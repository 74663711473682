import Pusher from "pusher-js";
import { store } from "../store";
import { REACT_APP_MESSAGING_SERVICE_URL } from "../../urls";
// staging d651b1bd0c18b021f264
// live 22eecb27b12e20f4d569
export const CHAT_URL = "";
export const GET_CHAT = "/api/v2/channels/my_channels";
export const SEND_MES = "/api/v1/messages/send";
// const APP_KEY = (window.location.port === 3006 || window.location.host.includes("staging")) ? "d651b1bd0c18b021f264" : "22eecb27b12e20f4d569"
export const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
    cluster: "ap2",
    encrypted: true,
    channelAuthorization: {
        endpoint: REACT_APP_MESSAGING_SERVICE_URL + "/broadcasting/auth",
        headers: {
            Authorization:
                "Bearer " +
                (localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).access_token),
            current_role_id:
                localStorage.getItem("user") &&
                JSON.parse(localStorage.getItem("user")).role_id,
        },
        headersProvider: () => {
            return {
                Authorization: "Bearer " + (store?.getState()?.auth?.user && store?.getState()?.auth?.user?.access_token),
                current_role_id: store?.getState()?.auth?.user && store?.getState()?.auth?.user?.role_id,
            };
        }
    },
});
Pusher.logToConsole = false