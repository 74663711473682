import React from "react";
import date from "date-and-time";
import ProfileNo from "../WhatsApp/assets/images/profileNo.png";
import { memo } from "react";
import Ply from "../WhatsApp/assets/images/ply";
import Paus from "../WhatsApp/assets/images/paus";
import { useState } from "react";
import { useRef } from "react";
import Icon from "../WhatsApp/components/Icon";
import { useDispatch } from "react-redux";
import Rep from "../WhatsApp/components/svgs/rep";
import { FILE_SERVER } from "../../../constants";
import OptionsMenu from "../WhatsApp/components/OptionsMenu";
import Spinner from "../Spinner";
import Cam from "../WhatsApp/assets/images/cam"
import Vid from "../WhatsApp/assets/images/vid"
import fil from "../WhatsApp/assets/images/output.png";
import Spinn from "../WhatsApp/assets/images/spinner";
import Down from "../WhatsApp/assets/images/down";
import Voc from "../WhatsApp/assets/images/voc"
function Message({
  setImg,
  a,
  i,
  setVid,
  activeChat,
  reply,
  GetMessage
}) {

  var numb = a?.id ? a?.id?.toString()?.match(/\d/g) : a?.type?.toString()?.match(/\d/g);
  let ids = numb?.join("");
  const dispatch = useDispatch()
  const [opens, setOpen] = React.useState(false);
  const handlePlay = () => {
    //    let played= a.id
    //     let auds=getAudioMessages()
    //     auds?.forEach((aud)=>{
    //       if(aud?.getAttribute("aria-details")!==played)
    //       aud.currentTime=0
    //       aud?.pause()
    //     })
  }
  const handleEnd = () => {
    // let played= a.id
    // let auds=getAudioMessages()
    // let targenext=0
    // getAudioMessages().forEach((aud,ind)=>{
    //   if(aud?.getAttribute("aria-details")===played&&ind!==(getAudioMessages().length-1)){
    //     targenext=ind+1
    //   }
    // })
    // if(targenext!==0){
    //   getAudioMessages().forEach((aud,ind)=>{
    //     if(ind===targenext){
    //       aud.play()
    //     }
    //   })
    // }
  }
  const audioRef = useRef();
  const showTime = (m) => {
    let minutes = parseInt(m / 60);
    let seconds = Math.abs(parseInt(m - minutes * 60));
    if (m)
      return `${minutes > 9 ? minutes : "0" + minutes}:${seconds > 9 ? seconds : "0" + seconds
        }`;
    else return "";
  };
  const [play, setPlay] = useState(false);
  const getMessageTime = () => {
    let no = new Date();
    let d = new Date(a?.created_at);
    if (no.getHours() === d.getHours()) {
      return date.format(new Date(d), "HH:mm");
    }
    const myDate = new Date()
    d = d.getTime() - (myDate.getTimezoneOffset() * 60 * 1000)
    return date.format(new Date(d), "HH:mm");
  };
  const getStatues = () => {
    let aa = a.message_status.filter((a) => a.user_id !== (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).id))
    if (aa.length > 0) {
      return (aa[0])

    }
    else {
      return ({ is_watched: false, is_received: 0 })
    }
  }

  const showMessage = () => {
    if (a.message_type.name === "TextMessage") {
      return (
        <div
          onMouseLeave={() => setOpen(false)}
          className={`message-hold ${opens && "ac"} message-hold--${(parseInt(a.sender_user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id)) ? "rxd" : "sent"
            }`}
        >
          <p
            id={"d" + ids}
            onClick={() => setOpen(true)}
            key={i}
            className={`${a.is_forward === 1 && "padTop"} ${activeChat?.channel_type?.slug === "team" &&
              (parseInt(a.sender_user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id)) &&
              "group_chat_msg"
              } chat__msg chat__msg--${(parseInt(a.sender_user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id)) ? "rxd" : "sent"
              }`}
          >
            {a.is_forward === 1 &&
              <div className="forwarded-icon">
                <Rep />
                <span>Forwarded Message</span>
              </div>}
            {a.parent_message && a.parent_message?.id && (<>
              <div
                className="replay-msg"
                onClick={() => GetMessage(a?.id, a.parent_message.id)}
              >
                <div className="bar-c"></div>
                <div className="reply-cont">
                  <div className="reply-name group_name">
                    {a.parent_message?.sender_user_id === JSON.parse(localStorage.getItem("user")).id
                      ? "You"
                      : a.parent_message.sender_user_id ? (activeChat.channel_members.filter((s) => s.user_id === a.parent_message.sender_user_id)[0]?.user?.full_name
                        ? activeChat.channel_members.filter((s) => s.user_id === a.parent_message.sender_user_id)[0]?.user?.full_name
                        : activeChat.channel_members.filter((s) => s.user_id === a.parent_message.sender_user_id)[0]?.user?.username) : ((a?.parent_message?.sender_whatsapp_contact?.name || a?.parent_message?.sender_whatsapp_contact?.pushname || ("+" + a?.parent_message?.sender_whatsapp_contact?.number)))}
                  </div>
                  <span className="reply-desc">
                    {a.parent_message && (a.parent_message.message_type.name === "ImageMessage") && <Cam />}
                    {a.parent_message && a.parent_message.message_type.name === "video" && <Vid />}
                    {a.parent_message && a.parent_message.message_type.name === "VoiceMessage" && <Voc />}
                    {a.parent_message && a.parent_message.message_type.name === "FileMessage" && <img alt="fill" height={15} width={14} src={fil} />}
                    {" "}
                    {a.parent_message?.body}
                  </span>
                </div>
                {a.parent_message && a.parent_message.message_type && a.parent_message.message_type.name === "ImageMessage" && (
                  <img
                    alt="quotedImg"
                    className="quotedImg"
                    src={FILE_SERVER + a.parent_message.image}
                  />
                )}
              </div>

            </>
            )}
            {a?.sender_user_id && activeChat?.channel_type?.slug === "team" && (
              <>
                <div className="group_name">
                  {activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id)).length > 0 ?
                    activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.full_name?.length > 0
                      ? activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.full_name
                      : activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.username
                    :
                    a?.sender_whatsapp_contact?.name
                  }
                </div>
                {(activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id)).length > 0 && activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.avatar?.length > 0)
                  ? <img src={FILE_SERVER + activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.avatar} className="group_ava" alt="group_ava" />
                  : a?.sender_whatsapp_contact?.profile_photo ? <img alt="group_ava" src={a?.sender_whatsapp_contact?.profile_photo} className="group_ava" /> : <img src={ProfileNo} className="group_ava" alt="group_ava" />
                }
              </>
            )}
            {!a.sender_user_id && a?.sender_whatsapp_contact &&
              activeChat?.channel_type?.slug === "team" && (
                <>
                  <div className="group_name">
                    {a?.sender_whatsapp_contact?.name || a.sender_whatsapp_contact?.pushname || (("+") + a.sender_whatsapp_contact?.number)}
                  </div>
                  {a?.sender_whatsapp_contact?.profile_photo?.length > 0
                    ? <img src={a.sender_whatsapp_contact?.profile_photo} className="group_ava" alt="group_ava" />
                    : <img src={ProfileNo} className="group_ava" alt="group_ava" />
                  }
                </>
              )
            }
            <span>{a?.message_content && a.message_content?.content}</span>
            <span className="chat__msg-filler"> </span>

            <span className="chat__msg-footer">
              {getMessageTime()}
              {parseInt(a.sender_user_id) === parseInt(JSON.parse(localStorage.getItem("user")).id) && (
                <>
                  <Icon
                    id={getStatues().is_received === 0 ? "singleTick" : "doubleTick"}
                    aria-label={a?.ack}
                    className={`chat__msg-status-icon ${getStatues().is_watched === true ? "chat__msg-status-icon--blue" : ""
                      }`}
                  />
                </>
              )}
            </span>
          </p>
          <OptionsMenu click={() => reply()} forward={() => { dispatch({ type: "FORWARD-MESSAGE", payload: a }) }} />
        </div>
      );
    } else if (a.message_type.name === "ImageMessage") {
      return (
        <div
          onMouseLeave={() => setOpen(false)}
          className={`message-hold ${opens && "ac"} message-hold--${(parseInt(a.sender_user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id)) ? "rxd" : "sent"
            }`}
        >
          <p
            id={"d" + ids}
            onClick={() => setOpen(true)}
            key={i}
            style={{
              padding: "3px",
              flexWrap: "wrap",
              paddingBottom: a?.body ? "16px" : "4px",
            }}
            className={`${a.is_forward === 1 && "padTop"} chat_img chat__msg chat__msg--${(parseInt(a.sender_user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id)) ? "rxd" : "sent"
              }`}
          >
            {activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id)).length > 0 && activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.avatar?.length > 0
              ? <img src={FILE_SERVER + activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.avatar} className="group_ava" alt="group_ava" />
              : <img alt="group_ava" src={ProfileNo} className="group_ava" />
            }
            {a.is_forward === 1 &&
              <div className="forwarded-icon">
                <Rep />
                <span>Forwarded Message</span>
              </div>}
            {a.parent_message && a.parent_message?.id && (<>
              <div
                className="replay-msg"
                onClick={() => GetMessage(a?.id, a.parent_message.id)}
              >
                <div className="bar-c"></div>
                <div className="reply-cont">
                  <div className="reply-name group_name">
                    {a.parent_message?.sender_user_id === JSON.parse(localStorage.getItem("user")).id
                      ? "You"
                      : a.parent_message.sender_user_id ? (activeChat.channel_members.filter((s) => s.user_id === a.parent_message.sender_user_id)[0]?.user?.full_name
                        ? activeChat.channel_members.filter((s) => s.user_id === a.parent_message.sender_user_id)[0]?.user?.full_name
                        : activeChat.channel_members.filter((s) => s.user_id === a.parent_message.sender_user_id)[0]?.user?.username) : ((a?.parent_message?.sender_whatsapp_contact?.name || a?.parent_message?.sender_whatsapp_contact?.pushname || ("+" + a?.parent_message?.sender_whatsapp_contact?.number)))}
                  </div>
                  <span className="reply-desc">
                    {a.parent_message && (a.parent_message.message_type.name === "ImageMessage") && <Cam />}
                    {a.parent_message && a.parent_message.message_type.name === "video" && <Vid />}
                    {a.parent_message && a.parent_message.message_type.name === "VoiceMessage" && <Voc />}
                    {a.parent_message && a.parent_message.message_type.name === "FileMessage" && <img height={15} width={14} src={fil} />}

                    {" "}
                    {a.parent_message?.body}
                  </span>
                </div>
                {a.parent_message && a.parent_message.message_type && a.parent_message.message_type.name === "ImageMessage" && (
                  <img
                    alt="quotedImg"
                    className="quotedImg"
                    src={FILE_SERVER + a.parent_message.image}
                  />
                )}
              </div>

            </>
            )}
            {(a?.sender_user_id) && activeChat?.channel_type?.slug === "team" && (
              <>
                <div className="group_name">
                  {activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id)).length > 0 && activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.full_name?.length > 0
                    ? activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0].user.full_name
                    : "+" + activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0].user.username}
                </div>

                <img src={ProfileNo} className="group_ava" alt="group_ava" />
              </>
            )}
            {!a.sender_user_id && a.sender_whatsapp_contact &&
              activeChat?.channel_type?.slug === "team" && (
                <>
                  <div className="group_name">
                    {a.sender_whatsapp_contact?.name || a.sender_whatsapp_contact?.pushname || (("+") + a.sender_whatsapp_contact?.number)}
                  </div>
                  {a.sender_whatsapp_contact?.profile_photo.length > 0
                    ? <img src={a.sender_whatsapp_contact?.profile_photo} className="group_ava" alt="group_ava" />
                    : <img src={ProfileNo} className="group_ava" alt="group_ava" />
                  }
                </>
              )
            }
            <img
              onClick={() => setImg((a.type || a.message_content[0].file_path.includes("https")) ? (
                a.message_content[0].file_path) : (
                FILE_SERVER + a.message_content[0].file_path))}
              className="img-mes"
              src={a.message_content ? (
                (a.type || a.message_content[0].file_path.includes("https")) ? (
                  a.message_content[0].file_path) : (
                  FILE_SERVER + a.message_content[0].file_path)) : "null"}
              alt="img-mes"
            />
            {a.type && (
              <div className="sppin">
                <Spinner no={true} />
              </div>
            )}
            <span className="chat__msg-footer">
              {getMessageTime()}
              {parseInt(a.sender_user_id) === parseInt(JSON.parse(localStorage.getItem("user")).id) && !a.type && (
                <>
                  <Icon
                    id={getStatues().is_received === 0 ? "singleTick" : "doubleTick"}
                    aria-label={a?.ack}
                    className={`chat__msg-status-icon ${getStatues().is_watched === true ? "chat__msg-status-icon--blue" : ""
                      }`}
                  />
                </>
              )}
            </span>
          </p>
          <OptionsMenu click={() => reply()} forward={() => { dispatch({ type: "FORWARD-MESSAGE", payload: a }) }} />
        </div>
      );
    } else if (a.message_type.name === "VoiceMessage") {
      return (
        <div
          onMouseLeave={() => setOpen(false)}
          className={`message-hold ${opens && "ac"} message-hold--${(parseInt(a.sender_user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id)) ? "rxd" : "sent"
            }`}
        >
          <p
            id={"d" + ids}
            onClick={() => setOpen(true)}
            style={{ flexWrap: "nowrap" }}
            key={i}
            className={`${a.is_forward === 1 && "padTop"} ml-0 chat__msg aud_msg chat__msg--${(parseInt(a.sender_user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id)) ? "rxd" : "sent"
              }`}
          >
            {activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id)).length > 0 && activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.avatar?.length > 0
              ? <img src={FILE_SERVER + activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.avatar} className="group_ava" alt="group_ava" />
              : <img src={ProfileNo} className="group_ava" alt="group_ava" />
            }
            {a.is_forward === 1 &&
              <div className="forwarded-icon">
                <Rep />
                <span>Forwarded Message</span>
              </div>}
            {a.parent_message && a.parent_message?.id && (<>
              <div
                className="replay-msg"
                onClick={() => GetMessage(a?.id, a.parent_message.id)}
              >
                <div className="bar-c"></div>
                <div className="reply-cont">
                  <div className="reply-name group_name">
                    {a.parent_message?.sender_user_id === JSON.parse(localStorage.getItem("user")).id
                      ? "You"
                      : a.parent_message.sender_user_id ? (activeChat.channel_members.filter((s) => s.user_id === a.parent_message.sender_user_id)[0]?.user?.full_name
                        ? activeChat.channel_members.filter((s) => s.user_id === a.parent_message.sender_user_id)[0]?.user?.full_name
                        : activeChat.channel_members.filter((s) => s.user_id === a.parent_message.sender_user_id)[0]?.user?.username) : ((a?.parent_message?.sender_whatsapp_contact?.name || a?.parent_message?.sender_whatsapp_contact?.pushname || ("+" + a?.parent_message?.sender_whatsapp_contact?.number)))}
                  </div>
                  <span className="reply-desc">
                    {a.parent_message && (a.parent_message.message_type.name === "ImageMessage") && <Cam />}
                    {a.parent_message && a.parent_message.message_type.name === "video" && <Vid />}
                    {a.parent_message && a.parent_message.message_type.name === "VoiceMessage" && <Voc />}
                    {a.parent_message && a.parent_message.message_type.name === "FileMessage" && <img height={15} width={14} src={fil} />}

                    {" "}
                    {a.parent_message?.body}
                  </span>
                </div>
                {a.parent_message && a.parent_message.message_type && a.parent_message.message_type.name === "ImageMessage" && (
                  <img
                    alt="quotedImg"
                    className="quotedImg"
                    src={FILE_SERVER + a.parent_message.image}
                  />
                )}
              </div>

            </>
            )}
            {parseInt(a.sender_user_id) === parseInt(JSON.parse(localStorage.getItem("user")).id) && (
              <div className="aud_ava_cont ml-0">
                {!(activeChat?.channel_members.filter((a) => parseInt(a.user_id) === parseInt(JSON.parse(localStorage.getItem("user")).id))[0] && activeChat?.channel_members.filter((a) => parseInt(a.user_id) === parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user && activeChat?.channel_members.filter((a) => parseInt(a.user_id) === parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar) ? <img className='abs-avva' src={ProfileNo} alt="abs-avva" /> : <img className='abs-avva' src={FILE_SERVER + (activeChat && activeChat?.channel_members && activeChat?.channel_members.filter((a) => parseInt(a.user_id) === parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar)} alt="abs-avva" />}

                <svg
                  viewBox="0 0 19 26"
                  height="26"
                  width="19"
                  preserveAspectRatio="xMidYMid meet"
                  className=""
                  version="1.1"
                  x="0px"
                  y="0px"
                  enableBackground="new 0 0 19 26"
                  xmlSpace="preserve"
                >
                  <path
                    fill="#FFFFFF"
                    className="ptt-status-background"
                    d="M9.217,24.401c-1.158,0-2.1-0.941-2.1-2.1v-2.366c-2.646-0.848-4.652-3.146-5.061-5.958L2.004,13.62 l-0.003-0.081c-0.021-0.559,0.182-1.088,0.571-1.492c0.39-0.404,0.939-0.637,1.507-0.637h0.3c0.254,0,0.498,0.044,0.724,0.125v-6.27 C5.103,2.913,7.016,1,9.367,1c2.352,0,4.265,1.913,4.265,4.265v6.271c0.226-0.081,0.469-0.125,0.723-0.125h0.3 c0.564,0,1.112,0.233,1.501,0.64s0.597,0.963,0.571,1.526c0,0.005,0.001,0.124-0.08,0.6c-0.47,2.703-2.459,4.917-5.029,5.748v2.378 c0,1.158-0.942,2.1-2.1,2.1H9.217V24.401z"
                  ></path>
                  <path
                    fill="#0DA9E5"
                    className="ptt-status-icon"
                    d="M9.367,15.668c1.527,0,2.765-1.238,2.765-2.765V5.265c0-1.527-1.238-2.765-2.765-2.765 S6.603,3.738,6.603,5.265v7.638C6.603,14.43,7.84,15.668,9.367,15.668z M14.655,12.91h-0.3c-0.33,0-0.614,0.269-0.631,0.598 c0,0,0,0-0.059,0.285c-0.41,1.997-2.182,3.505-4.298,3.505c-2.126,0-3.904-1.521-4.304-3.531C5.008,13.49,5.008,13.49,5.008,13.49 c-0.016-0.319-0.299-0.579-0.629-0.579h-0.3c-0.33,0-0.591,0.258-0.579,0.573c0,0,0,0,0.04,0.278 c0.378,2.599,2.464,4.643,5.076,4.978v3.562c0,0.33,0.27,0.6,0.6,0.6h0.3c0.33,0,0.6-0.27,0.6-0.6V18.73 c2.557-0.33,4.613-2.286,5.051-4.809c0.057-0.328,0.061-0.411,0.061-0.411C15.243,13.18,14.985,12.91,14.655,12.91z"
                  ></path>
                </svg>
              </div>
            )}
            <div
              className={`aud-cont ${parseInt(a.sender_user_id) === parseInt(JSON.parse(localStorage.getItem("user")).id) && "ml-0"}`}
            >
              {(play || (!audioRef.current?.paused)) ? (<div onClick={() => {
                audioRef?.current?.pause();
                setPlay(false);
              }}>
                <Paus />
              </div>
              ) : (
                <div onClick={() => {
                  audioRef?.current?.play();
                  setPlay(true);
                  handlePlay()
                }}>
                  <Ply />
                </div>
              )}
            </div>
            <div className="aud">
              <audio
                aria-details={a.id}
                onEnded={() => {
                  handleEnd()
                  setPlay(false);
                  audioRef.current.currentTime = 0;
                }}
                src={(a.type || a.message_content[0].file_path.includes("https")) ? (a.message_content && a.message_content[0].file_path) : (FILE_SERVER + a.message_content[0].file_path)}
                ref={audioRef}
              >
                <source src={(a.type || a.message_content[0].file_path.includes("https")) ? (a.message_content[0].file_path) : (FILE_SERVER + a.message_content && a.message_content[0].file_path)} />
              </audio>
              <div className="tra"></div>
              <div
                className="sv"
                style={{
                  marginLeft: `${audioRef?.current &&
                    ((parseFloat(audioRef.current.currentTime) * 100) /
                      parseFloat(audioRef.current.duration)) *
                    1.8
                    }px`,
                }}
              ></div>
            </div>
            {(parseInt(a.sender_user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id)) && (
              <div className="aud_ava_cont">
                {!(activeChat?.channel_members.filter((a) => parseInt(a.user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id))[0] && activeChat?.channel_members.filter((a) => parseInt(a.user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user && activeChat?.channel_members.filter((a) => parseInt(a.user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar) ? <img alt="abs-avva" className='abs-avva' src={ProfileNo} /> : <img alt="abs-avva" className='abs-avva' src={FILE_SERVER + (activeChat && activeChat?.channel_members && activeChat?.channel_members.filter((a) => parseInt(a.user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id))[0].user.avatar)} />}
                <svg
                  viewBox="0 0 19 26"
                  height="26"
                  width="19"
                  preserveAspectRatio="xMidYMid meet"
                  className=""
                  version="1.1"
                  x="0px"
                  y="0px"
                  enableBackground="new 0 0 19 26"
                  xmlSpace="preserve"
                >
                  <path
                    fill="#FFFFFF"
                    className="ptt-status-background"
                    d="M9.217,24.401c-1.158,0-2.1-0.941-2.1-2.1v-2.366c-2.646-0.848-4.652-3.146-5.061-5.958L2.004,13.62 l-0.003-0.081c-0.021-0.559,0.182-1.088,0.571-1.492c0.39-0.404,0.939-0.637,1.507-0.637h0.3c0.254,0,0.498,0.044,0.724,0.125v-6.27 C5.103,2.913,7.016,1,9.367,1c2.352,0,4.265,1.913,4.265,4.265v6.271c0.226-0.081,0.469-0.125,0.723-0.125h0.3 c0.564,0,1.112,0.233,1.501,0.64s0.597,0.963,0.571,1.526c0,0.005,0.001,0.124-0.08,0.6c-0.47,2.703-2.459,4.917-5.029,5.748v2.378 c0,1.158-0.942,2.1-2.1,2.1H9.217V24.401z"
                  ></path>
                  <path
                    fill="#0DA9E5"
                    className="ptt-status-icon"
                    d="M9.367,15.668c1.527,0,2.765-1.238,2.765-2.765V5.265c0-1.527-1.238-2.765-2.765-2.765 S6.603,3.738,6.603,5.265v7.638C6.603,14.43,7.84,15.668,9.367,15.668z M14.655,12.91h-0.3c-0.33,0-0.614,0.269-0.631,0.598 c0,0,0,0-0.059,0.285c-0.41,1.997-2.182,3.505-4.298,3.505c-2.126,0-3.904-1.521-4.304-3.531C5.008,13.49,5.008,13.49,5.008,13.49 c-0.016-0.319-0.299-0.579-0.629-0.579h-0.3c-0.33,0-0.591,0.258-0.579,0.573c0,0,0,0,0.04,0.278 c0.378,2.599,2.464,4.643,5.076,4.978v3.562c0,0.33,0.27,0.6,0.6,0.6h0.3c0.33,0,0.6-0.27,0.6-0.6V18.73 c2.557-0.33,4.613-2.286,5.051-4.809c0.057-0.328,0.061-0.411,0.061-0.411C15.243,13.18,14.985,12.91,14.655,12.91z"
                  ></path>
                </svg>
              </div>
            )}
            <span
              className={`chat__msg-footer ${parseInt(a.sender_user_id) === parseInt(JSON.parse(localStorage.getItem("user")).id) && "me-voc"
                }`}
            >
              {getMessageTime()}
              {parseInt(a.sender_user_id) === parseInt(JSON.parse(localStorage.getItem("user")).id) && (
                <>
                  <Icon
                    id={getStatues().is_received === 0 ? "singleTick" : "doubleTick"}
                    aria-label={a?.ack}
                    className={`chat__msg-status-icon ${getStatues().is_watched === true ? "chat__msg-status-icon--blue" : ""
                      }`}
                  />
                </>
              )}
            </span>
            <span className="chat__msg-footer dur">
              {audioRef?.current &&
                audioRef.current.duration &&
                showTime(audioRef.current.duration)}
            </span>
          </p>
          <OptionsMenu click={() => reply()} forward={() => { dispatch({ type: "FORWARD-MESSAGE", payload: a }) }} />
        </div>
      );
    } else if (a.message_type.name === "FileMessage") {
      return (
        <>
          <div
            onMouseLeave={() => setOpen(false)}
            className={`message-hold ${opens && "ac"}  message-hold--${(parseInt(a.sender_user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id)) ? "rxd" : "sent"
              }`}
          >

            <p
              id={"s" + ids}
              onClick={() => setOpen(true)}
              key={i}
              className={`${a?.is_forward === 1 && "padTop"}
              ${activeChat?.channel_type?.slug === "team" &&
                "group_chat_msg"
                } chat__file  chat__msg chat__msg--${(parseInt(a.sender_user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id)) ? "rxd" : "sent"
                }`}
            >
              {a.is_forward === 1 &&
                <div className="forwarded-icon">
                  <Rep />
                  <span>Forwarded Message</span>
                </div>}
              {(a?.sender_user_id) && activeChat?.channel_type?.slug === "team" && (
                <>
                  <div className="group_name">
                    {activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id)).length > 0 && activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.full_name?.length > 0
                      ? activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0].user.full_name
                      : activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0].user.username}
                  </div>
                  {activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id)).length > 0 && activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.avatar?.length > 0
                    ? <img alt="group_ava" src={FILE_SERVER + activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.avatar} className="group_ava" />
                    : <img alt="group_ava" src={ProfileNo} className="group_ava" />
                  }
                </>
              )}
              {!a.sender_user_id &&
                activeChat?.channel_type?.slug === "team" && (
                  <>
                    <div className="group_name">
                      {a.sender_whatsapp_contact?.name || a.sender_whatsapp_contact?.pushname || (("+") + a.sender_whatsapp_contact?.number)}
                    </div>
                    {a.sender_whatsapp_contact?.profile_photo.length > 0
                      ? <img alt="group_ava" src={a.sender_whatsapp_contact?.profile_photo} className="group_ava" />
                      : <img alt="group_ava" src={ProfileNo} className="group_ava" />
                    }
                  </>
                )
              }
              {a.parent_message && a.parent_message?.id && (<>
                <div
                  className="replay-msg"
                  onClick={() => GetMessage(a?.id, a.parent_message.id)}
                >
                  <div className="bar-c"></div>
                  <div className="reply-cont">
                    <div className="reply-name group_name">
                      {a.parent_message?.sender_user_id === JSON.parse(localStorage.getItem("user")).id
                        ? "You"
                        : a.parent_message.sender_user_id ? (activeChat.channel_members.filter((s) => s.user_id === a.parent_message.sender_user_id)[0]?.user?.full_name
                          ? activeChat.channel_members.filter((s) => s.user_id === a.parent_message.sender_user_id)[0]?.user?.full_name
                          : activeChat.channel_members.filter((s) => s.user_id === a.parent_message.sender_user_id)[0]?.user?.username) : ((a?.parent_message?.sender_whatsapp_contact?.name || a?.parent_message?.sender_whatsapp_contact?.pushname || ("+" + a?.parent_message?.sender_whatsapp_contact?.number)))}
                    </div>
                    <span className="reply-desc">
                      {a.parent_message && (a.parent_message.message_type.name === "ImageMessage") && <Cam />}
                      {a.parent_message && a.parent_message.message_type.name === "video" && <Vid />}
                      {a.parent_message && a.parent_message.message_type.name === "VoiceMessage" && <Voc />}
                      {a.parent_message && a.parent_message.message_type.name === "FileMessage" && <img alt="fill" height={15} width={14} src={fil} />}

                      {" "}
                      {a.parent_message?.body}
                    </span>
                  </div>
                  {a.parent_message && a.parent_message.message_type && a.parent_message.message_type.name === "ImageMessage" && (
                    <img
                      alt="quotedImg"
                      className="quotedImg"
                      src={FILE_SERVER + a.parent_message.image}
                    />
                  )}
                </div>

              </>
              )}
              {a?.message_content && a?.message_content[0]?.file_path && (
                <a
                  target="_blank"
                  href={(a.message_content[0].file_path.includes("https") || a.type) ? a.message_content[0].file_path : FILE_SERVER + a.message_content[0].file_path}
                  download
                  className="replay-msg file-msg"
                >
                  <img src={fil} style={{ width: "26px" }} alt="fill" />
                  <div className="file-desc">
                    <div className="file-name">
                      {"FILE"}
                    </div>
                    <div className="file-type">
                      {"FILE EXT"}
                    </div>
                  </div>
                  {a?.type ? (
                    <Spinn />
                  ) : (
                    <Down />
                  )}
                </a>
              )}

              <span className="chat__msg-filler"> </span>

              <span className="chat__msg-footer">
                {getMessageTime()}
                {parseInt(a.sender_user_id) === parseInt(JSON.parse(localStorage.getItem("user")).id) && (
                  <>
                    <Icon
                      id={getStatues().is_received === 0 ? "singleTick" : "doubleTick"}
                      aria-label={a?.ack}
                      className={`chat__msg-status-icon ${getStatues().is_watched === true ? "chat__msg-status-icon--blue" : ""
                        }`}
                    />
                  </>
                )}
              </span>
            </p>
            <OptionsMenu click={() => reply()} forward={() => { dispatch({ type: "FORWARD-MESSAGE", payload: a }) }} />
          </div>
        </>
      );
    } else if (a.message_type.name === "VideoMessage") {
      return (
        <div
          onMouseLeave={() => setOpen(false)}
          className={`message-hold ${opens && "ac"} message-hold--${(parseInt(a.sender_user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id)) ? "rxd" : "sent"
            }`}
        >
          <p
            id={"d" + ids}
            onClick={() => setOpen(true)}
            key={i}
            style={{
              padding: "3px",
              flexWrap: "wrap",
              paddingBottom: a?.body ? "16px" : "4px",
            }}
            className={`${a.is_forward === 1 && "padTop"} chat_img chat__msg chat__msg--${(parseInt(a.sender_user_id) !== parseInt(JSON.parse(localStorage.getItem("user")).id)) ? "rxd" : "sent"
              }`}
          >
            {activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id)).length > 0 && activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.avatar?.length > 0
              ? <img alt="group_ava" src={FILE_SERVER + activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.avatar} className="group_ava" />
              : <img alt="group_ava" src={ProfileNo} className="group_ava" />
            }
            {a.is_forward === 1 &&
              <div className="forwarded-icon">
                <Rep />
                <span>Forwarded Message</span>
              </div>}
            {a.parent_message && a.parent_message?.id && (<>
              <div
                className="replay-msg"
                onClick={() => GetMessage(a?.id, a.parent_message.id)}
              >
                <div className="bar-c"></div>
                <div className="reply-cont">
                  <div className="reply-name group_name">
                    {a.parent_message?.sender_user_id === JSON.parse(localStorage.getItem("user")).id
                      ? "You"
                      : a.parent_message.sender_user_id ? (activeChat.channel_members.filter((s) => s.user_id === a.parent_message.sender_user_id)[0]?.user?.full_name
                        ? activeChat.channel_members.filter((s) => s.user_id === a.parent_message.sender_user_id)[0]?.user?.full_name
                        : activeChat.channel_members.filter((s) => s.user_id === a.parent_message.sender_user_id)[0]?.user?.username) : ((a?.parent_message?.sender_whatsapp_contact?.name || a?.parent_message?.sender_whatsapp_contact?.pushname || ("+" + a?.parent_message?.sender_whatsapp_contact?.number)))}
                  </div>
                  <span className="reply-desc">
                    {a.parent_message && (a.parent_message.message_type.name === "ImageMessage") && <Cam />}
                    {a.parent_message && a.parent_message.message_type.name === "video" && <Vid />}
                    {a.parent_message && a.parent_message.message_type.name === "VoiceMessage" && <Voc />}
                    {a.parent_message && a.parent_message.message_type.name === "FileMessage" && <img height={15} width={14} src={fil} />}

                    {" "}
                    {a.parent_message?.body}
                  </span>
                </div>
                {a.parent_message && a.parent_message.message_type && a.parent_message.message_type.name === "ImageMessage" && (
                  <img
                    alt="quotedImg"
                    className="quotedImg"
                    src={FILE_SERVER + a.parent_message.image}
                  />
                )}
              </div>

            </>
            )}
            {(a?.sender_user_id) && activeChat?.channel_type?.slug === "team" && (
              <>
                <div className="group_name">
                  {activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id)).length > 0 && activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0]?.user.full_name?.length > 0
                    ? activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0].user.full_name
                    : "+" + activeChat?.channel_members?.filter((s) => parseInt(s?.user_id) === parseInt(a?.sender_user_id))[0].user.username}
                </div>

                <img src={ProfileNo} className="group_ava" alt="group_ava" />
              </>
            )}
            {!a.sender_user_id &&
              activeChat?.channel_type?.slug === "team" && (
                <>
                  <div className="group_name">
                    {a.sender_whatsapp_contact?.name || a.sender_whatsapp_contact?.pushname || (("+") + a.sender_whatsapp_contact?.number)}
                  </div>
                  {a.sender_whatsapp_contact?.profile_photo.length > 0
                    ? <img src={a.sender_whatsapp_contact?.profile_photo} className="group_ava" alt="group_ava" />
                    : <img src={ProfileNo} className="group_ava" alt="group_ava" />
                  }
                </>
              )
            }
            <div className={"vid-cont " + `${audioRef?.current?.currentTime === 0 && "vixs"}`}>
              <div onClick={() => {
                setVid((a.type || a.message_content[0].file_path.includes("https")) ? (
                  a.message_content[0].file_path) : (
                  FILE_SERVER + a.message_content[0].file_path))
              }}>
                <Ply />
              </div>
            </div>
            <video onEnded={() => {
              handleEnd()
              setPlay(false);
              audioRef.current.currentTime = 0;
            }} ref={audioRef} onClick={() => setVid((a.type || a.message_content[0].file_path.includes("https")) ? (
              a.message_content[0].file_path) : (
              FILE_SERVER + a.message_content[0].file_path))}
              className="img-mes"
              src={a.message_content ? (
                (a.type || a.message_content[0].file_path.includes("https")) ? (
                  a.message_content[0].file_path) : (
                  FILE_SERVER + a.message_content[0].file_path)) : "null"} controls={false}>
              <source src={a.type ? a?.message_content[0]?.file_path : FILE_SERVER + a?.message_content[0]?.file_path}></source>
            </video>
            {a.type && (
              <div className="sppin">
                <Spinner no={true} />
              </div>
            )}
            <span className="chat__msg-footer">
              {getMessageTime()}
              {parseInt(a.sender_user_id) === parseInt(JSON.parse(localStorage.getItem("user")).id) && !a.type && (
                <>
                  <Icon
                    id={getStatues().is_received === 0 ? "singleTick" : "doubleTick"}
                    aria-label={a?.ack}
                    className={`chat__msg-status-icon ${getStatues().is_watched === true ? "chat__msg-status-icon--blue" : ""
                      }`}
                  />
                </>
              )}
            </span>
          </p>
          <OptionsMenu click={() => reply()} forward={() => { dispatch({ type: "FORWARD-MESSAGE", payload: a }) }} />
        </div>
      );
    }
  };
  return <>{showMessage()}</>;
}

export default memo(Message);
