import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import * as types from "./constants";
import {
  wait,
  done,
  error,
  set_session,
  set_token,
  register,
  LoginSucces,
  SignupSucces,
  _setCountries,
  logout,
} from "./actions";
import AxiosInstance from "../../helpers/axiosInstance";
// import { remote_server } from "./constants";
import translations from "../../assets/translation/index.json";
// import MonitoringService from "../../monitoringService"
import {
  requestFirebaseNotificationPermission,
} from "../../firebaseInit";
import { store } from "../store";
import axios from "axios";
import { pusher } from "../chat/constants";
import { toast } from "react-toastify";
import { REACT_APP_ACCOUNTING_URL, REACT_APP_USER_SERVICE_URL } from "../../urls";
function* getRoles(action) {
  try {
    let AxiosInstaceRole = action.payload ? axios.create({
      baseURL: types.remote_role_server,
      headers: {
        Authorization: "Bearer " + action.payload?.access_token,
        "Content-Type": "application/json",
        current_role_id: action.payload?.role_id
      },
    }) : axios.create({
      baseURL: types.remote_role_server,
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).access_token,
        "Content-Type": "application/json",
        current_role_id: JSON.parse(localStorage.getItem("user")).role_id
      },
    });

    yield put(wait());
    let response = yield AxiosInstaceRole.get("/api/v1/user_permissions/mine");
    yield put({ type: "ROLES_AUTH", payload: response.data.data?.map((s) => { return (s.fixed_name) }) });
    yield put(done());
  }
  catch (e) {
    yield put(done());
  }
}
function* watchGetRoles() {
  yield takeEvery("GET_AUTH_ROLES", getRoles)
}
function* login(action) {
  let response;
  try {
    // 
    yield put(wait());
    response = yield AxiosInstance.post(REACT_APP_USER_SERVICE_URL + "/api/v1/auth/username/login",
      JSON.stringify(action.payload)
    ).then((res) => {
      store.dispatch({
        type: "TOKEN_WHATSAPP",
        payload: res.data?.data?.access_token,
      });
      store.dispatch({
        type: "GET_AUTH_ROLES",
        payload: res.data?.data,
      });
      requestFirebaseNotificationPermission()
        .then((firebaseToken) => {
          // console.log(firebaseToken)
          // eslint-disable-next-line no-console
          if (res.data.data) {
            try {
              store.dispatch({
                type: "STORE_TOKEN",
                payload: {
                  id: res.data.data.id,
                  token: firebaseToken,
                  user: res.data.data
                },
              });
            } catch (e) {
              console.error(e);
            }
          }
        })
        .catch((err) => {
          return err;
        });
      return res
    })
    let chs = pusher.subscribe(`whatsapp-user-${response.data.data.id}-channel`)
    chs.bind("PrivateMessageReceivedEvent", (da) => {
      if (store.getState().auth.user) {
        let chats = store.getState()?.whatsapp?.chats
        if (chats?.filter(chat => chat.id === da.message?.whatsapp_chat_id)?.length === 0) {
          store.dispatch({ type: "GET_WA_CHATS" })
        } else {
          store.dispatch({
            type: "CHANGE_IN_MESSAGES",
            payload: {
              type: "MessageReceivedEvent",
              payload: da
            }
          })
        }
        if (da.contact) {
          store.dispatch({ type: "CONTACT_ADD", payload: da.contact })
        }
        if (da.message.type === "ticket_hint") {
          store.dispatch({
            type: "delete_temp_odoo_message", payload: {
              chat_id: da.message?.whatsapp_chat_id
            }
          })
        }
      }
    })
    chs.bind("RemoveChatEvent", (da) => {
      // console.log("event RemoveChatEvent triggered")
      if (da.whatsapp_chat_id) { store.dispatch({ type: "REM_WA_ONE_CHAT", id: da.whatsapp_chat_id }) }


    })
    let ch = pusher.subscribe("employees_channel")
    ch.bind("pusher:subscription_succeeded", data => {

    })
    let channel = pusher.subscribe(`employee-${response.data.data.id}-channel`)
    channel.bind("EmployeeTicketEvent", (data) => {
      store.dispatch({
        type: "NEW_TICKET_RECIEVED",
        payload: {
          ...data
        }
      })
    })
    ch.bind("ReadChatMessagesEvent", data => {
      store.dispatch({ type: "READ_CHAT_PUSHER", payload: { id: data.chat_id } })
    })
    ch.bind("MessageReceivedEvent", (da) => {
      if (store.getState().auth.user) {
        let chats = store.getState()?.whatsapp?.chats
        if (chats?.filter(chat => chat.id === da.message?.whatsapp_chat_id)?.length === 0) {
          store.dispatch({ type: "GET_WA_CHATS" })
        } else {
          store.dispatch({
            type: "CHANGE_IN_MESSAGES",
            payload: {
              type: "MessageReceivedEvent",
              payload: da
            }
          })
        }
        if (da.contact) { store.dispatch({ type: "CONTACT_ADD", payload: da.contact }) }
      }
    })
    ch.bind("ShouldRefreshEvent", (data) => {
      toast.warning("Window will be refreshed due to system update in 10 seconds!")
      setTimeout(() => {
        window.location.reload()
      }, 10000)
    })
    ch.bind("ShouldLogoutEvent", (data) => {
      toast.warning("you will signed out due to system update in 10 seconds!")
      setTimeout(() => {
        logout().then(() => {
          localStorage.clear()
          setTimeout(() => {
            window.location.reload()
          }, 500)
        })
      }, 10000)
    })
    ch.bind("EmployeeStatusChangedEvent", (da) => {
      if (localStorage.getItem("user"))
        store.dispatch({ type: "GET-EMP" })
    })
    yield put(LoginSucces(response.data.data));
    // console.log(response.data.data)
    let asd = new Date();
    let a = `${asd.getHours() > 9
      ? asd.getHours().toString()
      : "0" + asd.getHours().toString()
      }:${asd.getMinutes() > 9
        ? asd.getMinutes().toString()
        : "0" + asd.getMinutes().toString()
      }`;
    localStorage.setItem("session-start", a);
    yield put(done());
  } catch (e) {
    yield put(error());
  }
}

function* watchlogin() {
  yield takeEvery(types.LOGIN_SAGA, login);
}
export const Translate = (pattern, language) => {
  let result = translations[`${language}`][`${pattern}`];
  return result;
};
function* Signup(action) {
  let response;
  try {
    yield put(wait());
    response = yield AxiosInstance.post(REACT_APP_USER_SERVICE_URL + "/api/v1/auth/signup",
      JSON.stringify({
        username: action.payload.name,
        role_id: 2,
        id_token: action.payload.id,
        password: action.payload.pass,
      })
    );
    yield put(register(response.data.data));
    yield put(done());
  } catch (e) {
    yield put(error());
  }
}
function* watchSignup() {
  yield takeEvery(types.SIGNUP_SAGA, Signup);
}
function* Forget(action) {
  let response;
  try {
    yield put(wait());
    response = yield AxiosInstance.post(REACT_APP_USER_SERVICE_URL + "/api/v1/auth/resetPasswordUsingOTP",
      JSON.stringify({
        id_token: action.payload.id,
        password: action.payload.pass,
      })
    );
    yield put(SignupSucces(response.data.data));
    yield put(done());
  } catch (e) {
    if (e instanceof Error) {
    }
  }
}
function* watchForget() {
  yield takeEvery(types.FORGET_SAGA, Forget);
}

export function* AuthSaga() {
  yield all([
    fork(watchSignup),
    fork(watchlogin),
    fork(watchForget),
    fork(watchGetRoles)
  ]);
}
