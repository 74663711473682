import React, { useState } from 'react'
import { TopNav } from "../shared/index"
import { useSelector, useDispatch } from "react-redux"
import Curre from "../../../../assets/svg/Transfer.svg"
import "./ship.css"
import "./ship2.css"
import "./bulks.css"
import Filter from './filter'
// import FilterCount from './filter_count'
import MainStatuses from './main_statuses'
// import PackageFilter from './package_filter'
// import PackageFilterExpand from './package_filter_expand'
// import BulkList from './bulks_list'
import { useEffect } from 'react'
import StaticsSquare from './staticssquare'
import date from "date-and-time"
import { pusher } from '../../../../redux/chat/constants'
import axios from 'axios'
import { toast } from 'react-toastify'
import { REACT_APP_ACCOUNTING_URL } from '../../../../urls'

export default function Shipments(props) {
    const dispatch = useDispatch()
    const statics_loading = useSelector((state) => state.ticketsReducer.loading)
    const countries = useSelector(state => state.ticketsReducer.countries)
    const cities = useSelector(state => state.ticketsReducer.cities)
    const [lang, setLang] = useState(null)
    const [activeStatus, setActiveStatus] = useState(null)
    const [dateFilter, setDateFilter] = useState(5)
    const [usdFilter, setUsdFilter] = useState(0)
    const [typeFilter, setTypeFilter] = useState(null)
    const [countriesFilter, setCountriesFilter] = useState(null)
    const [citiesFilter, setCitiesFilter] = useState(null)
    const [searchFilter, setSearchFilter] = useState("")
    // const [fakeFilterSelected, setFakeFilterSelected] = useState([null, null, null, null])
    const translated_lang = useSelector((state) => state.auth.lang)
    let user = useSelector(state => state.auth.user)
    let chs = pusher.subscribe(`whatsapp-user-${user.id}-channel`)

    useEffect(() => {
        chs.bind("TicketEvent", (data) => {
            let currency = null
            let from_date = new Date()
            let to_date = new Date()
            let ticket_country_id = countriesFilter
            let ticket_city_id = citiesFilter
            if (usdFilter === 1) {
                currency = "usdt"
            }
            if (usdFilter === 2) {
                currency = "usd"
            }
            if (dateFilter === 1) {
                from_date.setHours(0)
                from_date.setMinutes(0)
                from_date.setSeconds(0)
                from_date = from_date.toISOString()
                to_date = to_date.toISOString()
            } else if (dateFilter === 2) {
                from_date = date.addDays(new Date(), -1)
                from_date.setHours(0)
                from_date.setMinutes(0)
                from_date.setSeconds(0)
                to_date.setHours(0)
                to_date.setMinutes(0)
                to_date.setSeconds(0)
                from_date = from_date.toISOString()
                to_date = to_date.toISOString()
            } else if (dateFilter === 3) {
                from_date = date.addDays(new Date(), -7)
                from_date.setHours(0)
                from_date.setMinutes(0)
                from_date.setSeconds(0)
                from_date = from_date.toISOString()
                to_date = to_date.toISOString()
            } else if (dateFilter === 4) {
                from_date = date.addDays(new Date(), -30)
                from_date.setHours(0)
                from_date.setMinutes(0)
                from_date.setSeconds(0)
                from_date = from_date.toISOString()
                to_date = to_date.toISOString()

            } else if (dateFilter === 5) {
                from_date = date.addYears(new Date(), -30)
                from_date.setHours(0)
                from_date.setMinutes(0)
                from_date.setSeconds(0)
                from_date = from_date.toISOString()
                to_date = to_date.toISOString()
            } else {
                from_date = date.addYears(new Date(), -30)
                from_date.setHours(0)
                from_date.setMinutes(0)
                from_date.setSeconds(0)
                from_date = from_date.toISOString()
                to_date = to_date.toISOString()
            }
            dispatch({
                type: "GET_UPDATED_STATICS_SAGA", payload: {
                    chat_id: data.ticket.whatsapp_chat_id,
                    usdFilter: currency,
                    created_from: from_date,
                    created_to: to_date,
                    typeFilter: typeFilter,
                    ticket_country_id: ticket_country_id,
                    ticket_city_id: ticket_city_id,
                    search: searchFilter
                }
            })
        })
        return (() => {
            chs.unbind("TicketEvent")
        }
        )
    }, [chs, usdFilter, dateFilter, typeFilter, countriesFilter, citiesFilter, searchFilter])
    const getLang = () => {
        if (lang) {
            return (lang)
        }
        else {
            return translated_lang
        }
    }

    const token = useSelector(state => state.whatsapp?.userTokenWhatsapp)
    const getPage = async (action) => {
        try {
            const base_user = REACT_APP_ACCOUNTING_URL;
            let AxiosInstace = axios.create({
                baseURL: `${base_user}`,
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            });
            let res = await AxiosInstace.get(`api/v1/tickets/get/${action.chat_id}?${action.last_ticket_id ? `filter[last_ticket_id]=${action.last_ticket_id}` : ""}${action.status ? "&filter[status]=" + action.status : ""}&limit=300${action.ticket_city_id ? ("&filter[ticket_city_id]=" + action.ticket_city_id) : ""}${action.ticket_country_id ? ("&filter[ticket_country_id]=" + action.ticket_country_id) : ""}${action.usdFilter ? ("&filter[currency]=" + action.usdFilter) : ""}&filter[created_from]=${action.created_from}&filter[created_to]=${action.created_to}${action.typeFilter ? ("&filter[type]=" + action.typeFilter) : ""}`)
            dispatch({
                type: "GET_PAGE_TICKETS_RED",
                payload: {
                    data: res.data.data,
                    chat_id: action.chat_id
                }
            })
        }
        catch (e) {
            toast.error("error getting statistics!")
        }
    }
    useEffect(() => {
        dispatch({ type: "GET_TICKETS_COUNTRIES_SAGA" })
        dispatch({ type: "GET_TICKETS_CITIES_SAGA" })
    }, [dispatch])
    useEffect(() => {
        let currency = null
        let from_date = new Date()
        let to_date = new Date()
        let ticket_country_id = countriesFilter
        let ticket_city_id = citiesFilter
        if (usdFilter === 1) {
            currency = "usdt"
        }
        if (usdFilter === 2) {
            currency = "usd"
        }
        if (dateFilter === 1) {
            from_date.setHours(0)
            from_date.setMinutes(0)
            from_date.setSeconds(0)
            from_date = from_date.toISOString()
            to_date = to_date.toISOString()
        } else if (dateFilter === 2) {
            from_date = date.addDays(new Date(), -1)
            from_date.setHours(0)
            from_date.setMinutes(0)
            from_date.setSeconds(0)
            to_date.setHours(0)
            to_date.setMinutes(0)
            to_date.setSeconds(0)
            from_date = from_date.toISOString()
            to_date = to_date.toISOString()
        } else if (dateFilter === 3) {
            from_date = date.addDays(new Date(), -7)
            from_date.setHours(0)
            from_date.setMinutes(0)
            from_date.setSeconds(0)
            from_date = from_date.toISOString()
            to_date = to_date.toISOString()
        } else if (dateFilter === 4) {
            from_date = date.addDays(new Date(), -30)
            from_date.setHours(0)
            from_date.setMinutes(0)
            from_date.setSeconds(0)
            from_date = from_date.toISOString()
            to_date = to_date.toISOString()

        } else if (dateFilter === 5) {
            from_date = date.addYears(new Date(), -30)
            from_date.setHours(0)
            from_date.setMinutes(0)
            from_date.setSeconds(0)
            from_date = from_date.toISOString()
            to_date = to_date.toISOString()
        } else {
            from_date = date.addYears(new Date(), -30)
            from_date.setHours(0)
            from_date.setMinutes(0)
            from_date.setSeconds(0)
            from_date = from_date.toISOString()
            to_date = to_date.toISOString()
        }
        dispatch({
            type: "GET_TICKETS_STATICS_SAGA", payload: {
                usdFilter: currency,
                created_from: from_date,
                created_to: to_date,
                typeFilter: typeFilter,
                ticket_country_id: ticket_country_id,
                ticket_city_id: ticket_city_id,
                search: searchFilter
            }
        })
    }, [lang, usdFilter, dateFilter, typeFilter, countriesFilter, citiesFilter, searchFilter])

    let channel = pusher.subscribe(`presence-editing-ticket-rate`);
    useEffect(() => {
        channel.bind("client-edit-ticket", (data) => {
            dispatch({
                type: "ticketLockedByUser",
                payload: data
            })
        })
        return (() => {
            chs.unbind("client-edit-ticket")
        }
        )
    }, [channel])
    useEffect(() => {
        channel.bind("client-finish-edit-ticket", (data) => {
            dispatch({
                type: "ticketUnLockedByUser",
                payload: data
            })
        })
        return (() => {
            chs.unbind("client-edit-ticket")
        }
        )
    }, [channel])
    const editTicket = (ticket_id) => {
        channel.trigger(`client-edit-ticket`, {
            ticket_id: ticket_id,
            user_id: user.id,
            username: user.username
        })
    }
    const finishEditTicket = (ticket_id) => {
        channel.trigger(`client-finish-edit-ticket`, {
            ticket_id: ticket_id,
            user_id: user.id,
            username: user.username
        })
    }

    return (<div className={`languages-page ${props.active && "Active-page"} tickets_page`} style={{ paddingLeft: "28px" }}>
        <TopNav
            table_icon={Curre}
            table_name="Tickets"
            top_name_clk={() => { }}
            setLang={(e) => setLang(e)}
            disableAdd={true}
            lang={getLang()}
            hasChild={true}
            child={() => <div className='tickets_line_top'>
                <svg className={'lines'} xmlns="http://www.w3.org/2000/svg" width="0.5" height="25.5" viewBox="0 0 0.5 25.5">
                    <line id="Line_992" data-name="Line 992" y1="25" transform="translate(0.25 0.25)" fill="none" stroke="#8e8e8e" strokeLinecap="round" strokeWidth="0.5" />
                </svg>
                <div className="tickets_element_filter">
                    <div className={dateFilter === 1 ? "tickets_element_filter_button_active" : "tickets_element_filter_button"} onClick={() => {
                        if (dateFilter === 1) {
                            setDateFilter(5)
                        } else {
                            setDateFilter(1)
                        }
                    }}>
                        Today
                    </div>
                    <div className={dateFilter === 2 ? "tickets_element_filter_button_active" : "tickets_element_filter_button"} onClick={() => {
                        if (dateFilter === 2) {
                            setDateFilter(5)
                        } else {
                            setDateFilter(2)
                        }
                    }}>Yesterday</div>
                    <div className={dateFilter === 3 ? "tickets_element_filter_button_active" : "tickets_element_filter_button"} onClick={() => {
                        if (dateFilter === 3) {
                            setDateFilter(5)
                        } else {
                            setDateFilter(3)
                        }
                    }}>Week</div>
                    <div className={dateFilter === 4 ? "tickets_element_filter_button_active" : "tickets_element_filter_button"} onClick={() => {
                        if (dateFilter === 4) {
                            setDateFilter(5)
                        } else {
                            setDateFilter(4)
                        }
                    }}>Month</div>
                    <div className={dateFilter === 5 ? "tickets_element_filter_button_active" : "tickets_element_filter_button"} onClick={() => {
                        if (dateFilter === 5) {
                            setDateFilter(5)
                        } else {
                            setDateFilter(5)
                        }
                    }}>All</div>
                </div>
            </div>}
        />
        <StaticsSquare typeFilter={typeFilter} setTypeFilter={setTypeFilter} showDiffs={((dateFilter !== 5) && !statics_loading)} />
        <textarea style={{ opacity: 0, height: '0px', position: "absolute" }} id="hash-copy" />
        <Filter
            cities={cities}
            countries={countries}
            usdFilter={usdFilter}
            setUsdFilter={(value) => setUsdFilter(value)}
            countriesFilter={countriesFilter}
            setCountriesFilter={(value) => setCountriesFilter(value)}
            citiesFilter={citiesFilter}
            setCitiesFilter={(value) => setCitiesFilter(value)}
            search={(e) => {
                setSearchFilter(e)
            }}
        />
        <MainStatuses
            editTicket={(ticket_id) => editTicket(ticket_id)}
            finishEditTicket={(ticket_id) => finishEditTicket(ticket_id)}
            getPage={(arg) => {
                let currency = null
                let from_date = new Date()
                let to_date = new Date()
                let ticket_country_id = countriesFilter
                let ticket_city_id = citiesFilter
                if (usdFilter === 1) {
                    currency = "usdt"
                }
                if (usdFilter === 2) {
                    currency = "usd"
                }
                if (dateFilter === 1) {
                    from_date.setHours(0)
                    from_date.setMinutes(0)
                    from_date.setSeconds(0)
                    from_date = from_date.toISOString()
                    to_date = to_date.toISOString()
                } else if (dateFilter === 2) {
                    from_date = date.addDays(new Date(), -1)
                    from_date.setHours(0)
                    from_date.setMinutes(0)
                    from_date.setSeconds(0)
                    to_date.setHours(0)
                    to_date.setMinutes(0)
                    to_date.setSeconds(0)
                    from_date = from_date.toISOString()
                    to_date = to_date.toISOString()
                } else if (dateFilter === 3) {
                    from_date = date.addDays(new Date(), -7)
                    from_date.setHours(0)
                    from_date.setMinutes(0)
                    from_date.setSeconds(0)
                    from_date = from_date.toISOString()
                    to_date = to_date.toISOString()
                } else if (dateFilter === 4) {
                    from_date = date.addDays(new Date(), -30)
                    from_date.setHours(0)
                    from_date.setMinutes(0)
                    from_date.setSeconds(0)
                    from_date = from_date.toISOString()
                    to_date = to_date.toISOString()

                } else if (dateFilter === 5) {
                    from_date = date.addYears(new Date(), -30)
                    from_date.setHours(0)
                    from_date.setMinutes(0)
                    from_date.setSeconds(0)
                    from_date = from_date.toISOString()
                    to_date = to_date.toISOString()
                } else {
                    from_date = date.addYears(new Date(), -30)
                    from_date.setHours(0)
                    from_date.setMinutes(0)
                    from_date.setSeconds(0)
                    from_date = from_date.toISOString()
                    to_date = to_date.toISOString()
                }
                return getPage({
                    ...arg,
                    usdFilter: currency,
                    created_from: from_date,
                    created_to: to_date,
                    typeFilter: typeFilter,
                    ticket_country_id: ticket_country_id,
                    ticket_city_id: ticket_city_id
                })
            }}
            activeStatus={activeStatus}
            setActiveStatus={(e) => setActiveStatus(e)} />
    </div>)
}