import React, { useEffect, useState, memo } from "react";
import media from "../../../assets/images/placeholder.jpeg";
import ProfileNo from "../../../assets/images/profileNo.png";
import Checkbox from "../../../components/Checkbox";
import "../../../components/Checkbox/styles.css";
import Icon from "../../../components/Icon";
import { useDispatch, useSelector } from "react-redux";
import { getNum } from "../../../../../../constants";
import Copy from "../../../components/svgs/copy"
import { toast } from "react-toastify";
import { BACKEND_FIles_URL } from "../../../../../../constants/endpointConfig";
// import AddEmployeeModal from "./AddEmployeeModal";
const Profile = ({ isGroup, activeChat, id, user, id_f }) => {
  const copyContactInfo = () => {
    let elem = document.querySelector("#text-copy");
    elem.value = `name: ${val}\n phone: ${contacts.filter((s) => s.id === user?.id)[0]?.number ? ("+" + contacts.filter((s) => s.id === user?.id)[0]?.number) : (user?.number || ("+" + id_f))}`
    elem.select()
    document.execCommand('Copy');
    toast.success("Copied!")
  }
  const [edit, setEdit] = useState(false)
  const companies = useSelector((state) => state.whatsapp.companies)
  const contacts = useSelector((state) => state.whatsapp.contacts)
  const roles = useSelector((state) => state.whatsapp.roles)
  const employees = useSelector(state => state.employee.employee)
  const user_roles = useSelector(state => state.auth.roles)
  // const isChanged = () => {
  //   if (activeChat?.whatsapp_contact_company_id !== cid || activeChat?.whatsapp_contact_role_id !== rid || activeChat?.name !== val) {
  //     return true
  //   }
  //   else {
  //     return false
  //   }
  // }
  const [val, setVal] = useState('')
  const [cid, setCid] = useState('')
  const [rid, setRid] = useState('')
  useEffect(() => {
    // console.log(user)
    if (user) {
      setVal(contacts.filter((s) => s?.id === user?.id)[0]?.name || contacts.filter((s) => s?.id === user?.id)[0]?.pushname || `${contacts.filter((s) => s.id === user?.id)[0]?.number && "+" + contacts.filter((s) => s.id === user?.id)[0]?.number || user?.name || user?.number}`)
      setCid(contacts.filter((s) => s?.id === user?.id)[0]?.whatsapp_contact_company_id)
      setRid(contacts.filter((s) => s?.id === user?.id)[0]?.whatsapp_contact_role_id)
    }
  }, [user, id])
  const dispatch = useDispatch()
  // const [showModal, setShowModal] = useState(false)
  const changeName = () => {
    if (user?.id && !user.isNew) {
      setEdit(false)
      dispatch({ type: "CHANGE_NAME", payload: { name: val, cid: cid, rid: rid, number: getNum(contacts.filter((s) => s?.id === user?.id)[0]?.id) }, id: user?.id })
    }
    else {
      setEdit(false)
      dispatch({ type: "CHANGE_NAME", isNew: true, payload: { name: val, cid: cid, rid: rid, number: getNum(user.number) } })

    }
    setEdit(false);
  }
  const setName = (v) => {
    if (contacts.filter((s) => s?.id === user?.id)[0])
      dispatch({ type: "UPD_CONTACT", payload: { ...contacts.filter((s) => s?.id === user?.id)[0], name: v } })
    else {
      dispatch({ type: "UPD_CONTACT", payload: { number: user?.number, name: v } })
    }
  }
  return (
    <div className="profile">
      <div className="profile__section profile__section--personal">
        <div className="profile__avatar-wrapper">
          <img
            src={user?.profile_photo ? user?.profile_photo : ProfileNo}
            alt={user?.name}
            className="avatar"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = ProfileNo;
            }}
          />
        </div>
        <div className={"abs-menu-cha inhj" + ` ${edit && "low"}`}>
          <div className="contact-item">
            <div className="contact-info">
              <div className="contact-detail">
                {edit ? <input onKeyDown={(e) => { if (e.key === "Enter") e.target.blur() }} className="profile__name" value={val} onChange={(e) => {
                  setName(e.target.value)
                  setVal(e.target.value)
                }} /> : <div className="contact-name">{contacts.filter((s) => s.id === user?.id)[0]?.name || contacts.filter((s) => s.id === user?.id)[0]?.pushname || val || "not set"}</div>}
                {(contacts.filter((s) => s.id === user?.id)[0]?.number || user?.number || id_f) &&
                  <div className="contact-numb">
                    {contacts.filter((s) => s.id === user?.id)[0]?.number && ("+" + contacts.filter((s) => s.id === user?.id)[0]?.number)
                      || (user?.number)
                      || (id_f?.toString()?.includes("+") ? id_f : "+" + id_f)}</div>}
              </div>
              {!edit ? <div className="edit-conta">
                {contacts.filter((s) => s.id === user?.id)[0]?.whatsapp_contact_company_id &&

                  <div className="company-name">
                    {companies.filter((s) => s.id === cid)[0]?.name ?? "Not Set"}</div>
                }
                {contacts.filter((s) => s.id === user?.id)[0]?.whatsapp_contact_role_id &&
                  <div className="role-name">{roles.filter((s) => s.id === rid)[0]?.name ?? "Not Set"}</div>

                }
              </div> :
                <div className={"contact-detail-edit col-res "}>
                  <>

                    <div className="comp-edit">
                      <svg id="_15x15_photo_back" className="mr-w" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                        <g id="Mask_Group_312" data-name="Mask Group 312">
                          <g id="business-and-trade" transform="translate(0 -0.055)">
                            <path id="Path_21349" data-name="Path 21349" d="M8.144,1.169,1.275.125A1.108,1.108,0,0,0,.387.369,1.118,1.118,0,0,0,0,1.206V14.375A.627.627,0,0,0,.625,15H2.656V11.719A1.091,1.091,0,0,1,3.75,10.625H5.313a1.091,1.091,0,0,1,1.094,1.094V15H9.063V2.25a1.094,1.094,0,0,0-.919-1.081ZM3.437,9.219H2.5a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875H2.5a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875H2.5a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875H2.5a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938ZM6.563,9.219H5.625a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875H5.625a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875H5.625a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875H5.625a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Z" fill="#7c7c7c" />
                            <path id="Path_21350" data-name="Path 21350" d="M14.138,6.776l-4.45-.932V15h4.219A1.1,1.1,0,0,0,15,13.906V7.845A1.088,1.088,0,0,0,14.138,6.776Zm-1.481,6.349h-.937a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875h-.937a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Zm0-1.875h-.937a.469.469,0,0,1,0-.937h.938a.469.469,0,0,1,0,.938Z" fill="#7c7c7c" />
                          </g>
                        </g>
                      </svg>

                      {companies.map((s) => (
                        <div className={`edit-f contact-filter-item ${cid === s.id && "active-f"}`} onClick={() => setCid(s.id)}>
                          <span className="filter-title">{s.name}</span>
                        </div>
                      ))}
                    </div>
                    <div className="role-edit">
                      <svg className="mr-w" id="_10x10_flag_photo" data-name="10x10 flag photo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 10 10">
                        <g id="Mask_Group_314" data-name="Mask Group 314">
                          <g id="manager">
                            <path id="Path_21330" data-name="Path 21330" d="M5,3.947A1.973,1.973,0,1,0,3.027,1.973,1.973,1.973,0,0,0,5,3.947Z" fill="#5d5d5d" />
                            <path id="Path_21331" data-name="Path 21331" d="M7.648,4.9h0C7.388,4.839,6.6,4.729,6.6,4.729a.175.175,0,0,0-.216.107L5.2,8.086a.214.214,0,0,1-.4,0L3.615,4.836a.175.175,0,0,0-.164-.115c-.017,0-.826.113-1.1.18a1.678,1.678,0,0,0-1.2,1.661v2.7A.734.734,0,0,0,1.889,10H8.111a.734.734,0,0,0,.734-.734V6.554A1.736,1.736,0,0,0,7.648,4.9Z" fill="#5d5d5d" />
                            <path id="Path_21332" data-name="Path 21332" d="M5.447,4.667a.252.252,0,0,0-.186-.077H4.739a.252.252,0,0,0-.186.077.242.242,0,0,0-.032.285l.279.42-.13,1.1.257.684a.078.078,0,0,0,.147,0l.257-.684-.13-1.1.279-.42a.242.242,0,0,0-.032-.285Z" fill="#5d5d5d" />
                          </g>
                        </g>
                      </svg>
                      {roles.map((s) => (
                        <div className={`edit-f contact-filter-item ${rid === s.id && "active-f"}`} onClick={() => setRid(s.id)}>
                          <span className="filter-title">{s.name}</span>
                        </div>
                      ))}
                    </div>
                  </>

                </div>
              }

            </div>
            <div className="contact-edit" >
              {!edit ? <>{(user_roles?.includes("EDIT_CONTACT_FROM_PROFILE") || user_roles?.includes("USER_FULL_ACCESS") || !(contacts.filter((s) => s?.id === user?.id)[0]?.name || user?.name)) && <svg onClick={() => setEdit(true)} id="_15x15_photo_back" data-name="15x15 photo back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">
                <g id="Mask_Group_308" data-name="Mask Group 308" >
                  <g id="edit-5" transform="translate(0 0.029)">
                    <path id="Path_21336" data-name="Path 21336" d="M14.57,9.08a.393.393,0,0,0-.393.393v3.491A1.181,1.181,0,0,1,13,14.144H1.966A1.181,1.181,0,0,1,.786,12.964V2.719A1.181,1.181,0,0,1,1.966,1.54H5.456a.393.393,0,0,0,0-.786H1.966A1.968,1.968,0,0,0,0,2.719V12.964A1.968,1.968,0,0,0,1.966,14.93H13a1.968,1.968,0,0,0,1.966-1.966V9.474A.393.393,0,0,0,14.57,9.08Zm0,0" fill="#5d5d5d" />
                    <path id="Path_21337" data-name="Path 21337" d="M6.148,7.012l5.74-5.741,1.851,1.851L8,8.863Zm0,0" fill="#5d5d5d" />
                    <path id="Path_21338" data-name="Path 21338" d="M5.211,9.8l2.046-.567L5.778,7.754Zm0,0" fill="#5d5d5d" />
                    <path id="Path_21339" data-name="Path 21339" d="M14.251.3a.984.984,0,0,0-1.39,0l-.417.417L14.3,2.566l.417-.417a.984.984,0,0,0,0-1.39Zm0,0" fill="#5d5d5d" />
                  </g>
                </g>
              </svg>

              }
                <div onClick={() => copyContactInfo()} style={{ marginLeft: "10px" }}> <Copy /></div>
              </>
                :
                <>
                  <div className="save-contact" onClick={() => changeName()}>Save</div>
                  <div className="cancel-edit" onClick={() => setEdit(false)}>
                    <svg id="Component_134_1" data-name="Component 134 – 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                      <g id="Rectangle_4545" data-name="Rectangle 4545" fill="none" stroke="#cecdcd" strokeWidth="0.5">
                        <rect width="20" height="20" rx="5" stroke="none" />
                        <rect x="0.25" y="0.25" width="19.5" height="19.5" rx="4.75" fill="none" />
                      </g>
                      <line id="Line_962" data-name="Line 962" x1="12" transform="translate(5.758 5.757) rotate(45)" fill="none" stroke="#ff2e2e" strokeLinecap="round" strokeWidth="1" />
                      <line id="Line_963" data-name="Line 963" x1="12" transform="translate(14.242 5.757) rotate(135)" fill="none" stroke="#ff2e2e" strokeLinecap="round" strokeWidth="1" />
                    </svg>

                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="profile__section profile__section--media">
        <div className="sb profile__heading-wrapper">
          <h2 className="profile__heading"> Media, Links and Documents </h2>
          <button>
            <Icon id="rightArrow" className="profile__heading-icon" />
          </button>
        </div>
        <div className="profile__media-wrapper">
          <img src={media} alt="media" className="profile__media" />
          <img src={media} alt="media" className="profile__media" />
          <img src={media} alt="media" className="profile__media" />
        </div>
      </div>

      <ul className="profile__section profile__section--actions">
        <li className="profile__action">
          <div className="profile__action-left">
            <span className="profile__action-text profile__action-text--top">
              Mute Notifications
            </span>
          </div>
          <div className="profile__action-right">
            <Checkbox />
          </div>
        </li>
        <li className="profile__action">
          <div className="profile__action-left">
            <span className="profile__action-text profile__action-text--top">
              Starred Messages
            </span>
          </div>
          <button className="profile__action-right">
            <Icon id="rightArrow" className="profile__heading-icon" />
          </button>
        </li>
        <li className="profile__action">
          <div className="profile__action-left">
            <span className="profile__action-text profile__action-text--top">
              Disappearing Messages
            </span>
            <span className="profile__action-text profile__action-text--bottom">
              Off
            </span>
          </div>
          <button className="profile__action-right">
            <Icon id="rightArrow" className="profile__heading-icon" />{" "}
          </button>
        </li>
      </ul>

      <div className="profile__section profile__section--about">
        <div className="sb profile__heading-wrapper">
          <h2 className="profile__heading"> About and phone number </h2>
        </div>
        <ul>
          <li className="profile__about-item">
            Out here saving the world, one block of code at a time.
          </li>
          <li className="profile__about-item">{contacts.filter((s) => s.id === user?.id)[0]?.number ? ("+" + contacts.filter((s) => s.id === user?.id)[0]?.number) : (user?.number || ("+" + id_f))}</li>
        </ul>
      </div>
      {isGroup && user_roles?.includes("USER_FULL_ACCESS") && activeChat?.employees && <div className="profile__section profile__section--groups">
        <div className="profile__heading-wrapper">
          <h2 className="sb profile__heading profile__group-heading">
            <span> Employees Members </span> <span> {activeChat?.employees?.length}</span>
          </h2>
        </div>
        {activeChat?.employees.length > 0 && activeChat?.employees?.map((group) => (
          <>{employees?.filter((emp) => emp.id === group.id).length > 0 && <div className="profile__group" key={group?.id}>
            <div className="profile__group-avatar-wrapper">
              <img src={employees?.filter((emp) => emp.id === group.id)[0]?.avatar ? (BACKEND_FIles_URL + employees.filter((emp) => emp.id === group.id)[0]?.avatar) : ProfileNo} alt="Group 3" className="avatar" onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = ProfileNo;
              }} />
            </div>
            <div className="profile__group-content">
              <div className="profile__group-text profile__group-text--top">
                {(employees.filter((emp) => emp.id === group.id)[0]?.full_name.length > 0 && employees.filter((emp) => emp.id === group.id)[0]?.full_name) || employees.filter((emp) => emp.id === group.id)[0]?.username}
              </div>
            </div>
            <div className="remove-employee" onClick={() => dispatch({ type: "REM-EMP-FROM-CHAT", payload: group.id, cid: activeChat?.id })}>
              <Icon id="delete" className="profile__danger-icon no-mr" />
            </div>
          </div>}</>

        ))}
        {/* <div className="add-employee profile__group" onClick={()=>setShowModal(true)}>
              Add
            </div> */}
        {/* <AddEmployeeModal cid={activeChat?.id} employees={activeChat?.employees} open={showModal} close={()=>setShowModal(false)}/> */}
      </div>}
      {isGroup && <div className="profile__section profile__section--groups">
        <div className="profile__heading-wrapper">
          <h2 className="sb profile__heading profile__group-heading">
            <span> Groups Members </span> <span> {activeChat?.whatsapp_contact?.sub_contacts?.length}</span>
          </h2>
        </div>
        {activeChat?.whatsapp_contact?.sub_contacts?.map((group) => (
          <div className="profile__group" key={group?.id}>
            <div className="profile__group-avatar-wrapper">
              <img src={group.profile_photo || ProfileNo} alt="Group 3" className="avatar" onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = ProfileNo;
              }} />
            </div>
            <div className="profile__group-content">
              <div className="profile__group-text profile__group-text--top">
                {group?.name || group?.pushname || ("+" + group.number)}
              </div>

            </div>
          </div>
        ))}
      </div>}

      <div className="profile__section profile__section--danger">
        <Icon id="block" className="profile__danger-icon" />
        <div className="profile__danger-text"> Block </div>
      </div>

      <div className="profile__section profile__section--danger">
        <Icon id="thumbsDown" className="profile__danger-icon" />
        <div className="profile__danger-text"> Report contact </div>
      </div>

      <div className="profile__section profile__section--danger">
        <Icon id="delete" className="profile__danger-icon" />
        <div className="profile__danger-text"> Delete chat </div>
      </div>
    </div>
  );
};

export default memo(Profile);
