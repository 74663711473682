import React, { Children, useEffect, useState } from "react";
import InputText from "./inputs/InputText";
import photoCity from "./Icons/PhotoIcon.svg";
import flagCity from "./Icons/flagCity.svg";
import { textMarshal } from "text-marshal";
import { useDispatch, useSelector } from "react-redux";
import { FILE_SERVER } from "../../../../../constants";
import { upload } from "../../../../../uploadService";
import { toast } from "react-toastify";
import Empty from "../../../../../assets/svg/empty.svg";
import LoadingImage from "../../../LoadingImage";
import "../countries.css";
import Info from "../../../../../assets/svg/Info";
import Docs from "../../../../../assets/svg/Docs";
import Images from "../../../../../assets/svg/Images";
import Links from "../../../../../assets/svg/Links";
import Map from "./Map";
import Spinner from "../../../Spinner";
import { trans } from "../../../../../Internationalization"
function CompainesModal(props) {
  const getPhone = (a) => {
    let d = textMarshal({
      input: a,
      template: `xx  xxx  xxxxxxxx`,
      disallowCharacters: [/[a-z]/],
    });
    return d.marshaltext;
  };
  const languages = useSelector((state) => state.langs.languages);
  const mode = useSelector((state) => state.offices.mode);
  const floading = useSelector((state) => state.offices.floading);
  const img_upload = useSelector((state) => state.offices.img_upload);
  const parent_ids = useSelector((state) => state.offices.parent_ids);
  const offices = useSelector((state) => state.offices.offices);
  const curr = useSelector((state) => state.offices.test);
  const [post, setPost] = useState(false);
  const [postn, setPostn] = useState(false);
  const modalData = useSelector((state) => state.offices.modalData);
  const data = useSelector((state) => state.offices.newDepartment);

  const shake = (e) => {
    document.querySelector(".lang-modal.open").classList.add("shake-modal");
    setTimeout(() => {
      document
        .querySelector(".lang-modal.open")
        .classList.remove("shake-modal");
    }, 400);
  };
  const showIcons = () => {
    return (
      <>
        <div className="modal-icon-section">
          <div className="modal-icon-name">Office Logo</div>
          <div className="modal-icons">
            {!(img_upload.status && img_upload.type === "flat_photo_path") ? (
              <div
                className="modal-icon mar cityflag"
                onDragLeave={(e) => (e.target.style.transform = "scale(1)")}
                onDragEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                onDropCapture={(e) => { }}
                dropzone
                onDrop={(e) => {
                  onDropImage(e, "flat_photo_path");
                }}
                onDragOver={(e) => onDragOver(e)}
                onClick={() => updatPhoto("flat_photo_path")}
              >
                {data.flat_photo_path && data.flat_photo_path.length > 0 ? (
                  <svg
                    data-src={FILE_SERVER + data.flat_photo_path}
                    width="70"
                    height="50"
                  ></svg>
                ) : (
                  <img
                    style={{ width: "70px", height: "50px" }}
                    className="language-flat"
                    src={flagCity}
                  />
                )}
              </div>
            ) : (
              <div className="modal-icon mar cityflag">
                <LoadingImage />
              </div>
            )}
            {!(img_upload.status && img_upload.type === "photo_path") ? (
              <div
                className="modal-icon mar photo"
                style={{ width: "135px" }}
                onDragLeave={(e) => (e.target.style.transform = "scale(1)")}
                onDragEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                onDropCapture={(e) => { }}
                dropzone
                onDrop={(e) => {
                  onDropImage(e, "photo_path");
                }}
                onDragOver={(e) => onDragOver(e)}
                onClick={() => updatPhoto("photo_path")}
              >
                {data.photo_path && data.photo_path.length > 0 ? (
                  <img
                    src={FILE_SERVER + data.photo_path}
                    width="135"
                    height="50"
                  />
                ) : (
                  <img
                    style={{ width: "135px", height: "50px" }}
                    className="language-flat"
                    src={photoCity}
                  />
                )}
              </div>
            ) : (
              <div className="modal-icon mar photo">
                <LoadingImage />
              </div>
            )}
          </div>
        </div>
      </>
    );
  };
  const showInputs = () => {
    return (
      <>
        <div className="modal-input-section">
          {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

          <div className="modal-icon-name">{trans("com_nam")}</div>
          <div className="input-section">
            <div className="input-holder">
              <div
                className="full-input-element pa"
                style={{ paddingLeft: "10px" }}
              >
                <InputText
                  disabled={mode === "read"}
                  code={
                    data.region_translations.filter(
                      (t) => t.language_code === "en"
                    )[0].language_code
                  }
                  pad={true}
                  clear={() => {
                    ChangeTrans(
                      data.region_translations.filter(
                        (t) => t.language_code === "en"
                      )[0].language_code,
                      ""
                    );
                  }}
                  lang={
                    data.region_translations.filter(
                      (t) => t.language_code === "en"
                    )[0].region_name
                  }
                  setLang={(e) => {
                    ChangeTrans(
                      data.region_translations.filter(
                        (t) => t.language_code === "en"
                      )[0].language_code,
                      e.target.value
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-input-section">
          <div className="modal-icon-name">{trans("com_ph_cod")}</div>
          <div className="input-container">
            {mode === "read" && (
              <div className="options-code">
                {data.company_phone_codes.split(",").map((s) => (
                  <dv className="option-code">{s}</dv>
                ))}
              </div>
            )}
            {mode !== "read" && (
              <input
                readOnly={mode === "read"}
                pattern="[0-9,]"
                className="num-icp"
                type={"text"}
                value={data.company_phone_codes}
                onChange={(e) => {
                  dispatch({
                    type: "NEW-COM",
                    payload: { ...data, company_phone_codes: e.target.value },
                  });
                }}
              />
            )}
          </div>
        </div>
      </>
    );
  };
  const {
    open,
    setOpen,
    setExpand,
    setopenAccent,
    setLang,
    updateCode,
    langcode,
    lang,
  } = props;
  const dispatch = useDispatch();
  const ChangeTrans = (lang_code, value) => {
    let translations = data.region_translations;
    let arr = [];
    translations.map((d) => {
      if (d.language_code?.toLowerCase() === lang_code?.toLowerCase())
        arr.push({ ...d, region_name: value });
      else arr.push({ ...d });
    });
    dispatch({
      type: "NEW-OFF",
      payload: { ...data, region_translations: [...arr] },
    });
  };

  const ButtonValidate = () => {
    let bool,
      inp_bool = false;
    data.region_translations.map((a) => {
      if (a.region_name.length > 0) bool = true;
      else bool = false;
    });
    if (
      data.location_description &&
      data.location_description.length > 0 &&
      data.phone_number_1 &&
      data.phone_number_1.length > 0 &&
      data.phone_number_2 &&
      data.phone_number_2.length > 0 &&
      data.gsm_1 &&
      data.gsm_1.length > 0 &&
      data.gsm_2 &&
      data.gsm_2.length > 0 &&
      data.office_website &&
      data.office_website.length > 0 &&
      data.office_website &&
      data.office_website.length > 0 &&
      data.No &&
      data.No.length > 0 &&
      data.floor &&
      data.floor.length > 0 &&
      data.blok &&
      data.blok.length > 0 &&
      data.office_post_code &&
      data.office_post_code.length > 0 &&
      data.tower &&
      data.tower.length > 0
    )
      inp_bool = true;
    else inp_bool = false;
    return bool && inp_bool;
  };
  const updateImage = async (file, type) => {
    dispatch({ type: "IMG-UPLOAD-COM", payload: { status: true, type: type } });

    let pat = await upload(file);

    let path = `${FILE_SERVER + pat}`;
    let tmp = { ...data };
    tmp[`${type}`] = pat;
    dispatch({ type: "NEW-COM", payload: { ...tmp } });
    dispatch({
      type: "IMG-UPLOAD-COM",
      payload: { status: false, type: null },
    });
    dispatch({ type: "LEVEL-DONE-COM" });
  };
  const updatPhoto = (name) => {
    if (mode !== "read") {
      let Image = document.createElement("input");
      Image.onchange = (e) => {
        updateImage(e.target.files[0], name);
      };
      Image.type = "file";
      Image.hidden = true;
      Image.accept = "image/svg+xml";
      Image.style = { position: "absolute", opacity: "0" };
      let i = document.body.appendChild(Image);
      i.click();
    }
  };
  const onDropImage = (file, type) => {
    if (mode !== "read") {
      file.preventDefault();
      if (
        file.dataTransfer.files.length === 1 &&
        file.dataTransfer.files[0].type === "image/svg+xml"
      ) {
        updateImage(file.dataTransfer.files[0], type);
      } else {
        toast.error(
          "Invalid File Type Svg Files are the Only Allowed File Type"
        );
      }
    }
  };
  const onDragOver = (e) => {
    if (mode !== "read") e.preventDefault();
  };
  const sendRequest = () => {
    if (ButtonValidate()) {
      if (mode === "add") {
        dispatch({
          type: "ADD-OFF",
          payload: {
            ...data,
            region_type_id: 9,
            phone_number_1: data.phone_number_1
              .replaceAll(" ", "")
              .replaceAll("+", ""),
            phone_number_2: data.phone_number_2
              .replaceAll(" ", "")
              .replaceAll("+", ""),
            gsm_1: data.gsm_1.replaceAll(" ", "").replaceAll("+", ""),
            gsm_2: data.gsm_2.replaceAll(" ", "").replaceAll("+", ""),
          },
        });
      } else {
        dispatch({
          type: "UPD-OFF",
          payload: {
            ...data,
            region_type_id: 9,
            phone_number_1: data.phone_number_1
              .replaceAll(" ", "")
              .replaceAll("+", ""),
            phone_number_2: data.phone_number_2
              .replaceAll(" ", "")
              .replaceAll("+", ""),
            gsm_1: data.gsm_1.replaceAll(" ", "").replaceAll("+", ""),
            gsm_2: data.gsm_2.replaceAll(" ", "").replaceAll("+", ""),
          },
        });
      }
    } else {
      document.querySelectorAll(".modal-input-section input").forEach((inp) => {
        if (inp.value.length < 2) {
          inp.parentElement.classList.add("shake-modal");
          inp.classList.add("red-bord");
          setTimeout(() => {
            inp.parentElement.classList.remove("shake-modal");
          }, 400);
          setTimeout(() => {
            inp.classList.remove("red-bord");
          }, 1000);
        }
      });
    }
  };
  const [maps, setMap] = useState("");
  const showModal = () => {
    switch (props.languageFollower.length) {
      case 2:
        return "Add New Office";
      case 3:
        return "Add New Office";
      case 4:
        return "Add New Office";
      default:
        break;
    }
  };
  const [modal_sec, setModal_sec] = useState(1);
  const getPost = () => {
    dispatch({ type: "OFF-POST", payload: data.parent_post_code });
  };
  useEffect(async () => {
    if (parent_ids && parent_ids.id) {
      // try{
      //   const asf= await (await fetch("https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?countryCode="+`${offices.filter((a)=>parseInt(a.id)===(parent_ids.country_id?parseInt(parent_ids.country_id):parent_ids.id))[0].region_translations.filter((d)=>d.language_code==="en")[0].region_description}`+"&f=pjson&langCode=EN")).json()
      //   if(asf.candidates){
      // dispatch({type:"NEW-OFF",payload:{...data,region_latlongs:[{region_lat:asf.candidates[0].location.y, region_long:asf.candidates[0].location.x,}], parent_region_id:parent_ids.id}})
      // dispatch({type:"OFF-DONE"})
      //   }
      // }
      // catch(w){
      //   
      // }

      setPost(true);
    }
    if (data.parent_post_code && data.parent_post_code.length > 0);
    // setPost(false);
  }, [parent_ids]);
  useEffect(() => {
    if (mode !== "add") {
      getLoc();
    }
  }, [mode]);
  const getLoc = async () => {
    setMap("");
    let GEOCODE_URL =
      "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN&location=";

    try {
      const da = await (
        await fetch(
          GEOCODE_URL +
          `${data.region_latlongs[0].region_long},${data.region_latlongs[0].region_lat}`
        )
      ).json();
      setMap(da.address.CountryCode);
    } catch (e) {
      console.error(e);
    }
  };
  const getLoca = async (d) => {
    setMap("");

    dispatch({
      type: "NEW-OFF",
      payload: { ...data, location_description: d },
    });
    setPostn(false);
    setPostn(true);
  };
  const showModalData = () => {
    switch (modal_sec) {
      case 1:
        return (
          <>
            <div
              className="modal-input-section"
              style={{
                marginTop: "24px",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <div className="modal-half">
                <div className="modal-icon-name">
                  {trans("off_coun_add")}
                </div>
                <div className="input-section" style={{ marginTop: "5px" }}>
                  <div className="input-holder">
                    <div
                      className="full-input-element pa"
                      style={{ width: "122px" }}
                    >
                      <span
                        style={{ right: "initial", left: "0px" }}
                        className="locat"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.004"
                          height="12.004"
                          viewBox="0 0 12.004 12.004"
                        >
                          <g
                            id="Group_7628"
                            data-name="Group 7628"
                            transform="translate(0 0.001)"
                          >
                            <circle
                              id="Ellipse_328"
                              data-name="Ellipse 328"
                              cx="2.201"
                              cy="2.201"
                              r="2.201"
                              transform="translate(3.801 3.8)"
                              fill="#8e8e8e"
                            />
                            <path
                              id="Path_18940"
                              data-name="Path 18940"
                              d="M7.5,1.5a6,6,0,1,0,6,6A6,6,0,0,0,7.5,1.5Zm0,10.2a4.2,4.2,0,1,1,4.2-4.2,4.2,4.2,0,0,1-4.2,4.2Z"
                              transform="translate(-1.5 -1.501)"
                              fill="#8e8e8e"
                            />
                          </g>
                        </svg>
                      </span>
                      <InputText
                        placeholder="Post Code"
                        noFloat={true}
                        disabled={true}
                        pad={true}
                        lang={
                          data.parent_post_code === undefined
                            ? ""
                            : data.parent_post_code
                        }
                        clear={() => { }}
                        setLang={(e) => { }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-half" style={{ marginLeft: "30px" }}>
                <div className="modal-icon-name">{trans("map_loc")}</div>
                <div className="input-section" style={{ marginTop: "5px" }}>
                  <div className="input-holder">
                    <div
                      className="full-input-element pa"
                      style={{ width: "236px" }}
                    >
                      <span
                        style={{ right: "initial", left: "0px" }}
                        className="locat"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="9.012"
                          height="12.016"
                          viewBox="0 0 9.012 12.016"
                        >
                          <g id="Pin" transform="translate(-8)">
                            <path
                              id="Path_18946"
                              data-name="Path 18946"
                              d="M12.506,0A4.512,4.512,0,0,0,8,4.506c0,3.235,4.2,7.286,4.376,7.457a.186.186,0,0,0,.259,0c.178-.171,4.376-4.222,4.376-7.457A4.512,4.512,0,0,0,12.506,0Zm0,6.571a2.065,2.065,0,1,1,2.065-2.065A2.065,2.065,0,0,1,12.506,6.571Z"
                              fill="#8e8e8e"
                            />
                          </g>
                        </svg>
                      </span>
                      <InputText
                        onFocus={(e) => e.target.select()}
                        lang={
                          data.location_description &&
                            data.location_description.length > 0
                            ? data.location_description
                            : ""
                        }
                        noFloat={true}
                        disabled={mode === "read"}
                        pad={true}
                        clear={() => {
                          dispatch({
                            type: "NEW-OFF",
                            payload: { ...data, location_description: "" },
                          });
                        }}
                        setLang={(e) => {
                          e.target.select();
                          getLoca(e.target.value);
                          dispatch({
                            type: "CORDS-SAGA",
                            payload: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-input-section" style={{ marginTop: "8px" }}>
              {/* <div className="map-container"> */}
              {/* {false && (
                  <Map
                    mode={mode}
                    def={
                      data.region_latlongs[0].region_lat > 6
                        ? [
                            data.region_latlongs[0].region_lat,
                            data.region_latlongs[0].region_long,
                          ]
                        : [39.89709437, 32.82989502]
                    }
                    setMap={(a) => setMap(a)}
                    location_desc={data.location_description}
                    setLatLng={(e) =>
                      dispatch({
                        type: "NEW-OFF",
                        payload: {
                          ...data,
                          region_latlongs: [
                            { region_lat: e.lat, region_long: e.lng },
                          ],
                        },
                      })
                    }
                    latlng={{
                      lat: data.region_latlongs[0].region_lat,
                      lng: data.region_latlongs[0].region_long,
                    }}
                    setMapLocation={(e, a, b) =>
                      dispatch({
                        type: "NEW-OFF",
                        payload: {
                          ...data,
                          location_description: e,
                          region_latlongs: [{ region_lat: a, region_long: b }],
                        },
                      })
                    }
                  />
                )} */}
              {/* <iframe
                  width={"388"}
                  height={"70"}
                  className="gmap_iframe"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src={
                    "https://maps.google.com/maps?width=388&height=70&hl=en&q=" +
                    data.region_latlongs[0].region_lat +
                    "," +
                    data.region_latlongs[0].region_long +
                    "&t=&z=15&ie=UTF8&iwloc=B&output=embed"
                  }
                ></iframe> */}
              {/* </div> */}
            </div>
            <div className="modal-input-section" style={{ marginTop: "8px" }}>
              <div className="location-details">
                {
                  <svg
                    data-src={
                      FILE_SERVER +
                      offices.filter((a) => a.id === data.country_id)[0]
                        .flag_photo_path
                    }
                    viewBox={"0 0 23 15"}
                    width="23"
                    height={"15"}
                  />
                }
                <div className="location-text">
                  {props.languageFollower.map(
                    (j, i) => i !== 0 && j.name + ", "
                  )}
                </div>
              </div>
            </div>
            <div className="modal-input-section">
              <div className="modal-icon-name">{trans("off_cod_inf")}</div>
              <div className="input-section">
                {data.region_translations.map((a) => (
                  <div className="input-holder">
                    <div
                      className="full-input-element"
                      style={{ width: "100%" }}
                    >
                      <InputText
                        noFloat={true}
                        disabled={mode === "read"}
                        code={a.language_code}
                        pad={true}
                        clear={() => {
                          ChangeTrans(a.language_code, "");
                        }}
                        lang={a.region_name}
                        setLang={(e) => {
                          ChangeTrans(a.language_code, e.target.value);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-input-section">
              <div className="modal-icon-name">{trans("buld_tow_name")}</div>
              <div className="input-section" style={{ marginTop: "2px" }}>
                <div className="input-holder">
                  <div className="full-input-element" style={{ width: "100%" }}>
                    <InputText
                      noFloat={true}
                      disabled={mode === "read"}
                      clear={() => {
                        dispatch({
                          type: "NEW-OFF",
                          payload: { ...data, tower: "" },
                        });
                      }}
                      lang={data.tower}
                      setLang={(e) => {
                        dispatch({
                          type: "NEW-OFF",
                          payload: { ...data, tower: e.target.value },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal-input-section"
              style={{ flexDirection: "row" }}
            >
              <div className="half-s">
                <div className="half-row">
                  <div className="half-r" style={{ width: "85px" }}>
                    <div className="modal-icon-name">{trans("block")}</div>
                    <div className="input-section" style={{ marginTop: "2px" }}>
                      <div className="input-holder">
                        <div
                          className="full-input-element pa"
                          style={{ width: "85px" }}
                        >
                          <InputText
                            noFloat={true}
                            disabled={mode === "read"}
                            pad={false}
                            clear={() => {
                              dispatch({
                                type: "NEW-OFF",
                                payload: { ...data, blok: "" },
                              });
                            }}
                            lang={data.blok}
                            setLang={(e) => {
                              dispatch({
                                type: "NEW-OFF",
                                payload: { ...data, blok: e.target.value },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="half-r" style={{ width: "85px" }}>
                    <div className="modal-icon-name">{trans("floor")}</div>
                    <div className="input-section" style={{ marginTop: "2px" }}>
                      <div className="input-holder">
                        <div
                          className="full-input-element pa"
                          style={{ width: "85px" }}
                        >
                          <InputText
                            noFloat={true}
                            disabled={mode === "read"}
                            pad={false}
                            clear={() => {
                              dispatch({
                                type: "NEW-OFF",
                                payload: { ...data, floor: "" },
                              });
                            }}
                            lang={data.floor}
                            setLang={(e) => {
                              dispatch({
                                type: "NEW-OFF",
                                payload: { ...data, floor: e.target.value },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="half-r"
                  style={{ width: "100%", marginTop: "12px" }}
                >
                  <div className="modal-icon-name">{trans("phone")}</div>
                  <div className="input-section" style={{ marginTop: "2px" }}>
                    <div className="input-holder">
                      <div
                        className="full-input-element pa"
                        style={{ width: "100%" }}
                      >
                        <span className="pho_code">
                          +<span>{data.phone_code.replace("+", "")}</span>
                        </span>

                        <InputText
                          id="phone1"
                          noFloat={true}
                          disabled={mode === "read"}
                          pad={false}
                          clear={() => {
                            dispatch({
                              type: "NEW-OFF",
                              payload: { ...data, phone_number_1: "" },
                            });
                          }}
                          lang={getPhone(data.phone_number_1)}
                          setLang={(e) => {
                            dispatch({
                              type: "NEW-OFF",
                              payload: {
                                ...data,
                                phone_number_1: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="half-r"
                  style={{ width: "100%", marginTop: "12px" }}
                >
                  <div className="modal-icon-name">{trans("gsm")}</div>
                  <div className="input-section" style={{ marginTop: "2px" }}>
                    <div className="input-holder">
                      <div
                        className="full-input-element pa"
                        style={{ width: "100%" }}
                      >
                        <span className="pho_code">
                          +<span>{data.phone_code.replace("+", "")}</span>
                        </span>

                        <InputText
                          id="gsm1"
                          noFloat={true}
                          disabled={mode === "read"}
                          pad={false}
                          clear={() => {
                            dispatch({
                              type: "NEW-OFF",
                              payload: { ...data, gsm_1: "" },
                            });
                          }}
                          lang={getPhone(data.gsm_1)}
                          setLang={(e) => {
                            dispatch({
                              type: "NEW-OFF",
                              payload: { ...data, gsm_1: e.target.value },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="half-r"
                  style={{ width: "100%", marginTop: "12px" }}
                >
                  <div className="modal-icon-name">{trans("website")}</div>
                  <div className="input-section" style={{ marginTop: "2px" }}>
                    <div className="input-holder">
                      <div
                        className="full-input-element pa"
                        style={{ width: "100%" }}
                      >
                        <InputText
                          noFloat={true}
                          disabled={mode === "read"}
                          pad={false}
                          clear={() => {
                            dispatch({
                              type: "NEW-OFF",
                              payload: { ...data, office_website: "" },
                            });
                          }}
                          lang={data.office_website}
                          setLang={(e) => {
                            dispatch({
                              type: "NEW-OFF",
                              payload: {
                                ...data,
                                office_website: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="half-s" style={{ marginLeft: "26px" }}>
                <div className="half-row">
                  <div className="half-r" style={{ width: "85px" }}>
                    <div className="modal-icon-name">No</div>
                    <div className="input-section" style={{ marginTop: "2px" }}>
                      <div className="input-holder">
                        <div
                          className="full-input-element pa"
                          style={{ width: "85px" }}
                        >
                          <InputText
                            noFloat={true}
                            disabled={mode === "read"}
                            pad={false}
                            clear={() => {
                              dispatch({
                                type: "NEW-OFF",
                                payload: { ...data, No: "" },
                              });
                            }}
                            lang={data.No}
                            setLang={(e) => {
                              dispatch({
                                type: "NEW-OFF",
                                payload: { ...data, No: e.target.value },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="half-r" style={{ width: "85px" }}>
                    <div className="modal-icon-name">{trans("post_cod")}</div>
                    <div className="input-section" style={{ marginTop: "2px" }}>
                      <div className="input-holder">
                        <div
                          className="full-input-element pa"
                          style={{ width: "85px" }}
                        >
                          <InputText
                            noFloat={true}
                            disabled={mode === "read"}
                            pad={false}
                            clear={() => {
                              dispatch({
                                type: "NEW-OFF",
                                payload: { ...data, office_post_code: "" },
                              });
                            }}
                            lang={data.office_post_code}
                            setLang={(e) => {
                              dispatch({
                                type: "NEW-OFF",
                                payload: {
                                  ...data,
                                  office_post_code: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="half-r"
                  style={{ width: "100%", marginTop: "12px" }}
                >
                  <div className="modal-icon-name">{trans("ph_2")}</div>
                  <div className="input-section" style={{ marginTop: "2px" }}>
                    <div className="input-holder">
                      <div
                        className="full-input-element pa"
                        style={{ width: "100%" }}
                      >
                        <span className="pho_code">
                          +<span>{data.phone_code.replace("+", "")}</span>
                        </span>
                        <InputText
                          id="phone2"
                          noFloat={true}
                          disabled={mode === "read"}
                          pad={false}
                          clear={() => {
                            dispatch({
                              type: "NEW-OFF",
                              payload: { ...data, phone_number_2: "" },
                            });
                          }}
                          lang={getPhone(data.phone_number_2)}
                          setLang={(e) => {
                            dispatch({
                              type: "NEW-OFF",
                              payload: {
                                ...data,
                                phone_number_2: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="half-r"
                  style={{ width: "100%", marginTop: "12px" }}
                >
                  <div className="modal-icon-name">{trans("gsm_2")}</div>
                  <div className="input-section" style={{ marginTop: "2px" }}>
                    <div className="input-holder">
                      <div
                        className="full-input-element pa"
                        style={{ width: "100%" }}
                      >
                        <span className="pho_code">
                          +<span>{data.phone_code.replace("+", "")}</span>
                        </span>
                        <InputText
                          id="gsm2"
                          noFloat={true}
                          disabled={mode === "read"}
                          pad={false}
                          clear={() => {
                            dispatch({
                              type: "NEW-OFF",
                              payload: { ...data, gsm_2: "" },
                            });
                          }}
                          lang={getPhone(getPhone(data.gsm_2))}
                          setLang={(e) => {
                            dispatch({
                              type: "NEW-OFF",
                              payload: { ...data, gsm_2: e.target.value },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="half-r"
                  style={{ width: "100%", marginTop: "12px" }}
                >
                  <div className="modal-icon-name">{trans("mail")}</div>
                  <div className="input-section" style={{ marginTop: "2px" }}>
                    <div className="input-holder">
                      <div
                        className="full-input-element pa"
                        style={{ width: "100%" }}
                      >
                        <InputText
                          noFloat={true}
                          disabled={mode === "read"}
                          pad={false}
                          clear={() => {
                            dispatch({
                              type: "NEW-OFF",
                              payload: { ...data, office_mail: "" },
                            });
                          }}
                          lang={data.office_mail}
                          setLang={(e) => {
                            dispatch({
                              type: "NEW-OFF",
                              payload: { ...data, office_mail: e.target.value },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
        break;

      default:
        break;
    }
  };
  const ShowData = () => {
    if (post) {
      return (
        <>
          <div className="modal-input-section" style={{ marginTop: "-5px" }}>
            <div className="icons-header">
              <div
                onClick={() => setModal_sec(4)}
                className={"icon-header" + ` ${modal_sec === 4 && "active"}`}
              >
                <Links />
              </div>
              <div
                onClick={() => setModal_sec(3)}
                className={"icon-header" + ` ${modal_sec === 3 && "active"}`}
              >
                <Images />
              </div>
              <div
                onClick={() => setModal_sec(2)}
                className={"icon-header" + ` ${modal_sec === 2 && "active"}`}
              >
                <Docs />
              </div>
              <div
                onClick={() => setModal_sec(1)}
                className={"icon-header" + ` ${modal_sec === 1 && "active"}`}
              >
                <Info />
              </div>
            </div>
          </div>
          {showModalData()}
        </>
      );
    } else {
      return (
        <>
          <div className="modal-input-section" style={{ marginTop: "0px" }}>
            <div className="modal-icon-name">{trans("off_coun_add")}</div>
            <div className="input-section">
              <div className="input-holder">
                <div
                  className="full-input-element pa"
                  style={{ width: "122px" }}
                >
                  <InputText
                    placeholder="Post Code"
                    noFloat={true}
                    disabled={mode === "read"}
                    pad={false}
                    clear={() => { }}
                    lang={data.parent_post_code ? data.parent_post_code : ""}
                    setLang={(e) => {
                      dispatch({
                        type: "NEW-OFF",
                        payload: { ...data, parent_post_code: e.target.value },
                      });
                    }}
                  />
                  {floading ? (
                    <span className="locat">
                      <Spinner />
                    </span>
                  ) : (
                    <span className="locat" onClick={() => getPost()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.004"
                        height="12.004"
                        viewBox="0 0 12.004 12.004"
                      >
                        <g
                          id="Group_7628"
                          data-name="Group 7628"
                          transform="translate(0 0.001)"
                        >
                          <circle
                            id="Ellipse_328"
                            data-name="Ellipse 328"
                            cx="2.201"
                            cy="2.201"
                            r="2.201"
                            transform="translate(3.801 3.8)"
                            fill="#8e8e8e"
                          />
                          <path
                            id="Path_18940"
                            data-name="Path 18940"
                            d="M7.5,1.5a6,6,0,1,0,6,6A6,6,0,0,0,7.5,1.5Zm0,10.2a4.2,4.2,0,1,1,4.2-4.2,4.2,4.2,0,0,1-4.2,4.2Z"
                            transform="translate(-1.5 -1.501)"
                            fill="#8e8e8e"
                          />
                        </g>
                      </svg>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };
  useEffect(() => {
    if (mode !== "add") {
      setPost(true);
    } else {
      if (data.parent_post_code && data.parent_post_code.length > 0)
        setPost(true);
    }
  }, [mode]);
  useEffect(() => {
    if (data.parent_post_code && data.parent_post_code.length > 0)
      setPost(true);
  }, []);
  return (
    <>
      {mode !== "read" && (
        <div
          onClick={(e) => shake(e)}
          className={`lang-modalDisable ${open && "open"}`}
        ></div>
      )}
      <div
        style={{ width: "428px", height: post ? "auto" : "171px" }}
        className={`lang-modal ${open && "open"} ${props.fixModal}`}
      >
        <div className="modal-top">
          <div className="modal-top-title">
            {mode === "add" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="15"
                height="15"
                viewBox="0 0 15 15"
              >
                <defs>
                  <clipPath id="clipPath">
                    <rect
                      id="Rectangle_4527"
                      data-name="Rectangle 4527"
                      width="15"
                      height="15"
                      transform="translate(324 333)"
                      fill="none"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Mask_Group_16"
                  data-name="Mask Group 16"
                  transform="translate(-324 -333)"
                  clipPath="url(#clipPath)"
                >
                  <path
                    id="add-3"
                    d="M13.235,5.714H9.286V1.765a1.786,1.786,0,0,0-3.571,0v3.95H1.765a1.786,1.786,0,0,0,0,3.571h3.95v3.95a1.786,1.786,0,0,0,3.571,0V9.286h3.95a1.786,1.786,0,0,0,0-3.571Z"
                    transform="translate(324 333)"
                    fill="#404040"
                  />
                </g>
              </svg>
            ) : (
              <>
                {data.flat_photo_path ? (
                  <svg
                    className="emp-dept no-fill"
                    data-src={FILE_SERVER + data.flat_photo_path}
                    width="23"
                    height="15"
                  ></svg>
                ) : (
                  <svg
                    className="emp-dept"
                    data-src={Empty}
                    width="23"
                    height="15"
                  ></svg>
                )}
              </>
            )}
            {/* ATTENTION!! ADDING CONDITION NEXT LINE */}

            {mode === "add" ? (
              <div className="modal-title">{trans("add")} {trans("new_off")}</div>
            ) : (
              <div className="modal-title">
                {mode === "update" ? trans("update") : ""} {`${modalData.name}`} {trans("office")}
              </div>
            )}
          </div>
          {mode !== "read" ? (
            <div className="modal-top-buttons">
              <div
                className={`add-button ${!ButtonValidate() && "disabled-button"
                  }`}
                onClick={() => {
                  sendRequest();
                  if (ButtonValidate()) {
                    props.Close();
                    setPost(false);
                  }
                }}
              >
                {mode === "add" ? trans("add") : trans("update")}
              </div>
              <div
                className="close-button"
                onClick={() => {
                  setOpen(false);
                  props.Close();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.9"
                  height="9.899"
                  viewBox="0 0 9.9 9.899"
                >
                  <g
                    id="Group_4637"
                    data-name="Group 4637"
                    transform="translate(-224.153 -513.36) rotate(45)"
                  >
                    <line
                      id="Line_962"
                      data-name="Line 962"
                      x1="12"
                      transform="translate(522.5 204.5)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                    <line
                      id="Line_963"
                      data-name="Line 963"
                      x1="12"
                      transform="translate(528.5 198.5) rotate(90)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </div>
            </div>
          ) : (
            <div className="modal-top-buttons">
              <div
                className="icon-lang marg"
                onClick={() => {
                  props.Update(props.data, props.data.parent_post_code);
                }}
              >
                <div className="icon-lang-desc">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <defs>
                      <filter
                        id="Ellipse_319"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood floodOpacity="0.2" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g
                      transform="matrix(1, 0, 0, 1, 0, 0)"
                      filter="url(#Ellipse_319)"
                    >
                      <circle
                        id="Ellipse_319-2"
                        data-name="Ellipse 319"
                        cx="3"
                        cy="3"
                        r="3"
                        transform="translate(9 6)"
                        fill="#404040"
                      />
                    </g>
                  </svg>
                  {/* ATTENTION!! ADDING CONDITION NEXT LINE */}
                  <div
                    className="icon-lang-desc-bu"
                    style={{ whiteSpace: "nowrap", left: "-24px" }}
                  >
                    {trans("edit")}
                  </div>
                </div>
                <svg
                  className="gray-color"
                  id="_15x15_photo_back"
                  data-name="15x15 photo back"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                >
                  <g data-name="Mask Group 65">
                    <g
                      id="Group_4666"
                      data-name="Group 4666"
                      transform="translate(0.063 0)"
                    >
                      <g id="user" transform="translate(0.258)">
                        <path
                          id="Path_16021"
                          data-name="Path 16021"
                          d="M13.436,24.162l1.583-.366L13.8,22.579Z"
                          transform="translate(-6.428 -9.162)"
                          fill="#8e8e8e"
                        />
                        <path
                          id="Path_16022"
                          data-name="Path 16022"
                          d="M9.213,18.943l.262-1.136v-.047l.006-.014v-.009l.008-.014v-.008L9.5,17.7l.006-.008.011-.012h0l3.531-3.531a5.421,5.421,0,0,0-4.867-3.239A5.239,5.239,0,0,0,4.19,12.762a7.346,7.346,0,0,0-1.668,4.726A13.977,13.977,0,0,0,8.178,19a10.04,10.04,0,0,0,1.035-.055Z"
                          transform="translate(-2.521 -4.985)"
                          fill="#8e8e8e"
                        />
                        <path
                          id="Path_16023"
                          data-name="Path 16023"
                          d="M0,0H5.927V2.156H0Z"
                          transform="translate(7.595 12.882) rotate(-45)"
                          fill="#8e8e8e"
                        />
                        <circle
                          id="Ellipse_318"
                          data-name="Ellipse 318"
                          cx="2.694"
                          cy="2.694"
                          r="2.694"
                          transform="translate(2.963)"
                          fill="#8e8e8e"
                        />
                        <path
                          id="Path_16024"
                          data-name="Path 16024"
                          d="M23.32,15.209a1.078,1.078,0,0,0-1.84-.762L23,15.966A1.07,1.07,0,0,0,23.32,15.209Z"
                          transform="translate(-9.309 -6.137)"
                          fill="#8e8e8e"
                        />
                      </g>
                      <rect
                        id="Rectangle_4536"
                        data-name="Rectangle 4536"
                        width="14.875"
                        height="14.875"
                        transform="translate(0 0.125)"
                        fill="none"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div
                className="close-button"
                onClick={() => {
                  props.Close();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.9"
                  height="9.899"
                  viewBox="0 0 9.9 9.899"
                >
                  <g
                    id="Group_4637"
                    data-name="Group 4637"
                    transform="translate(-224.153 -513.36) rotate(45)"
                  >
                    <line
                      id="Line_962"
                      data-name="Line 962"
                      x1="12"
                      transform="translate(522.5 204.5)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                    <line
                      id="Line_963"
                      data-name="Line 963"
                      x1="12"
                      transform="translate(528.5 198.5) rotate(90)"
                      fill="none"
                      stroke="#ff2e2e"
                      strokeLinecap="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </div>
            </div>
          )}
        </div>
        <div className="modal-body">{ShowData()}</div>
      </div>
    </>
  );
}

export default CompainesModal;
