import WA from "../../../assets/wa.mp3"

const compares = (a, b) => {
  if (parseInt(new Date(a?.messages[a?.messages.length - 1]?.sent_at).getTime()) < parseInt(new Date(b?.messages[b?.messages.length - 1]?.sent_at).getTime())) {
    return 1
  }
  else {

    if (a.messages.length === 0 || b.messages.length === 0) {
      return 1
    }
    else {
      return -1
    }
  }
}

const sortMessages = (array) => {
  return array.sort((a, b) => {
    if (parseInt(new Date(a?.sent_at).getTime()) > parseInt(new Date(b?.sent_at).getTime())) {
      return 1
    }
    else {
      return -1
    }
  })
}
const sortFoundMessages = (array) => {
  return array.sort((a, b) => {
    if (parseInt(new Date(a?.sent_at).getTime()) < parseInt(new Date(b?.sent_at).getTime())) {
      return 1
    }
    else {
      return -1
    }
  })
}
const getLastMessage = (chat) => {
  var maxValue = Number.MIN_VALUE;
  var lst

  if (chat && chat?.messages?.length > 0)
    lst = chat.messages.filter((s) => ((s.whatsapp_chat_id === chat?.id) || (s.cid === chat.id) || (s.chat_id === chat?.id)))[0];
  for (var i = 0; i < chat?.messages?.length; i++) {
    if (new Date(chat?.messages[i].sent_at).getTime() > maxValue) {
      maxValue = new Date(chat?.messages[i].sent_at).getTime();
      lst = chat?.messages[i];
    }
  }
  return lst;
};
function addSeconds(date, seconds) {
  // Making a copy with the Date() constructor
  const dateCopy = new Date(date);
  dateCopy.setSeconds(new Date(date).getSeconds() + seconds);

  return dateCopy;
}
const initialState = {
  refresh: false,
  forwardedList: [],
  search_progress: false,
  search_progress_contact: false,
  chats: [],
  activeChat: null,
  wa_loading: false,
  odoo_loading: false,
  userTokenWhatsapp: null,
  username: "",
  password: "",
  is_fetch: false,
  sending: false,
  refs: false,
  scr: false,
  search: "",
  replyMainMessage: null,
  founds: [],
  foundsContact: [],
  base_url: null,
  base_user: null,
  first: false,
  forwarderMessage: null,
  vcard: null,
  qoutedMessage: null,
  wordsearch: "",
  roles: [],
  companies: [],
  sec: false,
  contacts: [],
  WAwidth: localStorage.getItem("WA-WIDTH") ?? null,
  viewDisplay: localStorage.getItem("WA-WIDTH-VAR") === "true" ?? false,
  WAP: false,
  searchInput: "",
  searchInputContact: "",
  mode: "customer_service",
  ticket_loading_type: null,
  odooIds: [],
  config: {
    admin_mobile_phone: '',
    ramaaz_system_user_id: null,
    failed_message_sender_notifiers: null,
    system_wallets_addresses: [],
    apply_hash_check_to_whatsapp_chats_id: []
  },
  searchMode: false,
  afterDone: true,
  reachedEnd: false,
  showUnRead: false,
  adminChat: null,
  customerAdminChat: null,
  operationAdminChat: null
};
const sortChats = (arr) => {
  let l = arr.sort((a, b) => compares(a, b))
  return l
}

export const whatsappReducer = (state = initialState, action) => {
  switch (action.type) {
    case "WE_REFRESHING_NOW": {
      return ({
        ...state,
        refresh: true
      })
    }
    case "GET_CHAT_ADMIN_WA_RED": {
      let mode = action.payload.mode
      if (mode) {
        if (mode === "customer_service") {
          let chats = state.chats
          let admin = action.payload.data
          if (chats?.filter(ch => ch.id === admin.id).length === 0) {
            chats.push(admin)
            return {
              ...state,
              customerAdminChat: admin,
              chats: sortChats(chats)
            }
          } else {
            return {
              ...state,
              customerAdminChat: admin
            }
          }
        } else {
          let chats = state.chats
          let admin = action.payload.data
          if (chats?.filter(ch => ch.id === admin.id).length === 0) {
            chats.push(admin)
            return {
              ...state,
              operationAdminChat: admin,
              chats: sortChats(chats)
            }
          } else {
            return {
              ...state,
              operationAdminChat: admin
            }
          }
        }
      } else {
        let chats = state.chats
        let admin = action.payload.data
        if (chats?.filter(ch => ch.id === admin.id).length === 0) {
          chats.push(admin)
          return {
            ...state,
            adminChat: admin,
            chats: sortChats(chats)
          }
        } else {
          return {
            ...state,
            adminChat: admin
          }
        }
      }
    }
    case "setUnread": {
      return {
        ...state,
        showUnRead: action.payload
      }
    }
    case "WE_REFRESHING_NOW_END": {
      return ({
        ...state,
        refresh: false
      })
    }

    case "CHAT-TICKET-NEW": {
      const ticket = action.payload.ticket
      let odooIds = state.odooIds
      let active = action.payload
      let chats = state.chats
      active.messages.push({
        id: 42211221133,
        ack: null,
        body: null,
        media: null,
        fromMe: 1,
        type: "ticket_hint",
        sent_at: active.messages[0] ? active.messages[0].sent_at : new Date(),
        isForwarded: null,
        isMentioned: 0,
        mentionedIds: null,
        quotedMsg: null,
        whatsapp_chat_id: action.payload.id,
        sender_employee_user_id: 628,
        received_from_contact_id: null,
        created_at: active.messages[0] ? active.messages[0].created_at : new Date(),
        self: 0,
        file_name: null,
        mentions: null,
        is_ticket: 0,
        ticket_id: action.payload.ticket_id,
        file_ext: "",
        ticket: ticket
      })
      chats.push(active)
      odooIds.push({ id: action.payload.id, ticket_id: action.payload.ticket_id, number_type: action.payload.number_type })
      return ({
        ...state,
        chats: chats,
        activeChat: active,
        odooIds: odooIds,
        WAP: true
      })
    }
    case "REMOVE_ODOO_ID": {
      let odooIds = state.odooIds.filter(odoo => odoo.id !== action.payload)
      return ({
        ...state,
        odooIds: odooIds
      })
    }
    case "CHAT-TICKET": {
      const ticket = action.payload.ticket
      let odooIds = state.odooIds
      let active = state.chats.filter((chat) => chat.id === action.payload.id)[0]
      let last_message = getLastMessage(active)
      if (active.messages && active.messages.filter(mes => mes.id === 42211221133).length === 0) {
        active.messages.push({
          id: 42211221133,
          ack: null,
          body: null,
          media: null,
          fromMe: 1,
          type: "ticket_hint",
          sent_at: last_message ? addSeconds(last_message.sent_at, 20) : new Date(),
          isForwarded: null,
          isMentioned: 0,
          mentionedIds: null,
          quotedMsg: null,
          whatsapp_chat_id: action.payload.id,
          sender_employee_user_id: 628,
          received_from_contact_id: null,
          created_at: last_message ? addSeconds(last_message.created_at, 20) : new Date(),
          self: 0,
          file_name: null,
          mentions: null,
          is_ticket: 0,
          ticket_id: action.payload.ticket_id,
          file_ext: "",
          ticket: ticket
        })
      }
      let chats = []
      if (state.chats.filter(ch => ch.id === action.payload.id).length > 0) {
        chats = state.chats.map(chat => {
          if (chat.id === action.payload.id) {
            let array = chat.messages
            if (array && array.filter(mes => mes.id === 42211221133).length === 0) {
              array.unshift({
                id: 42211221133,
                ack: null,
                body: null,
                media: null,
                fromMe: 1,
                type: "ticket_hint",
                sent_at: last_message ? addSeconds(last_message.sent_at, 20) : new Date(),
                isForwarded: null,
                isMentioned: 0,
                mentionedIds: null,
                quotedMsg: null,
                whatsapp_chat_id: action.payload.id,
                sender_employee_user_id: 628,
                received_from_contact_id: null,
                created_at: last_message ? addSeconds(last_message.created_at, 20) : new Date(),
                self: 0,
                file_name: null,
                mentions: null,
                is_ticket: 0,
                ticket_id: action.payload.ticket_id,
                file_ext: "",
                ticket: ticket
              })
            }
            return ({
              ...chat, messages: array
            })
          } else {
            return (chat)
          }
        })
      } else {
        chats = state.chats
        chats.unshift(active)
      }
      odooIds.push({ id: action.payload.id, ticket_id: action.payload.ticket_id, number_type: action.payload.number_type })
      return ({
        ...state,
        chats: chats,
        odooIds: odooIds,
        activeChat: {
          ...active,
          isOddoClient: true,
          ticket_id: action.payload.ticket_id
        },
        WAP: true,
      })
    }
    case "GET_CONFIG_RED": {
      return {
        ...state,
        config: action.payload
      }
    }
    case "change_mode_whatsapp": {
      return ({
        ...state,
        mode: action.payload.mode
      })
    }
    case "log_out": {
      return initialState
    }
    case "SET-REPLY-MESSAGE": {
      return ({
        ...state,
        replyMainMessage: action.payload
      })
    }
    case "OPEN-WA": {
      return ({
        ...state,
        WAP: action.payload
      })
    }
    case "input_change": {
      return ({
        ...state,
        searchInput: action.payload
      })
    }
    case "input_change_contact": {
      return ({
        ...state,
        searchInputContact: action.payload
      })
    }
    case "WA-WIDTH": {
      return ({
        ...state,
        WAwidth: action.payload
      })
    }
    case "VIEW_DISPLAY": {
      localStorage.setItem("WA-WIDTH-VAR", action.payload)
      return ({
        ...state,
        viewDisplay: action.payload
      })
    }

    case "REM_WA_ONE_CHAT": {

      return ({
        ...state,
        chats: state.chats.filter((s) => s.id !== action.id),
        activeChat: (state.activeChat?.id === action.id || state.activeChat === null) ? null : state.activeChat
      })
    }

    case "TICKET-LOADING": {
      return ({
        ...state,
        ticket_loading: action.payload.ticket_loading,
        ticket_loading_type: action.payload.ticket_loading_type
      })
    }
    case "CONTACT_ADD": {
      let arr = [...state.contacts]
      if (state.contacts.filter((s) => s.id === action.payload.id || s.number?.includes(action.payload.number)).length === 0) {
        arr.push(action.payload)
      }

      return ({
        ...state,
        contacts: arr
      })
    }
    case "WA_LOADING": {
      return {
        ...state,
        wa_loading: true
      }
    }
    case "WA_DONE": {
      return {
        ...state,
        wa_loading: false
      }
    }
    case "ODOO_LOADING": {
      return {
        ...state,
        odoo_loading: true
      }
    }
    case "ODOO_DONE": {
      return {
        ...state,
        odoo_loading: false
      }
    }
    case "delete_temp_odoo_message": {
      let chat_id = action.payload.chat_id
      let active = state.activeChat
      let chats = state.chats
      if (active) {
        if (active.id === chat_id) {
          active = { ...active, messages: active.messages.filter(mes => mes.id !== 42211221133) }
        }
        chats = chats.map(ch => {
          if (ch.id === chat_id) {
            return active
          } else {
            return ch
          }
        })
        return ({
          ...state,
          chats: chats,
          activeChat: active
        })
      } else {
        chats = chats.map(ch => {
          if (ch.id === chat_id) {
            return { ...ch, messages: ch.messages.filter(mes => mes.id !== 42211221133) }
          } else {
            return ch
          }
        })
        return ({
          ...state,
          chats: chats
        })
      }
    }
    case "UPDATE_HASH_MESSAGE_DATA": {
      const ticket = action.payload?.ticket
      const message_id = action.payload?.message_id
      const chat_id = action.payload?.chat_id
      let new_active_chat = {}
      let new_chats = []
      let new_chat_store = {}
      if (state.activeChat?.id === chat_id && state.activeChat?.id !== null) {
        let new_messages = state.activeChat?.messages?.map((mes) => {
          if (parseInt(mes.id) === parseInt(message_id)) {
            return ({
              ...mes,
              original_hash_message: {
                ...mes.original_hash_message,
                ticket: ticket
              }
            })
          } else {
            return ({
              ...mes
            })
          }
        })
        let new_messages_store = state.chats.filter(one => one.id === chat_id)[0]?.messages?.map((mes) => {
          if (parseInt(mes.id) === parseInt(message_id)) {
            return ({
              ...mes,
              original_hash_message: {
                ...mes.original_hash_message,
                ticket: ticket
              }
            })
          } else {
            return ({
              ...mes
            })
          }
        })
        new_active_chat = {
          ...state.activeChat,
          messages: new_messages
        }
        new_chat_store = {
          ...state.chats.filter(one => one.id === chat_id)[0],
          messages: new_messages_store
        }
        new_chats = state.chats.map((chat) => {
          if (chat.id === chat_id) {
            return ({
              ...new_chat_store,
            })
          } else {
            return ({
              ...chat
            })
          }
        })

        return ({
          ...state,
          activeChat: (state.activeChat !== null && state.activeChat?.id === new_active_chat?.id) ? new_active_chat : state.activeChat,
          chats: new_chats
        })
      } else {
        let new_messages = state.chats?.filter((s) => s.id === chat_id)[0]?.messages?.map((mes) => {
          if (parseInt(mes.id) === parseInt(message_id)) {
            return ({
              ...mes,
              original_hash_message: {
                ...mes.original_hash_message,
                ticket: ticket
              }
            })
          } else {
            return ({
              ...mes
            })
          }
        })
        new_active_chat = {
          ...state.chats?.filter((s) => s.id === chat_id)[0],
          messages: new_messages
        }
        new_chats = state.chats.map((chat) => {
          if (chat.id === chat_id) {
            return ({
              ...new_active_chat
            })
          } else {
            return ({
              ...chat
            })
          }
        })
        return ({
          ...state,
          activeChat: state.activeChat,
          chats: new_chats
        })
      }
    }
    case "MessageReceivedEvent": {
      if (action.payload.with_notification) {
        let not = new Audio(WA);
        not.volume = 0.5
        if (!state.WAP)
          not.play()
      }

      const message = action.payload?.message
      const message_id = action.payload?.message?.id
      const chat_id = action.payload?.message?.whatsapp_chat_id
      let new_active_chat = {}
      let new_chats = []
      let new_chat_store = {}
      if (state.activeChat?.id === chat_id && state.activeChat?.id !== null) {
        let new_messages = state.activeChat?.messages?.map((mes) => {
          if (parseInt(mes.id) === parseInt(message_id)) {
            return ({
              ...mes,
              ...message,
              mid: null
            })
          } else {
            return ({
              ...mes
            })
          }
        })
        let new_messages_store = state.chats.filter(one => one.id === chat_id)[0]?.messages?.map((mes) => {
          if (parseInt(mes.id) === parseInt(message_id)) {
            return ({
              ...mes,
              ...message,
              mid: null,
            })
          } else {
            return ({
              ...mes
            })
          }
        })
        if (state.activeChat?.messages?.filter(mes => mes.id === message_id).length === 0) {
          new_messages.push({
            ...message,
            fromMe: message.fromMe ? 1 : 0
          })
        }
        if (state.chats.filter(one => one.id === chat_id)[0]?.messages?.filter(mes => mes.id === message_id).length === 0) {
          new_messages_store.push({
            ...message,
            fromMe: message.fromMe ? 1 : 0
          })
        }
        new_active_chat = {
          ...state.activeChat,
          unread: !action.payload.message.fromMe ? (!state.chats?.filter((s) => s.id === chat_id)[0].unread ? 1 : parseInt(state.chats?.filter((s) => s.id === chat_id)[0].unread) + 1) : 0,
          messages: new_messages
        }
        new_chat_store = {
          ...state.chats.filter(one => one.id === chat_id)[0],
          unread: !action.payload.message.fromMe ? (!state.chats?.filter((s) => s.id === chat_id)[0].unread ? 1 : parseInt(state.chats?.filter((s) => s.id === chat_id)[0].unread) + 1) : 0,
          messages: new_messages_store?.slice(-20)
        }
        new_chats = state.chats.map((chat) => {
          if (chat.id === chat_id) {
            return ({
              ...new_chat_store,
            })
          } else {
            return ({
              ...chat
            })
          }
        })
        return ({
          ...state,
          activeChat: (state.activeChat !== null && state.activeChat?.id === new_active_chat?.id) ? new_active_chat : state.activeChat,
          chats: sortChats(new_chats)
        })
      }
      else {

        if (state.chats.filter((s) => s.id === chat_id).length > 0) {
          let new_messages = state.chats?.filter((s) => s.id === chat_id)[0]?.messages?.map((mes) => {
            if (parseInt(mes.id) === parseInt(message_id)) {
              return ({
                ...mes,
                ...message,
                mid: null
              })
            } else {
              return ({
                ...mes
              })
            }
          })
          if (state.chats?.filter((s) => s.id === chat_id)[0]?.messages?.filter(mes => mes.id === message_id).length === 0) {
            new_messages.push({
              ...message,
              fromMe: message.fromMe ? 1 : 0
            })
            new_active_chat = {
              ...state.chats?.filter((s) => s.id === chat_id)[0],
              unread: !action.payload.message.fromMe ? (!state.chats?.filter((s) => s.id === chat_id)[0].unread ? 1 : parseInt(state.chats?.filter((s) => s.id === chat_id)[0].unread) + 1) : 0,
              messages: new_messages?.slice(-20)
            }
            new_chats = state.chats.map((chat) => {
              if (chat.id === chat_id) {
                return ({
                  ...new_active_chat
                })
              } else {
                return ({
                  ...chat
                })
              }
            })
            new_chats = [{ ...new_chats.filter((s) => s.id === chat_id)[0] }, ...new_chats.filter((s, i) => (s.id !== chat_id))]
          }
          else {
            new_active_chat = {
              ...state.chats?.filter((s) => s.id === chat_id)[0],
              messages: new_messages?.slice(-20)
            }
            new_chats = state.chats.map((chat) => {
              if (chat.id === chat_id) {
                return ({
                  ...new_active_chat
                })
              } else {
                return ({
                  ...chat
                })
              }
            })
          }
          return ({
            ...state,
            activeChat: state.activeChat,
            chats: sortChats(new_chats)
          })
        }
        else {
          let newchat = { instance_name: "customer_service", isGroup: action.payload.contact.isGroup, id: chat_id, whatsapp_contact: action.payload.contact, whatsapp_contact_id: action.payload.contact.id, messages: [action.payload.message], unread: !action.payload.message.fromMe ? 1 : 0 };
          let arr = [];
          arr.push(newchat)
          state.chats.map((s, i) => {
            if (s.id !== chat_id)
              arr.push(s)
          })
          let active = state.activeChat
          return ({
            ...state,
            chats: arr,
            activeChat: typeof (active?.id) === "string" ? newchat : active
          })
        }
      }
    }
    case "FORWARD-MESSAGE": {
      return ({
        ...state,
        forwarderMessage: action.payload
      })
    }
    case "VCARD-MESSAGE": {
      return ({
        ...state,
        vcard: action.payload
      })
    }
    case "SEARCH_WA_CONV": {
      if (action.payload?.length === 0) {
        return ({
          ...state,
          searchInputContact: "",
          founds: [],
          foundsContact: []
        })
      } else {
        return ({
          ...state,
          searchInputContact: action.payload
        })
      }
    }
    case "WORD_SEARCH": {
      return ({
        ...state,
        wordsearch: action.payload
      })
    }
    case "FETCH_URL": {
      return {
        ...state,
        base_url: action?.payload?.base,
        base_user: action?.payload?.base_user,
      };
    }
    case "SEARCH_WA": {
      let arr = []
      if (action.payload.data.length > 0) {
        action.payload.data.map((ch) => {
          ch.messages.map((mes) => {
            arr.push({ ...mes, ch: ch })
          })
        })
      }
      return {
        ...state,
        scr: !state.scr,
        founds: sortFoundMessages(arr)
      };
    }
    case "SEARCH_WA_CONTACT": {
      let arr = action.payload.data
      return {
        ...state,
        scr: !state.scr,
        foundsContact: arr
      };
    }
    case "SEARCH_PROGRESS": {
      return ({
        ...state,
        search_progress: action.payload
      })
    }
    case "SEARCH_PROGRESS_CONTACT": {
      return ({
        ...state,
        search_progress_contact: action.payload
      })
    }
    case "SEARCH_WA_IN": {
      return ({
        ...state,
        search: action.payload
      })
    }
    case "GI_RED": {
      let arr = [];
      let ac = state.activeChat;
      if (ac && ac?.id === action.payload.id) {
        ac = { ...state.activeChat, info: action.payload.data };
      }
      state.chats.map((ch) => {
        if (ch.id === action.payload.id) {
          arr.push({ ...ch, info: action.payload.data });
        } else {
          arr.push(ch);
        }
      });
      return {
        ...state,
        chats: sortChats(arr),
        activeChat: ac,
      };
    }
    case "SA": {
      state.chats.unshift(action.payload);

      return {
        ...state,
      };
    }
    case "SEND_TEXT": {
      if (action.payload) {
        let arr = [];
        let first_var = action.payload.id?.includes("true") ? !state.first : state.first
        let act = state.activeChat.messages;
        if (state.chats.filter((c) => c.id === state.activeChat.id).length === 0) {
          if (state.chats.length === 0 || state.chats.filter((c) => c.id === state.activeChat.id).length === 0) {
            arr.push({ ...state.activeChat, messages: [...act, action.payload], })
          }
          else {
            arr.push({
              ...state.activeChat,
              messages: act,
            })
          }
        }
        state.chats.map((ch) => {
          if (ch.id === action.payload.chat_id) {
            let mes = ch.messages
            mes.push(action.payload);
            arr.unshift({ ...ch, messages: mes });
          } else {
            arr.push(ch);
          }
        });
        let acts = state.activeChat
        if (arr.filter((d) => d.id === acts.id).length === 0) {
          return {
            ...state,
            activeChat: {
              ...state.activeChat,
              messages: act,
            },
            chats: [{
              ...state.activeChat,
              messages: act,
            }, ...arr],
            refs: !state.refs,
            first: first_var
          };
        }
        else
          return {
            ...state,
            activeChat: arr.filter((s) => s.id === state.activeChat.id)[0],
            chats: arr,
            refs: !state.refs,
            first: first_var
          };

      } else {
        return {
          ...state,
          sending: true
        };
      }
    }
    case "SEND_P": {
      return {
        ...state,
        sending: true,
      };
    }
    case "DELETE_ME_RESEND": {
      let arr = [];
      let ac = state.activeChat;
      state.chats.map((ch) => {
        if (parseInt(ch.id) === parseInt(action.payload.chat_id)) {
          let mes = []
          ch.messages.map((m) => {
            if (m.mid && (m?.mid !== action.payload.local_message_id)) {
              if (m?.mid !== action.payload.local_message_id) {
                mes.push(m);
              }
            } else {
              if ((m?.id !== action.payload.message_id)) {
                mes.push(m);
              }
            }
          })
          arr.push({ ...ch, messages: mes, id: action.payload.chat_id ? parseInt(action.payload.chat_id) : ch.id });
        } else {
          arr.push(ch);
        }
      })
      if (parseInt(state.activeChat?.id) === parseInt(action.payload.chat_id)) {
        let mes = []
        state.activeChat.messages.map((m) => {
          if (m.mid && (m?.mid !== action.payload.local_message_id)) {
            if (m?.mid !== action.payload.local_message_id) {
              mes.push(m);
            }
          } else {
            if ((m?.id !== action.payload.message_id)) {
              mes.push(m);
            }
          }
        })
        ac = {
          ...ac,
          isOddoClient: false,
          messages: mes,
          id: action.payload.chat_id ? parseInt(action.payload.chat_id) : ac.id
        }
      }
      return {
        ...state,
        chats: arr,
        activeChat: ac,
        refs: !state.refs,
        first: !state.first,
        sending: false
      }
    }
    case "UPLOAD_SUC": {
      if (action.payload.resend) {
        let arr = [];
        let ac = state.activeChat;
        state.chats.map((ch) => {
          if (parseInt(ch.id) === parseInt(action.payload.cid)) {
            let mes = [];
            let message = ch.messages.filter((one) => (one.mid && (one?.mid === action.payload.mid)) || (one?.id === action.payload.id))[0]
            ch.messages.map((m) => {
              if (m.mid && (m?.mid !== action.payload.mid)) {
                if (m?.mid !== action.payload.mid) {
                  mes.push(m);
                }
              } else {
                if ((m?.id !== action.payload.id)) {
                  mes.push(m);
                }
              }
            });
            mes.push({ ...message, ...action.payload });
            arr.push({ ...ch, messages: mes, id: action.payload.chat_id ? parseInt(action.payload.chat_id) : ch.id });
          } else {
            arr.push(ch);
          }
        });
        if (parseInt(state.activeChat?.id) === parseInt(action.payload.cid)) {
          let mes = [];
          let message = state.activeChat.messages.filter((one) => (one.mid && (one?.mid === action.payload.mid)) || (one?.id === action.payload.id))[0]
          state.activeChat.messages.map((m) => {
            if (m.mid && (m?.mid !== action.payload.mid)) {
              if (m?.mid !== action.payload.mid) {
                mes.push(m);
              }
            } else {
              if ((m?.id !== action.payload.id)) {
                mes.push(m);
              }
            }
          });
          mes.push({ ...message, ...action.payload })
          ac = {
            ...ac,
            isOddoClient: false,
            messages: mes,
            id: action.payload.chat_id ? parseInt(action.payload.chat_id) : ac.id
          };
        }
        return {
          ...state,
          chats: arr,
          activeChat: ac,
          refs: !state.refs,
          first: !state.first,
          sending: false
        };
      } else {
        let arr = [];
        let ac = state.activeChat;
        state.chats.map((ch) => {
          if (parseInt(ch.id) === parseInt(action.payload.cid)) {
            let mes = [];
            ch.messages.map((m) => {
              if (m?.mid === action.payload.mid) {
                mes.push({ ...m, mid: action.payload.mid, id: action.payload.id, chat_id: (action.payload.chat_id) ?? ch.id, ack: action.payload.ack, ...action.payload });
              } else {
                mes.push(m);
              }
            });
            arr.push({ ...ch, messages: sortMessages(mes), id: action.payload.chat_id ? parseInt(action.payload.chat_id) : ch.id });
          } else {
            arr.push(ch);
          }
        });
        if (parseInt(state.activeChat?.id) === parseInt(action.payload.cid)) {
          let mes = [];
          state.activeChat.messages.map((m) => {
            if (m?.mid === action.payload.mid) {
              mes.push({ ...m, mid: action.payload.mid, id: action.payload.id, chat_id: (action.payload.chat_id) ?? state.activeChat.id, ack: action.payload.ack, ...action.payload });
            } else {
              mes.push(m);
            }
          });
          ac = {
            ...ac,
            isOddoClient: false,
            messages: sortMessages(mes),
            id: action.payload.chat_id ? parseInt(action.payload.chat_id) : ac.id
          };
        }
        return {
          ...state,
          chats: arr,
          activeChat: ac,
          refs: !state.refs,
          first: !state.first,
          sending: false
        };
      }
    }
    case "SENDING_END": {
      return {
        ...state,
        sending: false,
      };
    }
    case "GET_PAGE_CHAT": {
      return {
        ...state,
        is_fetch: true,
        sending: false
      };
    }
    case "GET_PAGE_CHAT_RED": {
      return {
        ...state,
        is_fetch: false,
      };
    }
    case "fetch": {
      return {
        ...state,
        is_fetch: true,
      };
    }
    case "GET_PAGE": {
      if (state.activeChat?.id === action?.payload?.id) {
        if (action?.payload.messages.length === 0) {
          return {
            ...state,
            is_fetch: false,
            reachedEnd: true
          }
        }
        let news = action?.payload.messages;
        let active = state.activeChat;
        let id = action?.payload?.id;
        let full = state.activeChat?.messages;
        let newarr = [];
        full?.map((a) => {
          if (newarr?.filter((sd) => sd.id === a.id).length === 0)
            newarr?.push(a);
        });
        let newElements = []
        news?.map((a) => {
          if (newarr?.filter((sd) => sd.id === a.id).length === 0)
            newElements?.push(a);
        });
        newarr.unshift(...newElements)
        if (state.activeChat?.id === id) {
          active = {
            ...state.activeChat,
            messages: newarr,
          }
        }
        return {
          ...state,
          activeChat: active,
          is_fetch: false,
          // chats: newsd
        };
      } else {
        return {
          ...state
        }
      }
    }

    case "GET_CHAT_WA_RED": {
      let arr = action.payload?.data
      let chats = state.chats
      let mode = action.payload?.mode
      if (mode === "customer_service") {
        if (chats?.length > 0 && action.payload?.getMore) {
          chats.map(chat => {
            if (arr.filter(one => one.id === chat.id).length > 0) {
              let full = chat.messages
              let news = arr.filter(one => one.id === chat.id)[0]?.messages
              news?.map((a) => {
                if (full?.filter((sd) => sd.id === a.id).length === 0)
                  full?.push(a);
              })
              arr = arr.map(element => {
                if (element.id === chat.id) {
                  return {
                    ...element,
                    messages: sortMessages(full)?.slice(-20)
                  }
                } else {
                  return {
                    ...element
                  }
                }
              })
            } else {
              arr.push(chat)
            }
          })
        } else {
          chats.map(chat => {
            if (chat.instance_name === "operations") {
              arr.push(chat)
            }
          })
        }
      } else if (mode === "operations") {
        if (chats?.length > 0 && action.payload?.getMore) {
          chats.map(chat => {
            if (arr.filter(one => one.id === chat.id).length > 0) {
              let full = chat.messages
              let news = arr.filter(one => one.id === chat.id)[0]?.messages
              news?.map((a) => {
                if (full?.filter((sd) => sd.id === a.id).length === 0)
                  full?.push(a);
              })
              arr = arr.map(element => {
                if (element.id === chat.id) {
                  return {
                    ...element,
                    messages: sortMessages(full)?.slice(-20)
                  }
                } else {
                  return {
                    ...element
                  }
                }
              })
            } else {
              arr.push(chat)
            }
          })
        } else {
          chats.map(chat => {
            if (arr.filter(one => one.id === chat.id).length > 0) {
              let full = chat.messages
              let news = arr.filter(one => one.id === chat.id)[0]?.messages
              news?.map((a) => {
                if (full?.filter((sd) => sd.id === a.id).length === 0)
                  full?.push(a);
              })
              arr = arr.map(element => {
                if (element.id === chat.id) {
                  return {
                    ...element,
                    messages: sortMessages(full)?.slice(-20)
                  }
                } else {
                  return {
                    ...element
                  }
                }
              })
            } else {
              arr.push(chat)
            }
          })
        }
      } else {
        if (chats?.length > 0) {
          chats.map(chat => {
            if (arr.filter(one => one.id === chat.id).length > 0) {
              let full = chat.messages
              let news = arr.filter(one => one.id === chat.id)[0]?.messages
              news?.map((a) => {
                if (full?.filter((sd) => sd.id === a.id).length === 0)
                  full?.push(a);
              })
              arr = arr.map(element => {
                if (element.id === chat.id) {
                  return {
                    ...element,
                    messages: sortMessages(full)?.slice(-20)
                  }
                } else {
                  return {
                    ...element
                  }
                }
              })
            } else {
              arr.push(chat)
            }
          })
        }
      }

      if (!state.activeChat) {
        return {
          ...state,
          chats: sortChats(arr),
          reachedEnd: false
        }
      } else {
        let active = state.activeChat
        let full = active.messages
        let news = arr.filter(one => one.id === active.id)[0]?.messages
        news?.map((a) => {
          if (full?.filter((sd) => sd.id === a.id).length === 0)
            full?.push(a);
        });
        active = {
          ...state.activeChat,
          messages: sortMessages(full)?.slice(-20)
        }
        return {
          ...state,
          chats: sortChats(arr),
          activeChat: active,
          reachedEnd: false
        }
      }
    }
    case "READ_CHAT": {
      let chats = state.chats.map((a) => {
        if (a.id === action.id) {
          return ({ ...a, unread: 0 });
        } else {
          return (a);
        }
      });
      let act = state.activeChat
      if (act?.id) {
        act = { ...state.activeChat, unread: 0 }
      }
      return {
        ...state,
        chats: chats,
        activeChat: act
      };
    }
    case "READ_CHAT_PUSHER": {
      let chats = state.chats.map((a) => {
        if (a.id === action.payload.id) {
          return ({ ...a, unread: 0 });
        } else {
          return (a);
        }
      });
      let act = state.activeChat
      if (act?.id === action.payload.id) {
        act = { ...state.activeChat, unread: 0 }
      }
      return {
        ...state,
        chats: chats,
        activeChat: act
      }
    }
    case "CHAT_ID": {
      let first_var = action.noScroll ? state.first : !state.first
      let active = state.activeChat
      if (action.payload === null) {
        active = null
      }

      else if (state.chats.filter((s) => s.id === action.payload.id).length === 0) {
        if (action.payload?.openVcard) {
          if (state.chats.filter((s) => s?.whatsapp_contact?.number?.includes(action.payload.id)).length > 0) {
            active = state.chats.filter((s) => s?.whatsapp_contact?.number?.includes(action.payload.id))[0]
          } else {
            active = { ...action.payload, isNew: true }
          }
        } else {
          active = action.payload
        }

      }
      else {
        active = state.chats.filter((s) => s.id === action.payload.id)[0]
      }
      return {
        ...state,
        activeChat: active,
        is_fetch: false,
        first: first_var,
        replyMainMessage: null,
        reachedEnd: false,
        searchMode: false
      };
    }

    case "LOGIN_SAGA":
      return {
        ...state,
        username: action.payload?.username,
        password: action.payload?.password,
      };
    case "TOKEN_WHATSAPP":
      return {
        ...state,
        userTokenWhatsapp: action.payload,
      };
    case "CHANGE_NAME_Reducer": {
      let arr = []
      let contact_id = null
      state.chats.map((s) => {
        if (s.id === state?.activeChat?.id) {
          contact_id = s.whatsapp_contact.id
          arr.push({ ...s, name: action.payload.name, whatsapp_contact: { ...s?.whatsapp_contact, name: action.payload.name, whatsapp_contact_company_id: action.payload.cid, whatsapp_contact_role_id: action.payload.rid } })
        }
        else {
          arr.push(s)
        }
      })
      let arrs = [];
      if (state.contacts.filter((s) => s.id === contact_id).length > 0) {
        state.contacts.map((s) => {
          if (s.id === contact_id) {
            arrs.push({ ...s, name: action.payload.name, whatsapp_contact_company_id: action.payload.cid, whatsapp_contact_role_id: action.payload.rid })
          }
          else {
            arrs.push(s)
          }
        })

      }
      else {
        arrs = state.contacts
      }
      return ({
        ...state,
        chats: arr,
        activeChat: arr.filter((s) => s.id === state.activeChat?.id)[0],
        contacts: arrs
      })
    }
    case "CHANGE_NAME": {
      if (state.chats.filter((s) => s.id === action.id).length > 0) {
        let arr = []
        let contact_id = null
        state.chats.map((s) => {
          if (s.id === state?.activeChat?.id) {
            contact_id = s.whatsapp_contact.id
            arr.push({ ...s, name: action.payload.name, whatsapp_contact: { ...s?.whatsapp_contact, name: action.payload.name, whatsapp_contact_company_id: action.payload.cid, whatsapp_contact_role_id: action.payload.rid } })
          }
          else {
            arr.push(s)
          }
        })
        let arrs = [];
        if (state.contacts.filter((s) => s.id === contact_id).length > 0) {
          state.contacts.map((s) => {
            if (s.id === contact_id) {
              arrs.push({ ...s, name: action.payload.name, whatsapp_contact_company_id: action.payload.cid, whatsapp_contact_role_id: action.payload.rid })
            }
            else {
              arrs.push(s)
            }
          })

        }
        else {
          arrs = state.contacts
        }
        return ({
          ...state,
          chats: arr,
          activeChat: arr.filter((s) => s.id === state.activeChat?.id)[0],
          contacts: arrs
        })
      }
      else if (action.isNew && state.activeChat?.id) {
        let active = { ...state.activeChat, whatsapp_contact: { name: action.payload.name, number: action.payload.number }, name: action.payload.name, number: action.payload.number }
        return ({
          ...state,
          activeChat: active
        })
      } else {
        return {
          ...state
        }
      }
    }
    case "GET_MES_RED": if (action.payload.length > 0) {
      let messagesBlock = action?.payload;
      let active = state.activeChat;

      let qoute = null

      if (state.activeChat?.id === action.cid) {
        active = {
          ...state.activeChat,
          messages: messagesBlock,
        }
        qoute = action.id
      }
      return ({
        ...state,
        activeChat: active,
        qoutedMessage: qoute,
        is_fetch: false,
        searchMode: true,
        afterDone: !state.afterDone
      })
    } else {
      return {
        ...state,
        is_fetch: false,
        searchMode: false,
        afterDone: !state.afterDone
      }
    }
    case "GET_MES_RED_AFTER": if (action.payload.length > 0) {
      let full = state.activeChat?.messages;
      let news = action?.payload;
      let newarr = [];
      let active = state.activeChat;
      full?.map((a) => {
        if (newarr?.filter((sd) => sd.id === a.id).length === 0)
          newarr?.push(a);
      });
      news?.map((a) => {
        if (newarr?.filter((sd) => sd.id === a.id).length === 0)
          newarr?.push(a);
      });
      if (state.activeChat?.id === action.cid) {
        active = {
          ...state.activeChat,
          messages: newarr,
        }
      }
      return ({
        ...state,
        activeChat: active,
        is_fetch: false,
        searchMode: true,
        afterDone: !state.afterDone
      })
    } else {
      return {
        ...state,
        is_fetch: false,
        searchMode: false,
        afterDone: !state.afterDone
      }
    }
    case "clearQuotedMessage": {
      return ({
        ...state,
        qoutedMessage: null
      })
    }
    case "GET_MES": {
      return ({
        ...state,
        is_fetch: true
      })
    }
    case "SEARCH_END": {
      return ({
        ...state,
        founds: []
      })
    }
    case "GET_ROLS": {
      return ({
        ...state,
        roles: action.payload
      })
    }
    case "GET_COMPA": {
      return ({
        ...state,
        companies: action.payload
      })
    }
    case "GET_CONTACT": {
      return ({
        ...state,
        contacts: action.payload
      })
    }
    case "UPD_CONTACT": {
      let arr = [];
      state.contacts.map((s) => {
        if (s.id === action.payload.id) {
          arr.push(action.payload)
        }
        else arr.push(s)
      })

      return ({
        ...state,
        contacts: arr
      })
    }
    case "ADD_CONTACT": {

      return ({
        ...state,
        contacts: [...state.contacts, action.payload]
      })
    }
    case "ADD-EMP-TO-CHAT": {
      let active = state.activeChat
      let chats = state.chats
      active = { ...active, employees: [...active.employees, { id: action.payload }] }
      let arr = []
      chats.map((chat) => {
        if (chat.id === active.id) {
          arr.push({ ...active, employees: [...active.employees, { id: action.payload }] })
        }
        else {
          arr.push(chat)
        }
      })
      return ({
        ...state,
        chats: arr,
        activeChat: active
      })
    }
    case "REM-EMP-FROM-CHAT": {
      let active = state.activeChat
      let chats = state.chats
      active = { ...active, employees: active.employees.filter((em) => em.id !== action.payload) }
      let arr = []
      chats.map((chat) => {
        if (chat.id === active.id) {
          arr.push({ ...active, employees: active.employees.filter((em) => em.id !== action.payload) })
        }
        else {
          arr.push(chat)
        }
      })
      return ({
        ...state,
        chats: arr,
        activeChat: active
      })
    }
    case "CHAT-INPUT-FILED": {
      let active = state.activeChat
      let chats = state.chats
      let arr = chats.map((chat) => {
        if (chat.id === action.payload.chat_id) {
          return ({ ...chat, input_value: action.payload.text })
        }
        else {
          return (chat)
        }
      })
      return ({
        ...state,
        chats: arr
      })
    }
    default:
      return state;
  }
};
