import L from 'leaflet';
import mark from './mark.svg'
const MarkerIcon = new L.Icon({
    iconUrl: mark,
    iconRetinaUrl: mark,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(25, 30),
    className: 'leaflet-div-icon'
});
export default MarkerIcon
