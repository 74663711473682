import React, { useEffect, memo } from "react";
import Spinner from "../../../../Spinner"
import date from "date-and-time"
import ProfileNo from "../../../assets/images/profileNo.png"
import parse from 'html-react-parser'
import Ply from "../../../assets/images/ply"
import Paus from "../../../assets/images/paus"
import Cam from "../../../assets/images/cam"
import Spinn from "../../../assets/images/spinner"
import Down from "../../../assets/images/down"
import fil from "../../../assets/images/output.png"
import Wai from "../../../assets/images/wai.js"
import Cons from "../../../assets/images/cons"
import Voc from "../../../assets/images/voc"
import Vide from "../../../assets/images/vid"
import { useState } from "react"
import { useRef } from "react"
import Icon from "../../../components/Icon"
import OptionsMenu from "../../../components/OptionsMenu"
import { useDispatch, useSelector } from "react-redux"
import { FILE_SERVER, getNum } from "../../../../../../constants"
import missedCall from "./missedCall.jpg"
import TicketMessage from "./TicketMessage"
import Rep from "../../../components/svgs/rep"
import Document from "./document"
import TronScan from "./tronScanFloating"
import RS from "../svg/RS.js"
import axios from "axios"
import { toast } from "react-toastify"
import Resend from "../../../assets/icons/resend.js";
import { REACT_APP_ACCOUNTING_URL, REACT_APP_BASE_FILE_URL } from "../../../../../../urls.js";
var vCard = require('vcard-parser')

function Message({
  a,
  active,
  i,
  copy,
  activeChat,
  img,
  setImg,
  vid,
  setVid,
  setReplyMessage,
  GetMessage,
  getAudioMessages,
  submitNewMessage
}) {
  function handleSetUser(contact, id) {
    dispatch({ type: "CHAT_ID", payload: contact })
  }

  // const [update, setUpdate] = useState(1)
  // useEffect(() => {
  //   const interval = setInterval(() => setUpdate(update + 1), 1000);
  //   return () => clearInterval(interval);
  // }, [update])
  const getDiffBetween = ({ date1, date2 }) => {
    let first = new Date(date1)
    let second = new Date(date2)
    let diff = (second.getTime() / 1000) - (first.getTime() / 1000)
    let days = parseInt(diff / (60 * 60 * 24)).toFixed(0)
    let hrs = parseInt(diff / (60 * 60) - days * 24).toFixed(0)
    let min = parseInt((diff / (60) - hrs * 60 - days * 24 * 60)).toFixed(0)
    let seconds = parseInt((diff - min * 60 - hrs * 60 - days * 24 * 60)).toFixed(0)

    if (days > 0) {
      return `${days} d`
    } else if (hrs > 0) {
      return `${hrs} h`
    }
    else if (min > 0) {
      return `${min} m`
    } else {
      return `${seconds} s`
    }
  }
  const [waitApply, setWaitApply] = useState(false)
  const token = useSelector(state => state.whatsapp?.userTokenWhatsapp)
  const user = useSelector(state => state.auth.user)
  const applySend = async (ticket_id) => {
    setWaitApply(true)
    const base_user = REACT_APP_ACCOUNTING_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
    try {
      await AxiosInstace.get(`/api/v1/tickets/${ticket_id}/approve`).then(res => {
        dispatch({
          type: "UPDATE_HASH_MESSAGE_DATA",
          payload: {
            ticket: res?.data?.data,
            chat_id: activeChat?.id,
            message_id: a?.id
          }
        })
        if (res?.data?.data?.approved_by_user_id !== user.id) {
          toast.success("already approved!!")
        }
        setWaitApply(false)
      })
    } catch (e) {
      toast.error("an error occured!!")
      setWaitApply(false)
    }
  }
  const [width, setWidth] = useState(0)
  const [openPreview, setOpenPreview] = useState(false)
  const [openPreview2, setOpenPreview2] = useState(false)
  const [showEmpTip, setShowEmpTip] = useState(false)
  const roles = useSelector(state => state.auth.roles)
  let ids = a?.id
  const dispatch = useDispatch()
  const [opens, setOpen] = React.useState(false)
  const getDir = (s) => {
    if (s?.length > 0) {
      let charStr = s[0]
      var langdic = {
        "arabic": /[\u0600-\u06FF]/,
        "english": /^[a-zA-Z]+$/
        //add other languages her
      }
      let lang = ''
      Object.entries(langdic).forEach(([key, value]) => {  // loop to read all the dictionary items if not true
        if (value.test(charStr) === true) {   //Check Unicode to see which one is true
          lang = key //print language name if unicode true   
        }
      });
      if (lang === "arabic") {
        return "rtl"
      }
      else {
        return "ltr"
      }
    }
    else {
      return "ltr"
    }
  }
  const getText = (sds) => {
    let str = sds?.replaceAll("\\n", '<br>')
    if (str?.includes("@")) {
      const regex = /@\d+/g;
      let ser = str?.match(regex)
      // console.log(ser)
      if (ser && ser.length > 0) {
        ser.forEach((s) => {
          str = str.replace(s, `<span class='blue_tag'> @${(contacts?.filter((con) => ("@" + con.number) === s)[0]?.name || contacts?.filter((con) => ("@" + con.number) === s)[0]?.pushname || sub_contacts?.filter((con) => ("@" + con.number) === s)[0]?.name || sub_contacts?.filter((con) => ("@" + con.number) === s)[0]?.pushname) || s}</span>`)
        })

      }
    }
    let regex = /\*[A-Z]+\*/g
    if (str) {
      let ser = str?.match(regex)
      if (ser && ser.length > 0) {
        ser.forEach((s) => {
          str = str.replace(s, `<span class='BIG_tag'>${s.replace("*", '').replace("*", '')}</span>`)
        })

      }
      if (str !== null) {
        return parse(str?.toString())

      }
      else {
        return sds
      }
    } else {
      return sds
    }
  }
  const handlePlay = () => {
    let played = a?.id
    let auds = getAudioMessages()
    auds?.forEach((aud) => {
      if (aud?.getAttribute("aria-details") !== played)
        aud.currentTime = 0
      aud?.pause()
    })
  }
  const getVcardName = (card) => {
    var myVCard = vCard?.parse(card);
    return (myVCard?.fn[0]?.value)
  }
  const getVcardWaId = (card) => {
    var myVCard = vCard.parse(card);
    if (myVCard?.tel && myVCard?.tel[0]?.meta && myVCard?.tel[0]?.meta?.waid && myVCard?.tel[0]?.meta?.waid[0]) {
      return (myVCard?.tel[0]?.meta?.waid[0])
    }
    else {
      return null
    }
  }
  const getVcardTel = (card) => {
    var myVCard = vCard.parse(card);
    return (myVCard?.tel[0]?.value)
  }
  const handleEnd = () => {
    document.querySelectorAll('audio').forEach((aud) => {
      if (aud.getAttribute("aria-details") !== a?.id) {
        aud.pause()
      }
    })
  }
  const emps = useSelector((state) => state.employee.employee)
  const contacts = useSelector((state) => state.whatsapp.contacts)
  const sub_contacts = useSelector((state) => state.whatsapp.activeChat?.whatsapp_contact?.sub_contacts) || []
  const audioRef = useRef();
  const showTime = (m) => {
    let minutes = parseInt(m / 60);
    let seconds = Math.abs(parseInt(m - minutes * 60));
    if (m)
      return `${minutes > 9 ? minutes : "0" + minutes}:${seconds > 9 ? seconds : "0" + seconds
        }`;
    else return "";
  };
  const [play, setPlay] = useState(false);
  const getMessageTime = () => {
    let no = new Date();
    let d = a.sent_at ? new Date(a?.sent_at) : new Date(a.created_at);
    return date.format(new Date(d), "HH:mm");
  };
  const deleteMessage = () => {
    if (a?.delete_status) {
      dispatch({
        type: "restore_this_message", payload: {
          message_id: a?.id,
          chat_id: activeChat?.id
        }
      })
    } else {
      dispatch({
        type: "delete_this_message", payload: {
          message_id: a?.id,
          chat_id: activeChat?.id
        }
      })
    }
  }
  const showMessage = () => {
    if (a?.delete_status && !roles.includes("USER_FULL_ACCESS")) {
      return (<>
        <div
          onMouseLeave={() => setOpen(false)}
          className={`message-hold ${opens && "ac"} message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
            }`}
        >
          <div
            id={"s" + ids}
            onClick={() => setOpen(true)}


            className={` ${((a?.fromMe === 0 || !a?.fromMe) || (a?.sender_employee_user_id)) &&
              "group_chat_msg"
              } chat__msg ${a?.delete_status ? "chat__msg--deleted" : ""} chat__msg--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
              }`}
          >
            {a?.isForwarded === 1 &&
              <div className="forwarded-icon">
                <Rep />
                <span>Forwarded Message</span>
              </div>}

            {(a?.fromMe === 0 || !a?.fromMe) && (
              <>
                {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                  {contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    : contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                  {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                </div>}
                <img alt="" src={contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
              </>
            )}
            {!a.received_from_contact_id && a?.sender_employee_user_id &&
              <>
                {activeChat?.isGroup === 1 && <div className="group_name">
                  {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                  {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                </div>}
                <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
              </>
            }
            <span>
              This Message Was Deleted By Admin
            </span>
            <span className="chat__msg-filler"> </span>

            <span className="chat__msg-footer">
              {(a?.ack === "fail" && a?.sender_employee_user_id === user.id) && <div className="resend_message_icon" onClick={() => {
                if (a?.id) {
                  dispatch({
                    type: "CHANGE_IN_MESSAGES",
                    payload: {
                      type: "UPLOAD_SUC",
                      payload: {
                        ...a,
                        cid: activeChat?.id,
                        ack: "sent",
                        resend: true,
                        sent_at: new Date().toISOString()
                      }
                    }
                  })
                  dispatch({
                    type: "RESEND_FAILED_MESSAGE",
                    payload: {
                      message_id: a?.id,
                      local_message_id: a?.mid,
                      chat_id: activeChat?.id
                    }
                  })
                } else {
                  submitNewMessage(a?.body)
                  dispatch({
                    type: "DELETE_ME_RESEND", payload: {
                      message_id: a?.id,
                      local_message_id: a?.mid,
                      chat_id: activeChat?.id
                    }
                  })
                }
              }}>
                <Resend />
              </div>}
              <div className="chat_message_time_footer">{getMessageTime()}</div>
              {((a?.fromMe === 1 || a?.fromMe === true) || a?.fromMe === 1) && (
                <>
                  {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read" || a?.ack === "fail") ? <Icon
                    id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : (a?.ack === "fail" ? "failed" : "")))}
                    aria-label={a?.ack}
                    className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                      }`}
                  /> :
                    <div className="chat__msg-status-icon"><Wai /></div>}
                </>
              )}
            </span>
          </div>
        </div>
      </>
      );
    }
    else if (a?.is_hash_message) {
      return (<div
        onMouseLeave={() => setOpen(false)}
        className={`message-hold ${opens && "ac"} message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
          }`}
      >
        <div
          id={"s" + ids}
          onClick={() => setOpen(true)}
          className={` ${((a?.fromMe === 0 || !a?.fromMe) || (a?.sender_employee_user_id)) &&
            "group_chat_msg"
            } chat__msg_hash chat__msg_hash--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
            }`}
        >
          {(openPreview || openPreview2) ? <TronScan opens={opens} ids={ids} hash_transaction_id={a?.ticket?.hash_transaction_id} setOpenPreview={setOpenPreview} setOpenPreview2={setOpenPreview2} getMessageTime={getMessageTime} /> : <></>}
          {a?.isForwarded === 1 &&
            <div className="forwarded-icon">
              <Rep />
              <span>Forwarded Message</span>
            </div>}
          {(a?.fromMe === 0 || !a?.fromMe) && (
            <>
              {a.received_from_contact_id && <div className="group_name">
                {contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                  ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                  : contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
              </div>}
              <img alt="" src={contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
            </>
          )}
          {!a.received_from_contact_id && a?.sender_employee_user_id &&
            <>
              {activeChat?.isGroup === 1 && <div className="group_name">
                {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
              </div>}
              <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
            </>
          }
          <span style={{ direction: getDir(getText(a?.body)) }}>
            {getText(a?.body)}
          </span>
          {a?.ticket?.hash_transaction_id ? <div style={{
            width: "100%",
            height: "25px",
            paddingTop: "10px"
            // position: "absolute",
            // bottom: "10%",
            // left: "10%",
          }} onClick={() => setOpenPreview(true)} onMouseEnter={() => setOpenPreview2(true)} onMouseLeave={() => setOpenPreview2(false)}>
            <Document />

          </div> : <></>}
          <span className="chat__msg-footer" style={{ color: "rgba(0, 0, 0, 0.45)" }}>
            {getMessageTime()}
            {((a?.fromMe === 1 || a?.fromMe === true) || a?.fromMe === 1) && (
              <>
                {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read" || a?.ack === "fail") ? <Icon
                  id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : (a?.ack === "fail" ? "failed" : "")))}
                  aria-label={a?.ack}
                  className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                    }`}
                /> :
                  <div className="chat__msg-status-icon"><Wai /></div>}
              </>
            )}
          </span>
        </div>
        {opens && <OptionsMenu deleted={a?.delete_status} delete={() => deleteMessage()} ack={a?.ack} copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus(); }} />
        }  </div>)
    }
    else if (a.type === "ticket" && a?.ticket) {
      return <div style={{ position: "relative" }} onClick={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        className={`message-hold ${opens && "ac"} message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
          }`}
      >
        <div
          id={"s" + ids}
          onClick={() => setOpen(true)}
          className={` ${((a?.fromMe === 0 || !a?.fromMe) || (a?.sender_employee_user_id)) &&
            "group_chat_msg"
            } chat__msg_hash chat__msg_hash--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
            }`}
          style={{ backgroundColor: "#E0F7CA" }}
        >
          <TicketMessage ticket={a.ticket} is_client_mobile_has_whatsapp={a?.ticket?.is_client_mobile_has_whatsapp} is_client_phone_has_whatsapp={a?.ticket?.is_client_phone_has_whatsapp} isMessage id={a?.ticket?.id} created_at={a?.ticket?.created_at} message={a?.ticket} />
          <span className="chat__msg-footer" style={{ color: "rgb(0 0 0 / 63%)" }}>
            {getMessageTime()}
            {((a?.fromMe === 1 || a?.fromMe === true)) && (
              <>
                {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read" || a?.ack === "fail") ? <Icon
                  id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : (a?.ack === "fail" ? "failed" : "")))}
                  aria-label={a?.ack}
                  className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                    }`}
                /> :
                  <div className="chat__msg-status-icon"><Wai /></div>}
              </>
            )}
          </span>
        </div>
        {opens && <OptionsMenu deleted={a?.delete_status} delete={() => deleteMessage()} ack={a?.ack} copy={() => copy(a?.ticket?.request_number)} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus(); }} />
        }
      </div>
    }
    else if (a.type === "ticket" && !a?.ticket) {
      return <div
        onMouseLeave={() => setOpen(false)}
        className={`message-hold ${opens && "ac"} message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
          }`}
      >
        <div
          id={"s" + ids}
          onClick={() => setOpen(true)}


          className={` ${((a?.fromMe === 0 || !a?.fromMe) || (a?.sender_employee_user_id)) &&
            "group_chat_msg"
            } chat__msg ${a?.delete_status ? "chat__msg--deleted" : ""} chat__msg--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
            }`}
        >
          {a?.isForwarded === 1 &&
            <div className="forwarded-icon">
              <Rep />
              <span>Forwarded Message</span>
            </div>}

          {(a?.fromMe === 0 || !a?.fromMe) && (
            <>
              {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                {contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                  ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                  : contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
              </div>}
              <img alt="" src={contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
            </>
          )}
          {!a.received_from_contact_id && a?.sender_employee_user_id &&
            <>
              {activeChat?.isGroup === 1 && <div className="group_name">
                {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
              </div>}
              <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
            </>
          }
          <span style={{ direction: getDir(getText(a?.body)) }}>


            {getText(a?.body)}</span>
          <span className="chat__msg-filler"> </span>

          <span className="chat__msg-footer">
            {getMessageTime()}
            {((a?.fromMe === 1 || a?.fromMe === true) || a?.fromMe === 1) && (
              <>
                {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read" || a?.ack === "fail") ? <Icon
                  id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : (a?.ack === "fail" ? "failed" : "")))}
                  aria-label={a?.ack}
                  className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                    }`}
                /> :
                  <div className="chat__msg-status-icon"><Wai /></div>}
              </>
            )}
          </span>
        </div>
        {opens && <OptionsMenu deleted={a?.delete_status} delete={() => deleteMessage()} ack={a?.ack} copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus(); }} />
        }  </div>
    }
    else if (a?.type === "ticket_hint") {

      return <div style={{ minWidth: "100%", maxWidth: "900px" }} onClick={() => setOpen(true)} id={"s" + ids}
        onMouseLeave={() => setOpen(false)}
        className={`message-hold ${opens && "ac"} message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
          }`}
      >
        <TicketMessage ticket={a.ticket} is_hint={true} messageTime={getMessageTime()} is_client_mobile_has_whatsapp={a.ticket?.is_client_mobile_has_whatsapp} is_client_phone_has_whatsapp={a.ticket?.is_client_phone_has_whatsapp} isMessage id={a?.ticket?.id} created_at={a?.ticket?.created_at} message={a?.ticket} />

      </div>
    }
    else if (a?.type === "document") {
      return (
        <>
          <div
            onMouseLeave={() => setOpen(false)}
            className={`message-hold ${opens && "ac"}  message-hold--${a?.fromMe === 0 ? "rxd" : "sent"
              }`}
          >

            <div
              id={"s" + ids}
              onClick={() => setOpen(true)}

              className={`
              ${(((a?.fromMe === 0 || !a?.fromMe)) || (a?.sender_employee_user_id)) &&
                "group_chat_msg"
                } chat__file  chat__msg ${a?.delete_status ? "chat__msg--deleted" : ""} chat__msg--${a?.fromMe === 0 ? "rxd" : "sent"
                }`}
            >
              {a?.isForwarded === 1 &&
                <div className="forwarded-icon">
                  <Rep />
                  <span>Forwarded Message</span>
                </div>}
              {(a?.fromMe === 0 || !a?.fromMe) && (
                <>
                  {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                    {contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                      ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                      : contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                    {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                  </div>}
                  <img alt="" src={contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
                </>
              )}
              {!a.received_from_contact_id && a?.sender_employee_user_id &&
                <>
                  {activeChat?.isGroup === 1 && <div className="group_name">
                    {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                    {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                  </div>}
                  <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
                </>
              }
              {a?.quotedMsg?.id && (
                <div
                  className="replay-msg"
                  onClick={() => GetMessage(a?.id, a?.quotedMsg?.id)}
                >
                  <div className="bar-c"></div>
                  <div className="reply-cont">
                    <div className="reply-name group_name">
                      {a?.quotedMsg?.sender_name ? a?.quotedMsg?.sender_name : (a?.quotedMsg?.fromMe === 1
                        ? ("You")
                        : activeChat?.isGroup === 1 ? (
                          (activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.pushname)
                            ? (activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.pushname)
                            : (activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.number ?
                              "+" + activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.number : "Rammaz System")
                        )
                          :
                          ((activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                            ? (activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                            : (activeChat?.whatsapp_contact?.number ? "+" + activeChat?.whatsapp_contact?.number : "Rammaz System")))}
                    </div>
                    <span className="reply-desc">
                      {(a?.quotedMsg?.type === "image" || a?.quotedMsg?.type === "sticker") && <><Cam /> Image</>}
                      {a?.quotedMsg?.type === "video" && <> <Vide /> Video</>}
                      {(a?.quotedMsg?.type === "ptt" || a?.quotedMsg?.type === "audio") && <><Voc /> Audio</>}
                      {a?.quotedMsg?.type === "document" && <><img alt="" width="12" height="15" src={fil} /> File</>}
                      {a?.quotedMsg?.type === "vcard" && <>  <Cons /> Contact</>}
                      {a?.quotedMsg?.type === "ticket" && <>  Ticket Message {a.quotedMsg?.ticket?.request_number} </>}
                      {" "}
                      {a?.quotedMsg?.body && a?.quotedMsg?.type !== "ticket" && a.quotedMsg?.type !== "vcard" && ((a?.quotedMsg?.type !== "video") || (a.quotedMsg?.type !== "vcard")) && a?.quotedMsg?.body}
                    </span>
                  </div>
                  {a?.quotedMsg && a?.quotedMsg?.media && (
                    <img alt=""
                      className="quotedImg"
                      src={a?.quotedMsg?.media}
                    />
                  )}
                </div>
              )}
              {a?.media && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={a?.media}
                  download
                  className="replay-msg file-msg"
                >
                  <img alt="" src={fil} style={{ width: "26px" }} />
                  <div className="file-desc">
                    <div className="file-name">
                      {a?.body}
                    </div>
                    <div className="file-type">
                      {a?.file_ext && a?.file_ext.length < 4
                        ? a?.file_ext
                        : a?.body && a?.body?.split(".")[1]}
                    </div>
                  </div>
                  <Down />
                </a>
              )}

              <span className="chat__msg-filler"> </span>

              <span className="chat__msg-footer">
                {getMessageTime()}
                {((a?.fromMe === 1 || a?.fromMe === true)) && (
                  <>
                    {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read" || a?.ack === "fail") ? <Icon
                      id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : (a?.ack === "fail" ? "failed" : "")))}
                      aria-label={a?.ack}
                      className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                        }`}
                    /> :
                      <div className="chat__msg-status-icon"><Wai /></div>}
                  </>
                )}
              </span>
            </div>
            {opens && <OptionsMenu deleted={a?.delete_status} delete={() => deleteMessage()} ack={a?.ack} copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus(); }} />
            }  </div>
        </>
      );
    } else if (a?.quotedMsg && a?.quotedMsg?.id) {
      return (
        <div
          onMouseLeave={() => setOpen(false)}
          className={`message-hold ${opens && "ac"}  message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
            }`}
        >
          <div
            id={"s" + ids}
            onClick={() => setOpen(true)}


            className={` ${((a?.fromMe === 0 || !a?.fromMe) || (a?.sender_employee_user_id)) &&
              "group_chat_msg"

              } ${(a?.type === "ptt" || a.type === "audio") && "aud_msg au-he"} chat__msg ${a?.delete_status ? "chat__msg--deleted" : ""} chat__msg--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
              }`}
          >
            {a?.isForwarded === 1 &&
              <div className="forwarded-icon">
                <Rep />
                <span>Forwarded Message</span>
              </div>}
            {(a?.fromMe === 0 || !a?.fromMe) && (
              <>
                {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                  {contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    : contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                  {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                </div>}
                <img alt="" src={contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
              </>
            )}
            {!a.received_from_contact_id && a?.sender_employee_user_id &&
              <>
                {activeChat?.isGroup === 1 && <div className="group_name">
                  {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                  {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                </div>}
                <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
              </>
            }
            <div
              className="replay-msg"
              onClick={() => GetMessage(a?.id, a?.quotedMsg?.id)}
            >
              <div className="bar-c"></div>
              <div className="reply-cont">
                <div className="reply-name group_name">
                  {a?.quotedMsg?.sender_name ? a?.quotedMsg?.sender_name : (a?.quotedMsg?.fromMe === 1
                    ? "You"
                    : activeChat?.isGroup === 1 ? (
                      (activeChat?.whatsapp_contact?.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.pushname)
                        ? (activeChat?.whatsapp_contact?.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.pushname)
                        : (activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.number ?
                          "+" + activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.number : "Rammaz System")
                    )
                      :
                      ((activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                        ? (activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                        : (activeChat?.whatsapp_contact?.number ? "+" + activeChat?.whatsapp_contact?.number : "Rammaz System")))}
                </div>
                <span className="reply-desc">
                  {(a?.quotedMsg?.type === "image" || a?.quotedMsg?.type === "sticker") && <><Cam /> Image</>}
                  {a?.quotedMsg?.type === "video" && <> <Vide /> Video</>}
                  {(a?.quotedMsg?.type === "ptt" || a?.quotedMsg?.type === "audio") && <><Voc /> Audio</>}
                  {a?.quotedMsg?.type === "document" && <><img alt="" width="12" height="15" src={fil} /> File</>}
                  {a?.quotedMsg?.type === "vcard" && <>  <Cons /> Contact</>}
                  {a?.quotedMsg?.type === "ticket" && <>  Ticket Message {a.quotedMsg?.ticket?.request_number} </>}
                  {" "}
                  {a?.quotedMsg?.body && a?.quotedMsg?.type !== "ticket" && a.quotedMsg?.type !== "vcard" && ((a?.quotedMsg?.type !== "video") || (a.quotedMsg?.type !== "vcard")) && a?.quotedMsg?.body} </span>
              </div>
              {a?.quotedMsg && a?.quotedMsg?.media && a?.quotedMsg?.type === "image" && (
                <img alt=""
                  className="quotedImg"
                  src={a?.quotedMsg?.media}
                />
              )}
            </div>
            {a?.media && (a?.type === "image" || a?.type === "sticker") && (
              <img alt=""
                onClick={() => setImg(a?.media)}
                className="img-mes"
                src={a?.media}
              />
            )}
            {(a?.type === "ptt" || a.type === "audio") && <div className="row-aud">
              {((a?.fromMe === 1 || a?.fromMe === true) || a?.fromMe === 1) && (
                <div className="aud_ava_cont ml-0">
                  <img alt=""
                    src={
                      active?.profile_photo ? active?.profile_photo : ProfileNo
                    }
                  />
                  <svg
                    viewBox="0 0 19 26"
                    height="26"
                    width="19"
                    preserveAspectRatio="xMidYMid meet"
                    className=""
                    version="1.1"
                    x="0px"
                    y="0px"
                    enableBackground="new 0 0 19 26"
                    xmlSpace="preserve"
                  >
                    <path
                      fill="#FFFFFF"
                      className="ptt-status-background"
                      d="M9.217,24.401c-1.158,0-2.1-0.941-2.1-2.1v-2.366c-2.646-0.848-4.652-3.146-5.061-5.958L2.004,13.62 l-0.003-0.081c-0.021-0.559,0.182-1.088,0.571-1.492c0.39-0.404,0.939-0.637,1.507-0.637h0.3c0.254,0,0.498,0.044,0.724,0.125v-6.27 C5.103,2.913,7.016,1,9.367,1c2.352,0,4.265,1.913,4.265,4.265v6.271c0.226-0.081,0.469-0.125,0.723-0.125h0.3 c0.564,0,1.112,0.233,1.501,0.64s0.597,0.963,0.571,1.526c0,0.005,0.001,0.124-0.08,0.6c-0.47,2.703-2.459,4.917-5.029,5.748v2.378 c0,1.158-0.942,2.1-2.1,2.1H9.217V24.401z"
                    ></path>
                    <path
                      fill="#0DA9E5"
                      className="ptt-status-icon"
                      d="M9.367,15.668c1.527,0,2.765-1.238,2.765-2.765V5.265c0-1.527-1.238-2.765-2.765-2.765 S6.603,3.738,6.603,5.265v7.638C6.603,14.43,7.84,15.668,9.367,15.668z M14.655,12.91h-0.3c-0.33,0-0.614,0.269-0.631,0.598 c0,0,0,0-0.059,0.285c-0.41,1.997-2.182,3.505-4.298,3.505c-2.126,0-3.904-1.521-4.304-3.531C5.008,13.49,5.008,13.49,5.008,13.49 c-0.016-0.319-0.299-0.579-0.629-0.579h-0.3c-0.33,0-0.591,0.258-0.579,0.573c0,0,0,0,0.04,0.278 c0.378,2.599,2.464,4.643,5.076,4.978v3.562c0,0.33,0.27,0.6,0.6,0.6h0.3c0.33,0,0.6-0.27,0.6-0.6V18.73 c2.557-0.33,4.613-2.286,5.051-4.809c0.057-0.328,0.061-0.411,0.061-0.411C15.243,13.18,14.985,12.91,14.655,12.91z"
                    ></path>
                  </svg>
                </div>
              )}
              <div
                className={`aud-cont ${((a?.fromMe === 1 || a?.fromMe === true) || a?.fromMe === 1) && "ml-0"}`}
              >
                {(play || (!audioRef.current?.paused)) ? (<div onClick={() => {
                  audioRef?.current?.pause();
                  setPlay(false);
                }}>
                  <Paus />
                </div>) : (<div onClick={() => {
                  handleEnd()
                  audioRef?.current?.play();
                  setPlay(true);
                  handlePlay()
                }}>
                  <Ply />
                </div>)}
              </div>
              <div className="aud">
                <audio
                  onPause={() => setPlay(false)}
                  onTimeUpdate={(e) => setWidth(!width)}
                  aria-details={a?.id}
                  onEnded={() => {
                    setWidth(!width)
                    setPlay(false);
                    audioRef.current.currentTime = 0;
                  }}
                  src={a?.media}
                  ref={audioRef}
                >
                  <source src={a?.media} />
                </audio>
                <div className="tra"></div>
                <div
                  className="sv"
                  style={{
                    marginLeft: `${audioRef?.current &&
                      ((parseFloat(audioRef.current.currentTime) * 100) /
                        parseFloat(audioRef.current.duration)) *
                      1.8
                      }px`,
                  }}
                ></div>
              </div>
              {(a?.fromMe === 0 || !a?.fromMe) && (
                <div className="aud_ava_cont">
                  <img alt=""
                    src={
                      active?.profile_photo ? active?.profile_photo : ProfileNo
                    }
                  />
                  <svg
                    viewBox="0 0 19 26"
                    height="26"
                    width="19"
                    preserveAspectRatio="xMidYMid meet"
                    className=""
                    version="1.1"
                    x="0px"
                    y="0px"
                    enableBackground="new 0 0 19 26"
                    xmlSpace="preserve"
                  >
                    <path
                      fill="#FFFFFF"
                      className="ptt-status-background"
                      d="M9.217,24.401c-1.158,0-2.1-0.941-2.1-2.1v-2.366c-2.646-0.848-4.652-3.146-5.061-5.958L2.004,13.62 l-0.003-0.081c-0.021-0.559,0.182-1.088,0.571-1.492c0.39-0.404,0.939-0.637,1.507-0.637h0.3c0.254,0,0.498,0.044,0.724,0.125v-6.27 C5.103,2.913,7.016,1,9.367,1c2.352,0,4.265,1.913,4.265,4.265v6.271c0.226-0.081,0.469-0.125,0.723-0.125h0.3 c0.564,0,1.112,0.233,1.501,0.64s0.597,0.963,0.571,1.526c0,0.005,0.001,0.124-0.08,0.6c-0.47,2.703-2.459,4.917-5.029,5.748v2.378 c0,1.158-0.942,2.1-2.1,2.1H9.217V24.401z"
                    ></path>
                    <path
                      fill="#0DA9E5"
                      className="ptt-status-icon"
                      d="M9.367,15.668c1.527,0,2.765-1.238,2.765-2.765V5.265c0-1.527-1.238-2.765-2.765-2.765 S6.603,3.738,6.603,5.265v7.638C6.603,14.43,7.84,15.668,9.367,15.668z M14.655,12.91h-0.3c-0.33,0-0.614,0.269-0.631,0.598 c0,0,0,0-0.059,0.285c-0.41,1.997-2.182,3.505-4.298,3.505c-2.126,0-3.904-1.521-4.304-3.531C5.008,13.49,5.008,13.49,5.008,13.49 c-0.016-0.319-0.299-0.579-0.629-0.579h-0.3c-0.33,0-0.591,0.258-0.579,0.573c0,0,0,0,0.04,0.278 c0.378,2.599,2.464,4.643,5.076,4.978v3.562c0,0.33,0.27,0.6,0.6,0.6h0.3c0.33,0,0.6-0.27,0.6-0.6V18.73 c2.557-0.33,4.613-2.286,5.051-4.809c0.057-0.328,0.061-0.411,0.061-0.411C15.243,13.18,14.985,12.91,14.655,12.91z"
                    ></path>
                  </svg>
                </div>
              )}
            </div>}
            {(getText(a?.body)) && (
              <span style={{ marginTop: "10px", width: "100%", direction: getDir(getText(a?.body)) }}>

                {" "}
                {getText(a?.body)}
              </span>
            )}
            <span className="chat__msg-filler"> </span>

            <span className="chat__msg-footer">
              {getMessageTime()}
              {((a?.fromMe === 1 || a?.fromMe === true) || a?.fromMe === 1) && (
                <>
                  {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read" || a?.ack === "fail") ? <Icon
                    id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : (a?.ack === "fail" ? "failed" : "")))}
                    aria-label={a?.ack}
                    className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                      }`}
                  /> :
                    <div className="chat__msg-status-icon"><Wai /></div>}
                </>
              )}
            </span>
          </div>
          {opens && <OptionsMenu deleted={a?.delete_status} delete={() => deleteMessage()} ack={a?.ack} copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus(); }} />
          }  </div>
      );
    }
    else if (a?.type === "chat") {
      if (a?.is_forwarded_hash_message && activeChat?.is_hash_automation_system_enabled && !activeChat?.is_hash_ticketing_system_enabled) {
        return (<div onMouseLeave={() => setOpen(false)}
          className={`message-hold ${a?.original_hash_message?.ticket?.ticket_status_id === 3 ? "--forwarded-hash" : "--forwarded-hash-unapproved"} ${opens && "ac"} message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
            }`}>
          <div
            id={"s" + ids}
            className={` ${((a?.fromMe === 0 || !a?.fromMe) || (a?.sender_employee_user_id)) &&
              "group_chat_msg"
              } chat__msg ${a?.delete_status ? "chat__msg--deleted" : ""} chat__msg--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
              }`}
          >
            {a?.isForwarded === 1 &&
              <div className="forwarded-icon">
                <Rep />
                <span>Forwarded Message</span>
              </div>}

            {(a?.fromMe === 0 || !a?.fromMe) && (
              <>
                {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                  {contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    : contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                  {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                </div>}
                <img alt="" src={contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
              </>
            )}
            {!a.received_from_contact_id && a?.sender_employee_user_id &&
              <>
                {activeChat?.isGroup === 1 && <div className="group_name">
                  {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                  {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                </div>}
                <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
              </>
            }
            {a?.original_hash_message ? <><span
              onClick={() => setOpen(true)}>
              <div style={{ fontWeight: "bold" }}>{a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name} </div> <br /><br />
              <div>
                Name: {a?.original_hash_message?.ticket?.name}
                <br />
                Account Number: {a?.original_hash_message?.ticket?.account_number}
                <br />
                Amount: {a?.original_hash_message?.ticket?.amount_to_display}
                <br /><br />
              </div>
              <div>
                {a?.original_hash_message?.ticket?.request_number}
                <br />
                🔴🔴🔴
                <br />
                {a?.original_hash_message?.ticket?.company}
                <br />
                USDT
                <br />
                {a?.original_hash_message?.ticket?.amount_to_display}
                <br />
              </div>
            </span>
              <span className="chat__msg-filler"> </span>
              <div className='rs-icon-message'
                onClick={() => setOpen(true)}>
                <RS />
              </div>
              {a?.original_hash_message?.ticket?.ticket_status_id === 2 ? <span className="approve_and_send_area">
                <div className="approve_and_send_area_button" onClick={() => {
                  applySend(a?.original_hash_message?.ticket?.id)
                }}>
                  Approve & Send
                  {waitApply && <div id="startup2" style={{ marginLeft: "10px" }}>
                    <svg className="spinner-container" width="65px" height="65px" viewBox="0 0 52 52">
                      <circle className="path" cx="26px" cy="26px" r="20px" fill="none" strokeWidth="4px" />
                    </svg>
                  </div>}
                </div>
                <div className="approve_and_send_area_time"
                  onClick={() => setOpen(true)}>{getMessageTime()}</div>
              </span> : <span className="approve_and_send_area_approved"
                onClick={() => setOpen(true)}>
                <div className="approve_and_send_area_button_approved" > Approved & Sent</div>
                <div className={`approve_and_send_area_time_approved`}>
                  {a?.original_hash_message?.ticket?.approved_by_user_id ? <div
                    onMouseEnter={() => setShowEmpTip(true)}
                    onMouseLeave={() => setShowEmpTip(false)}
                    style={{
                      width: "15px", height: "15px", borderRadius: "20px", marginRight: "5px", position: "relative"
                    }}>
                    {showEmpTip === true && <div className="popup_emp_name">{emps.filter(one => one.id === a?.original_hash_message?.ticket?.approved_by_user_id)[0]?.full_name}</div>}
                    <img
                      style={{
                        width: "15px", height: "15px", borderRadius: "20px"
                      }}
                      alt=""
                      src={REACT_APP_BASE_FILE_URL + emps.filter(one => one.id === a?.original_hash_message?.ticket?.approved_by_user_id)[0]?.avatar} />
                  </div> : (a?.original_hash_message?.ticket?.approved_by_contact_id ? <div
                    onMouseEnter={() => setShowEmpTip(true)}
                    onMouseLeave={() => setShowEmpTip(false)}
                    style={{
                      width: "15px", height: "15px", borderRadius: "20px", marginRight: "5px", position: "relative"
                    }}>
                    {showEmpTip === true && <div className="popup_emp_name">{
                      contacts.filter(one => one.id === a?.original_hash_message?.ticket?.approved_by_contact_id)[0]?.name ||
                      contacts.filter(one => one.id === a?.original_hash_message?.ticket?.approved_by_contact_id)[0]?.pushname ||
                      contacts.filter(one => one.id === a?.original_hash_message?.ticket?.approved_by_contact_id)[0]?.number
                    }</div>}
                    <img
                      style={{
                        width: "15px", height: "15px", borderRadius: "20px"
                      }}
                      alt=""
                      src={contacts.filter(one => one.id === a?.original_hash_message?.ticket?.approved_by_contact_id)[0]?.profile_photo || ProfileNo} />
                  </div> : <></>)
                  }
                  {getMessageTime()} / {getDiffBetween({
                    date1: a?.original_hash_message?.ticket?.created_at,
                    date2: a?.original_hash_message?.ticket?.approved_at
                  })}</div>
              </span>}
            </> : <span style={{ direction: getDir(getText(a?.body)) }}>
              {getText(a?.body)}</span>}
          </div>
          {opens && <OptionsMenu deleted={a?.delete_status} delete={() => deleteMessage()} ack={a?.ack} copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus(); }} />
          }
        </div>)
      } else {
        return (<>
          <div
            onMouseLeave={() => setOpen(false)}
            className={`message-hold ${opens && "ac"} message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
              }`}
          >
            <div
              id={"s" + ids}
              onClick={() => setOpen(true)}


              className={` ${((a?.fromMe === 0 || !a?.fromMe) || (a?.sender_employee_user_id)) &&
                "group_chat_msg"
                } chat__msg ${a?.delete_status ? "chat__msg--deleted" : ""} chat__msg--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
                }`}
            >
              {a?.isForwarded === 1 &&
                <div className="forwarded-icon">
                  <Rep />
                  <span>Forwarded Message</span>
                </div>}

              {(a?.fromMe === 0 || !a?.fromMe) && (
                <>
                  {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                    {contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                      ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                      : contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                    {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                  </div>}
                  <img alt="" src={contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
                </>
              )}
              {!a.received_from_contact_id && a?.sender_employee_user_id &&
                <>
                  {activeChat?.isGroup === 1 && <div className="group_name">
                    {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                    {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                  </div>}
                  <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
                </>
              }
              <span style={{ direction: getDir(getText(a?.body)) }}>
                {getText(a?.body)}</span>
              <span className="chat__msg-filler"> </span>

              <span className="chat__msg-footer">
                {(a?.ack === "fail" && a?.sender_employee_user_id === user.id) && <div className="resend_message_icon" onClick={() => {
                  if (a?.id) {
                    dispatch({
                      type: "CHANGE_IN_MESSAGES",
                      payload: {
                        type: "UPLOAD_SUC",
                        payload: {
                          ...a,
                          cid: activeChat?.id,
                          ack: "sent",
                          resend: true,
                          sent_at: new Date().toISOString()
                        }
                      }
                    })
                    dispatch({
                      type: "RESEND_FAILED_MESSAGE",
                      payload: {
                        message_id: a?.id,
                        local_message_id: a?.mid,
                        chat_id: activeChat?.id
                      }
                    })
                  } else {
                    submitNewMessage(a?.body)
                    dispatch({
                      type: "DELETE_ME_RESEND", payload: {
                        message_id: a?.id,
                        local_message_id: a?.mid,
                        chat_id: activeChat?.id
                      }
                    })
                  }
                }}>
                  <Resend />
                </div>}
                <div className="chat_message_time_footer">{getMessageTime()}</div>
                {((a?.fromMe === 1 || a?.fromMe === true) || a?.fromMe === 1) && (
                  <>
                    {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read" || a?.ack === "fail") ? <Icon
                      id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : (a?.ack === "fail" ? "failed" : "")))}
                      aria-label={a?.ack}
                      className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                        }`}
                    /> :
                      <div className="chat__msg-status-icon"><Wai /></div>}
                  </>
                )}
              </span>
            </div>
            {opens && <OptionsMenu deleted={a?.delete_status} delete={() => deleteMessage()} ack={a?.ack} copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus(); }} />
            }
          </div>
        </>
        );
      }
    } else if (a?.type === "image" || a?.type === "sticker") {
      return (
        <div
          onMouseLeave={() => setOpen(false)}
          className={`message-hold ${opens && "ac"}  message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
            }`}
        >

          <div
            id={"s" + ids}
            onClick={() => setOpen(true)}
            className={`
            ${((a?.fromMe === 0 || !a?.fromMe) || (a?.sender_employee_user_id)) &&
              "group_chat_msg"} med-msg
            ${a?.type === "sticker" && "sticker_msg"}
             chat_img chat__msg ${a?.delete_status ? "chat__msg--deleted" : ""} chat__msg--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
              }`}
          >
            {a?.sender_employee_user_id && <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />}

            {a?.isForwarded === 1 &&
              <div className="forwarded-icon">
                <Rep />
                <span>Forwarded Message</span>
              </div>}
            {(a?.fromMe === 0 || !a?.fromMe) && (
              <>
                {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                  {contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    : contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                  {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                </div>}
                <img alt="" src={contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
              </>
            )}
            {!a.received_from_contact_id && a?.sender_employee_user_id &&
              <>
                {activeChat?.isGroup === 1 && <div className="group_name">
                  {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                  {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                </div>}
                <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
              </>
            }

            <div onClick={() => setImg(a?.media)}
              className="image_show"
              style={{ backgroundImage: `url(${a?.media})` }}
            >
              <img
                alt=""
                className="img-mes"
                onClick={() => setImg(a?.media)}
                src={a?.media}
                style={{ opacity: 0 }}
              />

            </div>
            {(!a?.ack) && a.fromMe ?
              <div className="sppin">
                <Spinner no={true} />
              </div> : <></>
            }
            {a?.body ?
              <span style={{ marginTop: "10px", marginBottom: "10px", width: "100%", direction: getDir(getText(a?.body)) }}>
                {a?.body ? getText(a?.body) : ""}
              </span> : <></>
            }
            <span className="chat__msg-footer">
              {getMessageTime()}
              {((a?.fromMe === 1 || a?.fromMe === true) || a?.fromMe === 1) && (
                <>
                  {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read" || a?.ack === "fail") ? <Icon
                    id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : (a?.ack === "fail" ? "failed" : "")))}
                    aria-label={a?.ack}
                    className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                      }`}
                  /> :
                    <div className="chat__msg-status-icon"><Wai /></div>}
                </>
              )}
            </span>
          </div>
          {opens && <OptionsMenu deleted={a?.delete_status} delete={() => deleteMessage()} ack={a?.ack} copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus(); }} />
          } </div>
      );
    } else if (a?.type === "ptt" || a?.type === "audio") {
      return (
        <div
          onMouseLeave={() => setOpen(false)}
          className={`message-hold ${opens && "ac"} message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
            }`}
        >

          <div
            id={"s" + ids}
            onClick={() => setOpen(true)}
            style={{ flexWrap: "nowrap" }}


            className={` ml-0 chat__msg aud_msg chat__msg--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
              }`}
          >
            {a?.isForwarded === 1 &&
              <div className="forwarded-icon">
                <Rep />
                <span>Forwarded Message</span>
              </div>}
            {(a?.fromMe === 0 || !a?.fromMe) && (
              <>
                {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                  {contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    : contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                  {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                </div>}
                <img alt="" src={contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
              </>
            )}
            {!a.received_from_contact_id && a?.sender_employee_user_id &&
              <>
                {activeChat?.isGroup === 1 && <div className="group_name">
                  {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                  {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                </div>}
                <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
              </>
            }
            <div className="audio_message_enhanced">
              {((a?.fromMe === 1 || a?.fromMe === true) || a?.fromMe === 1) && (
                <div className="aud_ava_cont ml-0">
                  <img alt=""
                    src={
                      emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo
                    }
                  />
                  <svg
                    viewBox="0 0 19 26"
                    height="26"
                    width="19"
                    preserveAspectRatio="xMidYMid meet"
                    className=""
                    version="1.1"
                    x="0px"
                    y="0px"
                    enableBackground="new 0 0 19 26"
                    xmlSpace="preserve"
                  >
                    <path
                      fill="#FFFFFF"
                      className="ptt-status-background"
                      d="M9.217,24.401c-1.158,0-2.1-0.941-2.1-2.1v-2.366c-2.646-0.848-4.652-3.146-5.061-5.958L2.004,13.62 l-0.003-0.081c-0.021-0.559,0.182-1.088,0.571-1.492c0.39-0.404,0.939-0.637,1.507-0.637h0.3c0.254,0,0.498,0.044,0.724,0.125v-6.27 C5.103,2.913,7.016,1,9.367,1c2.352,0,4.265,1.913,4.265,4.265v6.271c0.226-0.081,0.469-0.125,0.723-0.125h0.3 c0.564,0,1.112,0.233,1.501,0.64s0.597,0.963,0.571,1.526c0,0.005,0.001,0.124-0.08,0.6c-0.47,2.703-2.459,4.917-5.029,5.748v2.378 c0,1.158-0.942,2.1-2.1,2.1H9.217V24.401z"
                    ></path>
                    <path
                      fill="#0DA9E5"
                      className="ptt-status-icon"
                      d="M9.367,15.668c1.527,0,2.765-1.238,2.765-2.765V5.265c0-1.527-1.238-2.765-2.765-2.765 S6.603,3.738,6.603,5.265v7.638C6.603,14.43,7.84,15.668,9.367,15.668z M14.655,12.91h-0.3c-0.33,0-0.614,0.269-0.631,0.598 c0,0,0,0-0.059,0.285c-0.41,1.997-2.182,3.505-4.298,3.505c-2.126,0-3.904-1.521-4.304-3.531C5.008,13.49,5.008,13.49,5.008,13.49 c-0.016-0.319-0.299-0.579-0.629-0.579h-0.3c-0.33,0-0.591,0.258-0.579,0.573c0,0,0,0,0.04,0.278 c0.378,2.599,2.464,4.643,5.076,4.978v3.562c0,0.33,0.27,0.6,0.6,0.6h0.3c0.33,0,0.6-0.27,0.6-0.6V18.73 c2.557-0.33,4.613-2.286,5.051-4.809c0.057-0.328,0.061-0.411,0.061-0.411C15.243,13.18,14.985,12.91,14.655,12.91z"
                    ></path>
                  </svg>
                </div>
              )}
              <div
                className={`aud-cont ${((a?.fromMe === 1 || a?.fromMe === true) || a?.fromMe === 1) && "ml-0"}`}
              >
                {(play || (!audioRef.current?.paused)) ? (<div onClick={() => {
                  audioRef?.current?.pause();
                  setPlay(false);
                }} style={{ width: "34px", height: "34px" }}>
                  <Paus />
                </div>) : (<div onClick={() => {
                  handleEnd()
                  audioRef?.current?.play();
                  setPlay(true);
                  handlePlay()
                }} style={{ width: "34px", height: "34px" }}>
                  <Ply />
                </div>)}
              </div>
              <div className="aud">
                <audio
                  aria-details={a?.id}
                  onPause={() => setPlay(false)}
                  onTimeUpdate={(e) => setWidth(!width)}
                  onEnded={() => {
                    setPlay(false);
                    setWidth(!width)
                    audioRef.current.currentTime = 0;
                  }}
                  src={a?.media}
                  ref={audioRef}
                >
                  <source src={a?.media} />
                </audio>
                <div className="tra"></div>
                <div
                  className="sv"
                  style={{
                    marginLeft: `${audioRef?.current &&
                      ((parseFloat(audioRef.current.currentTime) * 100) /
                        parseFloat(audioRef.current.duration)) *
                      1.8
                      }px`,
                  }}
                ></div>
              </div>
              {(a?.fromMe === 0 || !a?.fromMe) && (
                <div className="aud_ava_cont">
                  <img alt=""
                    src={
                      contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo
                    }
                  />
                  <svg
                    viewBox="0 0 19 26"
                    height="26"
                    width="19"
                    preserveAspectRatio="xMidYMid meet"
                    className=""
                    version="1.1"
                    x="0px"
                    y="0px"
                    enableBackground="new 0 0 19 26"
                    xmlSpace="preserve"
                  >
                    <path
                      fill="#FFFFFF"
                      className="ptt-status-background"
                      d="M9.217,24.401c-1.158,0-2.1-0.941-2.1-2.1v-2.366c-2.646-0.848-4.652-3.146-5.061-5.958L2.004,13.62 l-0.003-0.081c-0.021-0.559,0.182-1.088,0.571-1.492c0.39-0.404,0.939-0.637,1.507-0.637h0.3c0.254,0,0.498,0.044,0.724,0.125v-6.27 C5.103,2.913,7.016,1,9.367,1c2.352,0,4.265,1.913,4.265,4.265v6.271c0.226-0.081,0.469-0.125,0.723-0.125h0.3 c0.564,0,1.112,0.233,1.501,0.64s0.597,0.963,0.571,1.526c0,0.005,0.001,0.124-0.08,0.6c-0.47,2.703-2.459,4.917-5.029,5.748v2.378 c0,1.158-0.942,2.1-2.1,2.1H9.217V24.401z"
                    ></path>
                    <path
                      fill="#0DA9E5"
                      className="ptt-status-icon"
                      d="M9.367,15.668c1.527,0,2.765-1.238,2.765-2.765V5.265c0-1.527-1.238-2.765-2.765-2.765 S6.603,3.738,6.603,5.265v7.638C6.603,14.43,7.84,15.668,9.367,15.668z M14.655,12.91h-0.3c-0.33,0-0.614,0.269-0.631,0.598 c0,0,0,0-0.059,0.285c-0.41,1.997-2.182,3.505-4.298,3.505c-2.126,0-3.904-1.521-4.304-3.531C5.008,13.49,5.008,13.49,5.008,13.49 c-0.016-0.319-0.299-0.579-0.629-0.579h-0.3c-0.33,0-0.591,0.258-0.579,0.573c0,0,0,0,0.04,0.278 c0.378,2.599,2.464,4.643,5.076,4.978v3.562c0,0.33,0.27,0.6,0.6,0.6h0.3c0.33,0,0.6-0.27,0.6-0.6V18.73 c2.557-0.33,4.613-2.286,5.051-4.809c0.057-0.328,0.061-0.411,0.061-0.411C15.243,13.18,14.985,12.91,14.655,12.91z"
                    ></path>
                  </svg>
                </div>
              )}</div>
            <div className="special_footer_audio">
              <span className="audio_message_duration">
                {play || audioRef.current?.paused ? <>
                  {audioRef?.current &&
                    !isNaN(audioRef.current.currentTime) &&
                    showTime(audioRef.current.currentTime).toString()}
                </> : <>{audioRef?.current &&
                  !isNaN(audioRef.current.duration) &&
                  showTime(audioRef.current.duration).toString()}</>}
              </span>
              <span
                className={`chat__msg-footer ${(a?.fromMe === 1 || a?.fromMe === true) && "me-voc"
                  }`}
              >
                {getMessageTime()}
                {((a?.fromMe === 1 || a?.fromMe === true) || a?.fromMe === 1) && (
                  <>
                    {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read" || a?.ack === "fail") ? <Icon
                      id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : (a?.ack === "fail" ? "failed" : "")))}
                      aria-label={a?.ack}
                      className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                        }`}
                    /> :
                      <div className="chat__msg-status-icon"><Wai /></div>}
                  </>
                )}
              </span>
            </div>
          </div>
          {opens && <OptionsMenu deleted={a?.delete_status} delete={() => deleteMessage()} ack={a?.ack} copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus(); }} />
          }  </div>
      );
    }
    else if (a?.type === "video") {
      return (
        <div
          onMouseLeave={() => setOpen(false)}
          className={`message-hold ${opens && "ac"} message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
            }`}
        >

          <div
            id={"s" + ids}
            onClick={() => setOpen(true)}
            style={{ flexWrap: "nowrap" }}


            className={` aud_msg ml-0 med-msg chat__msg ${a?.delete_status ? "chat__msg--deleted" : ""} chat__img chat__msg--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
              }`}
          >

            {a?.isForwarded === 1 &&
              <div className="forwarded-icon">
                <Rep />
                <span>Forwarded Message</span>
              </div>}
            {(a?.fromMe === 0 || !a?.fromMe) && (
              <>
                {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                  {contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                    : contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                  {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                </div>}
                <img alt="" src={contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
              </>
            )}
            {!a.received_from_contact_id && a?.sender_employee_user_id &&
              <>
                {activeChat?.isGroup === 1 && <div className="group_name">
                  {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                  {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                </div>}
                <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
              </>
            }
            <div className={`vid-cont ${audioRef?.current?.currentTime === 0 && "vixs"}`}>
              <div onClick={() => {
                setVid(a.media)
              }}>
                <Ply />
              </div>
            </div>
            <video onClick={() => {
              handleEnd();
              setVid(a?.media)
            }} onEnded={() => {

              setPlay(false);
              audioRef.current.currentTime = 0;
            }} ref={audioRef} className="img-mes" src={a?.media} controls={false}>
              <source src={a?.media}></source>
            </video>
            <span
              className={`chat__msg-footer ${((a?.fromMe === 1 || a?.fromMe === true) || a?.fromMe === 1) && "me-voc"
                }`}
            >
              {getMessageTime()}
              {((a?.fromMe === 1 || a?.fromMe === true) || a?.fromMe === 1) && (
                <>
                  {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read" || a?.ack === "fail") ? <Icon
                    id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : (a?.ack === "fail" ? "failed" : "")))}
                    aria-label={a?.ack}
                    className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                      }`}
                  /> :
                    <div className="chat__msg-status-icon"><Wai /></div>}
                </>
              )}
            </span>
          </div>
          {opens && <OptionsMenu deleted={a?.delete_status} delete={() => deleteMessage()} ack={a?.ack} copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus(); }} />
          }   </div>
      );
    }
    else if (a.type === "vcard") {
      return (
        <>
          <div
            onMouseLeave={() => setOpen(false)}
            className={`message-hold ${opens && "ac"} vcard-message  message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
              }`}
          >

            <div
              id={"s" + ids}
              onClick={() => setOpen(true)}

              className={`
            ${((a?.fromMe === 0) || (a?.sender_employee_user_id)) &&
                "group_chat_msg"
                }   chat__msg ${a?.delete_status ? "chat__msg--deleted" : ""} chat__msg--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
                }`}
            >
              {a?.isForwarded === 1 &&
                <div className="forwarded-icon">
                  <Rep />
                  <span>Forwarded Message</span>
                </div>}
              {(a?.fromMe === 0 || !a?.fromMe) && (
                <>
                  {a.received_from_contact_id && activeChat?.isGroup === 1 && <div className="group_name">
                    {contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                      ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.name
                      : contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.pushname : "+" + contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.number}
                    {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                  </div>}
                  <img alt="" src={contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo ? contacts?.filter((s) => s.id === a?.received_from_contact_id)[0]?.profile_photo : ProfileNo} className="group_ava" />
                </>
              )}
              {!a.received_from_contact_id && a?.sender_employee_user_id &&
                <>
                  {activeChat?.isGroup === 1 && <div className="group_name">
                    {emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.full_name || emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.username}
                    {a?.original_hash_message && <span className="from_orginal_span">from {a?.original_hash_message?.whatsapp_chat?.whatsapp_contact?.name}</span>}
                  </div>}
                  <img alt="" src={emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar ? FILE_SERVER + emps.filter((s) => s.id === a?.sender_employee_user_id)[0]?.avatar : ProfileNo} className="group_ava" />
                </>
              }
              {a?.quotedMsg && a?.quotedMsg?.id && (
                <div
                  className="replay-msg"
                  onClick={() => GetMessage(a?.id, a?.quotedMsg?.id)}
                >
                  <div className="bar-c"></div>
                  <div className="reply-cont">
                    <div className="reply-name group_name">
                      {a?.quotedMsg?.sender_name ? a?.quotedMsg?.sender_name : (a?.quotedMsg?.fromMe === 1
                        ? "You"
                        : activeChat?.isGroup === 1 ? (
                          (activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.pushname)
                            ? (activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.name || activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.pushname)
                            : (activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.number ?
                              "+" + activeChat?.whatsapp_contact.sub_contacts?.filter((s) => s.id === a?.quotedMsg?.whatsapp_contact_id)[0]?.number : "Rammaz System")
                        )
                          :
                          ((activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                            ? (activeChat?.whatsapp_contact?.name || activeChat?.whatsapp_contact?.pushname)
                            : (activeChat?.whatsapp_contact?.number ? "+" + activeChat?.whatsapp_contact?.number : "Rammaz System")))}
                    </div>
                    <span className="reply-desc">
                      {(a?.quotedMsg?.type === "image" || a?.quotedMsg?.type === "sticker") && <><Cam /> Image</>}
                      {a?.quotedMsg?.type === "video" && <> <Vide /> Video</>}
                      {(a?.quotedMsg?.type === "ptt" || a?.quotedMsg?.type === "audio") && <><Voc /> Audio</>}
                      {a?.quotedMsg?.type === "document" && <><img alt="" width="12" height="15" src={fil} /> File</>}
                      {a?.quotedMsg?.type === "vcard" && <>  <Cons /> Contact</>}
                      {a?.quotedMsg?.type === "ticket" && <>  Ticket Message {a.quotedMsg?.ticket?.request_number} </>}
                      {" "}
                      {a?.quotedMsg?.body && a?.quotedMsg?.type !== "ticket" && a.quotedMsg?.type !== "vcard" && ((a?.quotedMsg?.type !== "video") || (a.quotedMsg?.type !== "vcard")) && a?.quotedMsg?.body} </span>
                  </div>
                  {a?.quotedMsg && a?.quotedMsg?.media && (
                    <img alt=""
                      className="quotedImg"
                      src={a?.quotedMsg?.media}
                    />
                  )}
                </div>
              )}
              <div className={a?.fromMe ? "vcardFromMe" : "vcard"}>
                <img alt="" className="ava-vcard" src={ProfileNo} />
                <span>{getVcardName(a?.body)}</span>

              </div>

              <div className="vcard-option" onClick={() => {
                if (getVcardWaId(a?.body)) {
                  if (contacts?.filter((s) => getNum(s.number) === getNum(getVcardTel(a?.body))).length === 0) {
                    handleSetUser({
                      openVcard: true,
                      id: getVcardWaId(a?.body),
                      number: getVcardTel(a?.body),
                      name: getVcardName(a?.body),
                      whatsapp_contact: {
                        name: getVcardName(a?.body),
                        number: getVcardTel(a?.body)
                      },
                      messages: []
                    }, null)
                  }
                  else {
                    handleSetUser({
                      openVcard: true,
                      id: getVcardWaId(a?.body),
                      number: getVcardTel(a?.body),
                      name: contacts?.filter((s) => getNum(s.number) === getNum(getVcardTel(a?.body)))[0]?.name || contacts?.filter((s) => getNum(s.number) === getNum(getVcardTel(a?.body)))[0]?.pushsname || getVcardName(a?.body),
                      whatsapp_contact: contacts?.filter((s) => getNum(s.number) === getNum(getVcardTel(a?.body)))[0],
                      whatsapp_contact_id: contacts?.filter((s) => getNum(s.number) === getNum(getVcardTel(a?.body)))[0].id,
                      messages: []
                    }, null)
                  }
                }
                else {
                  let elem = document.querySelector("#text-copy");
                  elem.value = `name:${getVcardName(a?.body)}\n phone: ${getVcardTel(a?.body)}`
                  elem.select()
                  document.execCommand('Copy');
                }

              }}>
                {getVcardWaId(a?.body) ? 'Message' : 'Copy Number'}
              </div>
              {contacts?.filter((s) => getNum(s.number) === getNum(getVcardTel(a?.body))).length === 0 && <div className="vcard-option" onClick={() => {
                dispatch({ type: "CHANGE_NAME", payload: { number: getVcardTel(a?.body), name: getVcardName(a?.body) } })

              }}>Save
              </div>}
              <span className="chat__msg-footer">
                {getMessageTime()}
                {((a?.fromMe === 1 || a?.fromMe === true) || a?.fromMe === 1) && (
                  <>
                    {(a?.ack === "device" || a?.ack === "server" || a?.ack === "read" || a?.ack === "fail") ? <Icon
                      id={a?.ack === "device" ? "doubleTick" : (a?.ack === "server" ? "singleTick" : (a?.ack === "read" ? "doubleTick" : (a?.ack === "fail" ? "failed" : "")))}
                      aria-label={a?.ack}
                      className={`chat__msg-status-icon ${a?.ack === "read" ? "chat__msg-status-icon--blue" : ""
                        }`}
                    /> :
                      <div className="chat__msg-status-icon"><Wai /></div>}
                  </>
                )}
              </span>
            </div>

            {opens && <OptionsMenu deleted={a?.delete_status} delete={() => deleteMessage()} ack={a?.ack} copy={() => copy()} forward={() => dispatch({ type: "FORWARD-MESSAGE", payload: a })} click={() => { setReplyMessage(a); document.querySelector(".chat__input")?.focus(); }} />
            }  </div>
        </>
      )
    }
    else if (a?.type === "call_log") {
      return (
        <>
          {a.fromMe === 0 && <div
            onMouseLeave={() => setOpen(false)}
            className={`message-hold call_log_container ${opens && "ac"} message-hold--${(a?.fromMe === 0 || !a?.fromMe) ? "rxd" : "sent"
              }`}
          >
            <div
              id={"s" + ids}
              className={` ${((a?.fromMe === 0 || !a?.fromMe) || (a?.sender_employee_user_id)) &&
                "group_chat_msg"
                } chat__msg ${a?.delete_status ? "chat__msg--deleted" : ""} chat__msg call_log`}
            >
              <img src={missedCall} height={17} width={17} style={{ marginRight: "10px" }} />
              Missed Call At {getMessageTime()}
            </div>
          </div>}
        </>
      );
    }
  };
  return <>{showMessage()}</>;
}

export default memo(Message);