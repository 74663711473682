import React, { memo, useCallback, useEffect, useState } from "react";
import "./styles/main.css";
import date from "date-and-time";
import Filter from "../../assets/images/filter";
import Edit from "../../assets/images/edit";
import Icon from "../Icon";
import Cons from "../../../WhatsApp/assets/images/cons";
import Alert from "./Alert";
import Contact from "./Contact";
import ProfileNo from "../../assets/images/profileNo.png";
import Group from "../../assets/images/group";
import { useDispatch, useSelector } from "react-redux";
import SearchComponent from "./SearchComponent";
import ChatUsers from "./ChatUsers";
import { FILE_SERVER } from "../../../../../constants";
import vcard from "vcard-parser";
import { asyncSearch, asyncSearchByContact } from "../../../../../redux/whatsapp/actions";
import SwitchArea from "./SwitchArea";
const Sidebar = (props) => {
  const getVcardName = (card) => {
    let myVCard = vcard?.parse(card);
    return (myVCard?.fn[0]?.value)
  }
  const loading_contacts = useSelector((state) => state.whatsapp.search_progress_contact)
  let is_fetch = useSelector((state) => state.whatsapp.is_fetch)
  const showUnRead = useSelector(state => state.whatsapp.showUnRead)
  const refresh = useSelector(state => state.whatsapp.refresh)
  useEffect(() => {
    if (document?.getElementById("inner_chats_section_scrollable")) {
      document.getElementById("inner_chats_section_scrollable").scrollTop = 0
    }
  }, [props.mode])
  const { setOpen } = props
  // let search = useSelector((state) => state.whatsapp.search)
  let [search, setSear] = useState("")
  let [searchContact, setSearchContact] = useState("")
  let founds = useSelector((state) => state.whatsapp.founds)
  let foundsContact = useSelector((state) => state.whatsapp.foundsContact)
  let mode = useSelector((state) => state.whatsapp.mode)
  let users = useSelector((state) => state.chat.users)
  let adminChat = useSelector((state) => state.whatsapp.adminChat)
  let customerAdminChat = useSelector((state) => state.whatsapp.customerAdminChat)
  let operationAdminChat = useSelector((state) => state.whatsapp.operationAdminChat)

  let now = new Date();
  let nowString = `${now.getFullYear()}-${(now.getMonth() + 1) > 9 ? (now.getMonth() + 1).toString() : ("0" + (now.getMonth() + 1).toString())}-${(now.getDate()) > 9 ? now.getDate() : "0" + parseInt(now.getDate()).toString()}`
  const showDate = (d) => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    let day = new Date(d)
    day = days[day.getDay()]
    let da = new Date(d)
    da = `${da.getFullYear()}-${(da.getMonth() + 1) > 9 ? (da.getMonth() + 1).toString() : ("0" + (da.getMonth() + 1).toString())}-${(da.getDate()) > 9 ? da.getDate() : "0" + parseInt(da.getDate()).toString()}`
    if (da === nowString)
      return date.format(new Date(d), "HH:mm");
    else if ((new Date(nowString) - new Date(d)) <= 86400000) {
      return ("Yesterday")
    }
    else if ((new Date(nowString) - new Date(d)) < (86400000 * 6))
      return (day)
    else
      return (date.format(new Date(d), "MMM DD"))
  }

  const setSearch = useCallback((a) => {
    dispatch({ type: "input_change", payload: a })
    setSear(a)
    dispatch({ type: "setUnread", payload: false })
    dispatch({ type: "SEARCH_WA_I", payload: a })
    if (a.length > 0)
      asyncSearch(a)
  }, [search])

  const setSearchConv = useCallback((a) => {
    dispatch({ type: "input_change_contact", payload: a })
    setSearchContact(a)
    dispatch({ type: "setUnread", payload: false })
    dispatch({ type: "SEARCH_WA_CONV", payload: a })
    if (a.length > 0)
      asyncSearchByContact(a)
  }, [searchContact])

  const dispatch = useDispatch();
  const handleSetUser = (contact, id) => {
    setOpen(false)
    dispatch({ type: "OPEN-CHAT", payload: null })
    // dispatch({ type: "CHAT_ID", payload: null });
    props.setId(contact);
    props.setUser(contact);
    if (!contact.isNew) {
      dispatch({ type: "READ_CHAT", id: contact.id })
    }
    setSearch("")
    let element = document.querySelector("#XD-1");
    if (element) {
      element.scrollTop = -0;
    }
  }
  const setshowUnread = () => {
    dispatch({ type: "setUnread", payload: !showUnRead })
    dispatch({ type: "GET_WA_CHATS" })
    dispatch({ type: "GET_WA_ADMIN_CHATS" })
  }
  const openAdmin = useCallback((chat) => {
    if (props.chats.length > 0) {
      handleSetUser(chat, chat.id);
      dispatch({ type: "setUnread", payload: false });
    }
  })
  const getNew = useCallback(() => {
    if (props.chats.length > 0) {
      return props?.chats?.filter((s) => s?.is_admin === 1)[0]?.unread
    }
  })
  const chatsArray = useSelector((state) => state.chat.data)
  const handleChat = useCallback((em) => {
    if (chatsArray.filter((s) => s.channel_members.filter((m) => m.user_id === em.id).length > 0).length > 0) {
      dispatch({ type: "OPEN-CHAT", payload: chatsArray.filter((s) => s.channel_members.filter((m) => m.user_id === em.id).length > 0)[0] })
    }
    else {
      dispatch({
        type: "OPEN-CHAT", payload: {
          id: Math.random(),
          messages: [],
          total_unread_message_count: 0,
          channel_members: [{ user_id: em.id, role_id: em.role_id, user: em }, { user_id: JSON.parse(localStorage.getItem("user")).id, role_id: JSON.parse(localStorage.getItem("user")).role_id, user: JSON.parse(localStorage.getItem("user")) }]
        }
      })


    }
  })
  const goToMessage = useCallback((i, c) => {
    if (!is_fetch) {
      dispatch({ type: "clearQuotedMessage" })
      // dispatch({ type: "CHAT_ID", payload: null });
      let numb = i.id;
      let ids = numb;
      dispatch({ type: "CHAT_ID", payload: c, noScroll: true });
      if (props.chats.filter((s) => s.id === c.id)[0]?.messages?.filter((one) =>
        one.id === ids
      ).length > 0) {

        setTimeout(() => {
          document.querySelector("#s" + ids)?.scrollIntoView({ block: "center", inline: "end" })

          setTimeout(() => {
            document.querySelector("#s" + ids)?.classList.add("backdrop_msg")
          }, 300)
          setTimeout(() => {
            document.querySelector("#s" + ids)?.classList.remove("backdrop_msg")
          }, 1200)
        }, 1500);

      } else {
        dispatch({ type: "GET_MES", qid: props.chats.filter((s) => s.id === c.id)[0]?.messages[0]?.id, sid: ids, cid: c.id })
      }
    }
  })
  const roles = useSelector((state) => state.auth.roles)
  const handleScroll = (e) => {
    if (search.length === 0 && searchContact.length === 0 && !refresh && (((e?.target?.scrollTop * 100) / (e?.target?.scrollHeight - e?.target?.clientHeight)) > 90)) {
      if (roles?.includes("USER_FULL_ACCESS")) {
        dispatch({
          type: "GET_WA_CHATS",
          payload: {
            getMore: true,
            mode: mode,
            lastCustomerId: props.lastCustomerId,
            lastOperationsId: props.lastOperationId
          }
        })
      } else {
        dispatch({
          type: "GET_WA_CHATS",
          payload: {
            getMore: true,
            last_id: props.last_id
          }
        })
      }
    }
  }
  return (
    <aside className="sidebar">
      {roles?.includes("USER_FULL_ACCESS") ? <SwitchArea /> : <></>}
      <header className="header">

        <div className="sidebar__avatar-wrapper">
          <div className="avatar-circle">Vision</div>
        </div>
        {props.showContactsButton && <div className="contact-icon" onClick={() => setOpen(true)}>
          <svg id="_20X20_photo_Back" data-name="20X20 photo Back" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
            <g id="Mask_Group_311" data-name="Mask Group 311">
              <g id="notebook-of-contacts">
                <path id="Path_21348" data-name="Path 21348" d="M4.769,10A.769.769,0,0,1,4,10.769H2.462A.769.769,0,0,1,1.692,10h0a.769.769,0,0,1,.769-.769H4A.769.769,0,0,1,4.769,10Zm0-4.923A.769.769,0,0,0,4,4.308H2.462a.769.769,0,0,0-.769.769h0a.769.769,0,0,0,.769.769H4a.769.769,0,0,0,.769-.769Zm0,9.846A.769.769,0,0,0,4,14.154H2.462a.769.769,0,0,0-.769.769h0a.769.769,0,0,0,.769.769H4a.769.769,0,0,0,.769-.769ZM18.308,2.462V17.538A2.462,2.462,0,0,1,15.846,20H5.077a2.462,2.462,0,0,1-2.462-2.462V16.308H4a1.385,1.385,0,0,0,0-2.769H2.615V11.385H4A1.385,1.385,0,1,0,4,8.615H2.615V6.461H4A1.385,1.385,0,1,0,4,3.692H2.615V2.462A2.462,2.462,0,0,1,5.077,0H15.846A2.462,2.462,0,0,1,18.308,2.462ZM8.55,7.3a2.374,2.374,0,1,0,2.374-2.374A2.374,2.374,0,0,0,8.55,7.3Zm6.066,5.8a2.637,2.637,0,0,0-2.637-2.637H9.868A2.638,2.638,0,0,0,7.231,13.1v1.055h7.385Z" fill="#5d5d5d" />
              </g>
            </g>
          </svg>
        </div>}
        <div className="searchs-wrap">
          <SearchComponent svg="search" onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search In Table" />
          <SearchComponent svg="edit" onChange={(e) => setSearchConv(e.target.value)} value={searchContact} placeholder="Start New Chat" />
        </div>
        <div className="filter-icon" onClick={() => { setshowUnread() }}>
          <Filter active={showUnRead} />
        </div>
      </header>
      {chatsArray?.length > 0 && <ChatUsers NotForward={true} chats={chatsArray} />}
      <div className="sidebar__contacts">

        <Alert chatG={chatsArray.filter((s) => s.channel_type.slug === "team")[0]} noAdmin={false} noGroup={false} openChat={() => {
          chatsArray.filter((s) => s.channel_type?.slug === "team").length > 0 &&
            // dispatch({ type: "CHAT_ID", payload: null });
            dispatch({ type: "OPEN-CHAT", payload: chatsArray.filter((s) => s?.channel_type?.slug === "team")[0] })
          dispatch({ type: "WATCH_CHANNEL_RED", payload: chatsArray.filter((s) => s?.channel_type?.slug === "team")[0].id })
        }}
          chat={roles?.includes("USER_FULL_ACCESS") ? (mode === "customer_service" ? customerAdminChat : operationAdminChat) : adminChat}
          new={getNew()}
          onClick={(chat) => openAdmin(chat)}
        />
        <div className="inner_chats_section" id="inner_chats_section_scrollable" onScroll={(e) => handleScroll(e)}>
          {searchContact?.includes("+") && foundsContact?.length === 0 && !loading_contacts && (
            <Contact newChat={true}
              innerKey={0}
              key={9999999}
              onClick={() => { handleSetUser({ instance_name: roles?.includes("USER_FULL_ACCESS") ? mode : "customer_service", isNew: true, number: searchContact?.replace(/\s/g, '')?.replaceAll('(', '')?.replaceAll(')', '')?.replaceAll('-', '')?.split("+")[1], id: searchContact, number: searchContact, name: searchContact, messages: [], whatsapp_contact: { isNew: true, id: "ms", number: searchContact, name: searchContact, whatsapp_contact_company_id: null, whatsapp_contact_role_id: null } }, null); dispatch({ type: "setUnread", payload: false }); setSearchConv("") }}
              contact={{ instance_name: roles?.includes("USER_FULL_ACCESS") ? mode : "customer_service", isNew: true, number: searchContact, id: searchContact, name: searchContact, number: searchContact, messages: [], whatsapp_contact: { isNew: true, id: "ms", number: searchContact.replace(/\s/g, '')?.replaceAll('(', '')?.replaceAll(')', '')?.replaceAll('-', ''), name: searchContact.replace(/\s/g, '')?.replaceAll('(', '')?.replaceAll(')', '')?.replaceAll('-', ''), whatsapp_contact_company_id: null, whatsapp_contact_role_id: null } }} />
          )}
          {(search.length > 0 || searchContact.length > 0) && users.map((s, index) => (
            <Contact
              innerKey={index}
              key={index}
              onClick={() => { handleChat(s) }}
              contact={{ name: s.full_name ? s.full_name : s.username, profile_photo: FILE_SERVER + s.avatar, messages: [] }} />
          ))}
          {search.length > 0 && founds?.length > 0 && props.chats.length > 0 && founds.map((one, index) => <div key={index} className="sidebar-contact" onClick={() => {
            if (!is_fetch) {
              goToMessage(one, one.ch)
            }
          }}>
            <div className="sidebar-contact__avatar-wrapper">
              {one.ch?.isGroup && one.ch.isGroup === 1 ? (
                <Group />
              ) : (
                <img
                  src={(one?.fromMe !== 1) ? one?.ch?.whatsapp_contact?.profile_photo : ProfileNo}
                  alt={""}
                  className="avatar"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = ProfileNo;
                  }}
                />
              )}
            </div>
            <div className="sidebar-contact__content" style={{ justifyContent: "flex-start", textAlign: "start" }}>
              <div className="sidebar-contact__topcontent">
                <h2 className="sidebar-contact__name">
                  {one?.fromMe === 1
                    ? "You"
                    : (one?.ch?.whatsapp_contact?.name || one?.ch?.whatsapp_contact?.pushname || "+" + one?.ch?.whatsapp_contact?.number)}
                </h2>

              </div>
              <div className="sidebar-contact__bottom-content" style={{ justifyContent: "flex-start" }}>
                {one?.ack && (
                  <Icon
                    id={
                      one?.ack === "device"
                        ? "singleTick"
                        : "doubleTick"
                    }
                    aria-label={one?.ack}
                    className={`sidebar-contact__message-icon ${one?.ack === "read"
                      ? "sidebar-contact__message-icon--blue"
                      : ""
                      }`}
                  />
                )}
                {one?.type === "vcard" ?
                  <> <Cons />  {getVcardName(one?.body)}</>
                  : one?.body}</div>
              <span className="Dvjym">
                {showDate(new Date(one.sent_at))}
              </span>
            </div>
          </div>)
          }
          {search.length === 0 && searchContact.length > 0 && foundsContact.filter(one => !one.is_admin).map((one, index) => <>
            <Contact
              key={index}
              onClick={() => { handleSetUser(one, one?.id); setSearch(""); dispatch({ type: "setUnread", payload: false }); setSearchConv("") }}
              innerKey={index}
              contact={one}
            />
          </>)
          }
          {search.length === 0 && searchContact.length === 0 && props.chats.filter(one => !one.is_admin).map((one, index) => <>
            <Contact
              key={index}
              onClick={() => { handleSetUser(one, one?.id); setSearch(""); dispatch({ type: "setUnread", payload: false }); setSearchConv("") }}
              innerKey={index}
              contact={one}
            />
          </>)
          }
        </div>
      </div>
    </aside>
  );
};

export default memo(Sidebar);
