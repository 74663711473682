import axios from "axios";
import {
  all,
  fork,
  put,
  takeEvery
} from "redux-saga/effects";
import {
  OFFLINE
} from 'redux-offline-status/lib/actions'
import store from "../store"
import { toast } from 'react-toastify';
import { REACT_APP_ACCOUNTING_URL } from "../../urls";

function* GetChat(action) {
  try {
    const roles = store.getState()?.auth?.roles
    const showUnRead = store.getState()?.whatsapp.showUnRead
    yield put({ type: "WE_REFRESHING_NOW" })
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    const base_user = REACT_APP_ACCOUNTING_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    if (roles?.includes("USER_FULL_ACCESS")) {
      if (action.payload?.getMore) {
        if (action.payload?.mode === "customer_service") {
          let res1 = yield AxiosInstace.get(`/api/v1/whatsapp/get_chats_with_latest_messages?last_chat_id=${action.payload?.lastCustomerId}${showUnRead ? "&only_unread=true" : ""}&limit=20&chats_limit=15&type=customer_service`)
          yield put({
            type: "GET_CHAT_WA_RED", payload: {
              data: res1.data?.data,
              getMore: action.payload?.getMore,
              mode: action.payload?.mode
            }
          })
        } else {
          let res2 = yield AxiosInstace.get(`/api/v1/whatsapp/get_chats_with_latest_messages?last_chat_id=${action.payload?.lastOperationsId}${showUnRead ? "&only_unread=true" : ""}&limit=20&chats_limit=15&type=operations`)
          yield put({
            type: "GET_CHAT_WA_RED", payload: {
              data: res2.data?.data,
              getMore: action.payload?.getMore,
              mode: action.payload?.mode
            }
          })
        }
      } else {
        let res1 = yield AxiosInstace.get(`/api/v1/whatsapp/get_chats_with_latest_messages?limit=20&chats_limit=15${showUnRead ? "&only_unread=true" : ""}&type=customer_service`)
        yield put({
          type: "GET_CHAT_WA_RED", payload: {
            data: res1.data?.data,
            mode: "customer_service"
          }
        })
        if (document?.getElementById("inner_chats_section_scrollable")) {
          document.getElementById("inner_chats_section_scrollable").scrollTop = 0
        }
        let res2 = yield AxiosInstace.get(`/api/v1/whatsapp/get_chats_with_latest_messages?limit=20&chats_limit=15${showUnRead ? "&only_unread=true" : ""}&type=operations`)
        yield put({
          type: "GET_CHAT_WA_RED", payload: {
            data: res2.data?.data,
            mode: "operations"
          }
        })
        if (document?.getElementById("inner_chats_section_scrollable")) {
          document.getElementById("inner_chats_section_scrollable").scrollTop = 0
        }
      }
    } else {
      if (action.payload?.getMore) {
        let res = yield AxiosInstace.get(`/api/v1/whatsapp/get_chats_with_latest_messages?last_chat_id=${action.payload?.last_id}${showUnRead ? "&only_unread=true" : ""}&limit=20&chats_limit=15`)
        yield put({
          type: "GET_CHAT_WA_RED", payload: {
            data: res.data?.data,
            getMore: action.payload?.getMore
          }
        })
      } else {
        let res = yield AxiosInstace.get(`/api/v1/whatsapp/get_chats_with_latest_messages?limit=20&chats_limit=15${showUnRead ? "&only_unread=true" : ""}`)
        yield put({
          type: "GET_CHAT_WA_RED", payload: {
            data: res.data?.data
          }
        })
        if (document?.getElementById("inner_chats_section_scrollable")) {
          document.getElementById("inner_chats_section_scrollable").scrollTop = 0
        }
      }
    }
    yield put({ type: "WE_REFRESHING_NOW_END" })
  } catch (e) {
    yield put({ type: "WE_REFRESHING_NOW_END" })
    // console.log(e)
    // console.log(e)
  }
}
function* watchGetChat() {
  yield takeEvery("GET_WA_CHATS", GetChat);
}

function* GetAdminChat(action) {
  try {
    const roles = store.getState()?.auth?.roles
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    const base_user = REACT_APP_ACCOUNTING_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    if (roles?.includes("USER_FULL_ACCESS")) {
      let res1 = yield AxiosInstace.get(`/api/v1/whatsapp/get_admin_chat_with_latest_messages?limit=20&type=customer_service`)
      yield put({
        type: "GET_CHAT_ADMIN_WA_RED", payload: {
          data: res1.data?.data,
          mode: "customer_service"
        }
      })
      let res2 = yield AxiosInstace.get(`/api/v1/whatsapp/get_admin_chat_with_latest_messages?limit=20&type=operations`)
      yield put({
        type: "GET_CHAT_ADMIN_WA_RED", payload: {
          data: res2.data?.data,
          mode: "operations"
        }
      })
    } else {
      let res = yield AxiosInstace.get(`/api/v1/whatsapp/get_admin_chat_with_latest_messages?limit=20`)
      yield put({
        type: "GET_CHAT_ADMIN_WA_RED", payload: {
          data: res.data?.data
        }
      })
    }
  } catch (e) {
    console.log(e)
  }
}
function* watchGetAdminChat() {
  yield takeEvery("GET_WA_ADMIN_CHATS", GetAdminChat);
}

function* getConfig() {
  try {
    yield put({ type: "ODOO_LOADING" });
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    const base_user = REACT_APP_ACCOUNTING_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let response_config = yield AxiosInstace.get("/api/v1/configs")
    yield put({ type: "GET_CONFIG_RED", payload: response_config.data.data })
    yield put({ type: "ODOO_DONE" });
  } catch (e) {
    toast.error("error fetching config!!")
    yield put({ type: "ODOO_DONE" });
  }
}
function* watchGetConfig() {
  yield takeEvery("GET_CONFIG_CHATS", getConfig);
}
function* setConfig(action) {
  try {
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    const base_user = REACT_APP_ACCOUNTING_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
    let response_config = yield AxiosInstace.post("/api/v1/configs", JSON.stringify(action.payload))
    yield put({ type: "GET_CONFIG_RED", payload: response_config.data.data })
    // yield put({ type: "WA_DONE" })
    toast.success("configs saved successfully !!")
  } catch (e) {
    console.log(e)
    toast.error("error saving config!!")
    // yield put({ type: "WA_DONE" })
  }
}
function* watchSetConfig() {
  yield takeEvery("SET_CONFIG_CHATS", setConfig)
}
function* sendText(action) {
  try {
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    // console.log(action)
    let res;
    const base_user = REACT_APP_ACCOUNTING_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let AxiosFileInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    });


    // yield put({ type: "WA_LOADING" });
    if (!action.isGroup) {
      let url = action.is_oddo ? "/api/v3/whatsapp/messages/send_message_to_odoo_client" : "/api/v3/whatsapp/messages/send_message_to_user"
      res = yield AxiosInstace.post(
        url,
        JSON.stringify({
          ...action.pay
        })
      );
      yield put({
        type: "CHANGE_IN_MESSAGES",
        payload: {
          type: "UPLOAD_SUC",
          payload: {
            mid: action.mid,
            cid: action.cid,
            chat_id: res.data.data.whatsapp_chat_id,
            id: res.data.data.id,
            ack: "server",
            ...res.data.data
          }
        }
      });
      if (action.is_oddo) {
        yield put({ type: "REMOVE_ODOO_ID", payload: action.pay.whatsapp_chat_id })
      }
    }
    else {
      res = yield AxiosFileInstace.post(
        "/api/v3/whatsapp/messages/send_message_to_group",
        action?.pay
      );
      yield put({
        type: "CHANGE_IN_MESSAGES",
        payload: {
          type: "UPLOAD_SUC",
          payload: {
            mid: action.mid,
            chat_id: res.data.data.whatsapp_chat_id,
            cid: action.cid,
            id: res.data.data.id,
            ack: "server",
            ...res.data.data
          }
        }
      });
    }

    yield put({ type: "SENDING_END" });
    // setTimeout(() => {
    //   put({ type: "WA_DONE" });
    //   put({ type: "SENDING_END" });
    // }, 3000);
  } catch (e) {
    yield put({
      type: "CHANGE_IN_MESSAGES",
      payload: {
        type: "UPLOAD_SUC",
        payload: {
          mid: action.mid,
          cid: action.cid,
          ack: "fail"
        }
      }
    });
    yield put({ type: "SENDING_END" });
  }
}
function* watchSendText() {
  yield takeEvery("SEND_TEXT_SAGA", sendText);
}

function* getPage(action) {
  try {
    let id = action.id
    let page = action.page;
    const base_user = REACT_APP_ACCOUNTING_URL;
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    //EDIT
    let resp = yield AxiosInstace.get(
      `/api/v1/whatsapp/get_messages_of_chat?limit=50&last_whatsapp_chat_message_id=${page}&whatsapp_chat_id=${id}`
    );
    yield put({
      type: "GET_PAGE", payload: {
        messages: resp.data.data,
        id: action.id
      }
    });
  } catch (e) {
    yield put({
      type: "GET_PAGE_CHAT_RED", payload: {
        messages: [],
        id: action.id
      }
    });
  }
}
function* watchGetPage() {
  yield takeEvery("GET_PAGE_CHAT", getPage);
}

function* SendForward(action) {
  try {
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    let res;
    const base_user = REACT_APP_ACCOUNTING_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    res = yield AxiosInstace.post("/api/v3/whatsapp/messages/forward_message",
      JSON.stringify({
        whatsapp_chat_message_id: action.msId.toString(),
        whatsapp_chat_id: action.chatId?.toString(),
        id: "4",
        withBody: action.withBody
      }));
    yield put({
      type: "CHANGE_IN_MESSAGES",
      payload: {
        type: "UPLOAD_SUC",
        payload: {
          mid: action.mid,
          cid: action.cid,
          id: res.data.data.id,
          ack: "server",
          ...res.data.data
        }
      }
    });
    yield put({ type: "SENDING_END" });
  } catch (e) {
    yield put({
      type: "CHANGE_IN_MESSAGES",
      payload: {
        type: "UPLOAD_SUC",
        payload: {
          mid: action.mid,
          cid: action.cid,
          ack: "fail"
        }
      }
    });
    yield put({ type: "SENDING_END" });
  }
}
function* watchForward() {
  yield takeEvery("SEND_TEXT_SAGA_FORWARD", SendForward)
}

function* changeName(action) {
  const token = store.getState()?.whatsapp?.userTokenWhatsapp;
  const base_user = REACT_APP_ACCOUNTING_URL;
  let AxiosInstace = axios.create({
    baseURL: `${base_user}`,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  try {
    if (action.id && !action.isNew)
      yield AxiosInstace.put("api/v1/whatsapp_contacts/" + action.id, JSON.stringify({ name: action.payload.name, whatsapp_contact_company_id: action.payload.cid, whatsapp_contact_role_id: action.payload.rid }))
    else {
      let res = yield AxiosInstace.post("api/v1/whatsapp_contacts", JSON.stringify({ number: action.payload.number, name: action.payload.name, whatsapp_contact_company_id: action.payload.cid, whatsapp_contact_role_id: action.payload.rid }))
      yield put({ type: "ADD_CONTACT", payload: res.data.data })
    }
  }
  catch (e) { }
}
function* watchChangeName() {
  yield takeEvery("CHANGE_NAME", changeName)
}
function* getMessages(action) {
  try {
    const base_user = REACT_APP_ACCOUNTING_URL;
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;

    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let res = yield AxiosInstace.get(`api/v3/whatsapp/messages/getMessagesSectionByCenterMessage/${action.sid}`)
    yield put({ type: "GET_MES_RED", payload: res.data.data, cid: action.cid, id: action.sid })
  }
  catch (e) {
    yield put({ type: "GET_MES_RED", payload: [] })
    toast.error("cannot get message!!")
  }
}
function* WatchGetMessages() {
  yield takeEvery("GET_MES", getMessages)
}
function* getMessagesAfter(action) {
  try {
    const base_user = REACT_APP_ACCOUNTING_URL;
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let res = yield AxiosInstace.get(`api/v3/whatsapp/messages/getMessagesAfterSpecificMessage/${action.sid}`)
    yield put({ type: "GET_MES_RED_AFTER", payload: res.data.data, cid: action.cid, id: action.sid })
  }
  catch (e) {
    yield put({ type: "GET_MES_RED_AFTER", payload: [] })
    toast.error("cannot get message!!")
  }
}
function* WatchGetMessageAfter() {
  yield takeEvery("GET_MES_AFTER", getMessagesAfter)
}

function* getChatData(action) {
  try {
    const base_user = REACT_APP_ACCOUNTING_URL;
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let res = yield AxiosInstace.get("/api/v1/whatsapp_contact_roles")
    yield put({ type: "GET_ROLS", payload: res.data.data })
    let resd = yield AxiosInstace.get("/api/v1/whatsapp_contact_companies")
    yield put({ type: "GET_COMPA", payload: resd.data.data })
    let resp = yield AxiosInstace.get("api/v1/whatsapp_contacts")
    yield put({ type: "GET_CONTACT", payload: resp.data.data })
  }
  catch (e) { }
}
function* watchGetData() {
  yield takeEvery("GET_DATA_CHA", getChatData)
}
function* readChat(action) {
  try {
    const base_user = REACT_APP_ACCOUNTING_URL;
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    yield AxiosInstace.get("/api/v1/whatsapp/read_chat_messages?whatsapp_chat_id=" + action.id)

  }
  catch (e) { }
}
function* watchReadChat() {
  yield takeEvery("READ_CHAT", readChat)
}
function* restoreMessage(action) {
  try {
    const base_user = REACT_APP_ACCOUNTING_URL;
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    if (action.payload.message_id) {
      yield AxiosInstace.post(`api/v3/whatsapp/messages/restore/${action.payload.message_id}`)
      yield put({
        type: "CHANGE_IN_MESSAGES",
        payload: {
          type: "MessageReceivedEvent",
          payload: {
            message: {
              id: action.payload.message_id,
              whatsapp_chat_id: action.payload.chat_id,
              delete_status: null
            }
          }
        }
      })
    }
  }
  catch (e) {
    toast.error("error while delete message!")
  }
}
function* watchRestoreMessage() {
  yield takeEvery("restore_this_message", restoreMessage)
}
function* deleteMessage(action) {
  try {
    const base_user = REACT_APP_ACCOUNTING_URL;
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    if (action.payload.message_id) {
      yield AxiosInstace.delete(`api/v3/whatsapp/messages/delete/${action.payload.message_id}`)
      yield put({
        type: "CHANGE_IN_MESSAGES",
        payload: {
          type: "MessageReceivedEvent",
          payload: {
            message: {
              id: action.payload.message_id,
              whatsapp_chat_id: action.payload.chat_id,
              delete_status: "hide"
            }
          }
        }
      })
    }
  }
  catch (e) {
    toast.error("error while delete message!")
  }
}
function* watchDeleteMessage() {
  yield takeEvery("delete_this_message", deleteMessage)
}
function* removeEmpFromChat(action) {
  try {
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    const base_user = REACT_APP_ACCOUNTING_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    yield AxiosInstace.post(
      `/api/v1/whatsapp_chat_members/destroy`, JSON.stringify({ user_id: action.payload, whatsapp_chat_id: action.cid })
    );
  }
  catch (e) {

  }
}
function* watchRemoveEmpFromChat() {
  yield takeEvery("REM-EMP-FROM-CHAT", removeEmpFromChat)
}
function* AddEmpFromChat(action) {
  try {
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    const base_user = REACT_APP_ACCOUNTING_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    yield AxiosInstace.post(
      `/api/v1/whatsapp_chat_members/store`, JSON.stringify({ user_id: action.payload, whatsapp_chat_id: action.cid }))
  }
  catch (e) {

  }
}
function* watchAddEmpFromChat() {
  yield takeEvery("ADD-EMP-TO-CHAT", AddEmpFromChat)
}
function* getWhatsChat(action) {
  try {
    yield put({
      type: "TICKET-LOADING", payload: {
        ticket_loading: action.payload.id,
        ticket_loading_type: action.payload.number_type
      }
    })
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    const base_user = REACT_APP_ACCOUNTING_URL;
    let AxiosInstace = axios.create({
      baseURL: `${base_user}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let res = yield AxiosInstace.get(`/api/v1/tickets/get_client_chat_if_exist/${action.payload.id}?number_type=${action.payload.number_type}`)
    if (res.data.data) {
      yield put({ type: "CHAT-TICKET", payload: { id: res.data.data.id, ticket_id: action.payload.id, ticket: action.payload.ticket, number_type: action.payload.number_type } })
    }
    else {
      let ticket = action.payload.ticket
      yield put({
        type: "CHAT-TICKET-NEW", payload:
        {
          isNew: true,
          number_type: action.payload.number_type,
          isOddoClient: true,
          ticket_id: action.payload.id,
          id: "ms",
          name: ticket?.client,
          number: ticket?.maskedPhoneNumber,
          instance_name: "customer_service",
          messages: [],
          ticket: action.payload.ticket,
          whatsapp_contact: { isNew: true, id: "ms", number: ticket?.maskedPhoneNumber, name: ticket?.client, whatsapp_contact_company_id: null, whatsapp_contact_role_id: null }
        }
      })
    }
    yield put({
      type: "TICKET-LOADING", payload: {
        ticket_loading: null,
        ticket_loading_type: null
      }
    })
  }
  catch (e) {
    console.log(e)
    yield put({
      type: "TICKET-LOADING", payload: {
        ticket_loading: null,
        ticket_loading_type: null
      }
    })
  }
}
function* watchGetWhatsChat() {
  yield takeEvery("GET-WA-CHAT-TICKET", getWhatsChat)
}

function* changeInMessages(action) {
  yield put(action.payload)
}
function* watchchangeInMessages() {
  yield takeEvery("CHANGE_IN_MESSAGES", changeInMessages)
}

function* resendText(action) {
  try {
    const token = store.getState()?.whatsapp?.userTokenWhatsapp;
    let res;
    let AxiosInstace = axios.create({
      baseURL: REACT_APP_ACCOUNTING_URL,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
    if (action.payload.message_id) {
      res = yield AxiosInstace.get(`api/v3/whatsapp/messages/resend/${action.payload.message_id}`)
      yield put({
        type: "CHANGE_IN_MESSAGES",
        payload: {
          type: "UPLOAD_SUC",
          payload: {
            mid: action.payload.local_message_id,
            cid: action.payload.chat_id,
            chat_id: res.data.data.whatsapp_chat_id,
            id: res.data.id,
            ack: "server",
            resend: true,
            ...res.data.data
          }
        }
      })
      yield put({ type: "SENDING_END" });
    }
  } catch (e) {
    yield put({
      type: "CHANGE_IN_MESSAGES",
      payload: {
        type: "UPLOAD_SUC",
        payload: {
          mid: action.payload.local_message_id,
          cid: action.payload.chat_id,
          id: action.payload.message_id,
          ack: "fail"
        }
      }
    });
    yield put({ type: "SENDING_END" });
  }
}
function* watchReSendText() {
  yield takeEvery("RESEND_FAILED_MESSAGE", resendText);
}

export default function* WhatsappSaga() {
  yield all([
    fork(watchGetChat),
    fork(watchSendText),
    fork(watchGetPage),
    fork(watchGetWhatsChat),
    fork(watchForward),
    fork(watchChangeName),
    fork(WatchGetMessages),
    fork(watchGetData),
    fork(watchReadChat),
    fork(watchAddEmpFromChat),
    fork(watchRemoveEmpFromChat),
    fork(watchGetConfig),
    fork(watchSetConfig),
    fork(watchchangeInMessages),
    fork(WatchGetMessageAfter),
    fork(watchGetAdminChat),
    fork(watchReSendText),
    fork(watchDeleteMessage),
    fork(watchRestoreMessage)
  ]);
}
